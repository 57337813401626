import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

let initialState = {
  totalPatientsMap: {},
  totalPatientsLoading: false,
  totalPatientsError: false,

  topCarePlanMap: {},
  totalCarePlanLoading: false,
  totalCarePlanError: false,

  careTeamStatistics: null,
  careTeamStatisticsLoading: false,
  careTeamStatisticsError: false,

  activePatientsByCategory: null,
  activePatientsByCatLoading: false,
  activePatientsByCatError: false,

  appOnboardStatus: null,
  appOnboardLoading: false,
  appOnboardError: false,
};

const dashboard = createSlice({
  name: "dashboard",
  initialState: initialState,
  reducers: {
    getTotalPatients(state, category) {
      state.totalPatientsLoading = true;
      state.totalPatientsError = false;
    },
    setTotalPatients(state, action) {
      let { category, ...data } = action.payload;
      let updatedMetrics = data.metrics.map(monthData => {
        let date = moment(`01/${monthData.month}/${monthData.year}`, 'DD/MMMM/YYYY');
        monthData.monthYear = date.format("MMM'YY");
        return monthData;
      })
      data.metrics = updatedMetrics
      state.totalPatientsMap[category] = data;
      state.totalPatientsLoading = false;
      state.totalPatientsError = false;
    },
    setTotalPatientsError(state, action) {
      state.totalPatientsLoading = action.payload.loading;
      state.totalPatientsError = action.payload.error;
    },

    getTopCarePlan(state, category) {
      state.totalCarePlanLoading = true;
      state.totalCarePlanError = false;
    },
    setTopCarePlan(state, action) {
      let { category, ...data } = action.payload;
      state.topCarePlanMap[category] = data;
      state.totalCarePlanLoading = false;
      state.totalCarePlanError = false;
    },
    setTopCarePlanError(state, action) {
      state.totalCarePlanLoading = action.payload.loading;
      state.totalCarePlanError = action.payload.error;
    },

    getCareTeamStatistics(state, action) {
      state.careTeamStatisticsLoading = true;
      state.careTeamStatisticsError = false;
    },
    setCareTeamStatistics(state, action) {
      state.careTeamStatistics = action.payload;
      state.careTeamStatisticsLoading = false;
      state.careTeamStatisticsError = false;
    },
    setCareTeamStatisticsError(state, action) {
      state.careTeamStatisticsLoading = action.payload.loading;
      state.careTeamStatisticsError = action.payload.error;
    },

    getActivePatientsByCategory(state, action) {
      state.activePatientsByCatLoading = true;
      state.activePatientsByCatError = false;
    },
    setActivePatientsByCategory(state, action) {
      state.activePatientsByCategory = action.payload;
      state.activePatientsByCatLoading = false;
      state.activePatientsByCatError = false;
    },
    setActivePatientsByCategoryError(state, action) {
      state.activePatientsByCatLoading = action.payload.loading;
      state.activePatientsByCatError = action.payload.error;
    },

    getAppOnboardStatus(state) {
      state.appOnboardLoading = true;
      state.appOnboardError = false;
    },
    setAppOnboardStatus(state, action) {
      state.appOnboardStatus = action.payload;
      state.appOnboardLoading = false;
      state.appOnboardError = false;
    },
    setAppOnboardStatusError(state, action) {
      state.appOnboardLoading = action.payload.loading;
      state.appOnboardError = action.payload.error;
    },

    getSearchPatientsEMR() {},
    setSearchPatientsEMR(state, action) {},
  },
});

export const {
  getTotalPatients,
  setTotalPatients,
  setTotalPatientsError,
  getTopCarePlan,
  setTopCarePlan,
  setTopCarePlanError,
  getCareTeamStatistics,
  setCareTeamStatistics,
  setCareTeamStatisticsError,
  getActivePatientsByCategory,
  setActivePatientsByCategory,
  setActivePatientsByCategoryError,
  getAppOnboardStatus,
  setAppOnboardStatus,
  setAppOnboardStatusError,
  getSearchPatientsEMR,
  setSearchPatientsEMR,
} = dashboard.actions;

export default dashboard.reducer;
