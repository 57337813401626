import React from "react";

export const Dashboard = React.lazy(() =>
  import("../pages/Dashboard/DashboardPage")
);
export const Packages = React.lazy(() =>
  import("../pages/Packages/PackageRouting")
);
export const Patients = React.lazy(() =>
  import("../pages/Patients/PatientsPage")
);
export const CareTeam = React.lazy(() =>
  import("../pages/CareTeam/CareTeamPage")
);

export const PageNotFound = React.lazy(() =>
  import("../components/PageNotFound")
);
export const carePackageTemplateCreation = React.lazy(() =>
  import("../pages/Packages/CarePackageMainPage")
);

export const CarePackageApproval = React.lazy(() =>
  import("../pages/Packages/CarePackageAprover/CarePackageApprover")
);

export const AppointmentsPage = React.lazy(() =>
  import("../pages/Appointments/AppointmentsPage")
);
