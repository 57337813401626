import { Amplify } from "aws-amplify";

const env = process.env;

Amplify.configure({
  Auth: {
    region: env.REACT_APP_REGION,
    userPoolId: env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: env.REACT_APP_CLIENT_ID,
  },
});