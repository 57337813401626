import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { UrmComponent } from "cc-aws-urm-service";
import { urmService } from "../../services/urmService";
import { referenceDataService } from "../../services/referenceDataService";
import { toast } from "react-toastify";
import "./urm.scss";
import { useNavigate } from "react-router-dom";
import { APP_SERVICES, PERMISSIONS_SET } from "../../utils/constants";
import { Permissions } from "../../components/Common/Permissions";
import { Container, Row, Col } from "react-bootstrap";

const URMHome = () => {
  const navigate = useNavigate();

  const [userListData, setUserListData] = useState([]);
  const [roleListData, setRoleListData] = useState([]);
  const [policyListData, setPolicyListData] = useState([]);
  const [groupListData, setGroupListData] = useState([]);
  const [groupUserData, setGroupUserData] = useState({});
  const [rolePolicyData, setRolePolicyData] = useState([]);
  const [currentUserCustomerData, setCurrentUserCustomerData] = useState([]);
  const [currentUserSegmentData, setCurrentUserSegmentData] = useState([]);
  const [currentUserProductData, setCurrentUserProductData] = useState([]);
  const [currentGroupCustomerData, setCurrentGroupCustomerData] = useState([]);
  const [currentGroupSegmentData, setCurrentGroupSegmentData] = useState([]);
  const [currentGroupProductData, setCurrentGroupProductData] = useState([]);
  const [currentGroupRolesData, setCurrentGroupRolesData] = useState([]);
  const [currentGroupUsersData, setCurrentGroupUsersData] = useState([]);
  const [currentUserGroupsData, setCurrentUserGroupsData] = useState([]);

  const [resourceTypes, setResourceTypes] = useState([]);

  const [customers] = useState([]);
  const [segments] = useState([]);
  const [products] = useState([]);

  const [userData, setUserData] = useState([]);
  const [roleData, setRoleData] = useState([]);
  const [policyData, setPolicyData] = useState([]);
  const [groupData, setGroupData] = useState([]);

  const [userName, setUserName] = useState("");

  const getUsersData = async () => {
    urmService.getAllUser().then((res) => {
      setUserListData(res.data);
    });
  };

  const getRolesData = async () => {
    urmService.getRoles().then((res) => {
      setRoleListData(res.data);
      getRolePolicyData();
    });
  };

  const getPolicyData = async () => {
    urmService.getPolicies().then((res) => {
      setPolicyListData(res.data);
    });
  };

  const getRolePolicyData = async () => {
    urmService.getRolesPolicies().then((res) => {
      setRolePolicyData(res.data);
    });
  };

  const getResourceTypes = async () => {
    urmService.getResourceTypes().then((res) => {
      const updatedResources = updateResourceTypes(res.data);
      setResourceTypes(updatedResources);
    });
  };

  const getGroupData = async () => {
    const groups = await urmService.getGroups();
    setGroupListData(groups.data);
    const usercount = await urmService.getGroupsUsersCount();
    setGroupUserData(usercount.data);
  };

  useEffect(() => {
    getUsersData();
    getRolesData();
    getPolicyData();
    getResourceTypes();
    // getGroupData();
    const username =
      localStorage.getItem("REACT_APP_AUTH_USERNAME") ||
      process.env.REACT_APP_AUTH_USERNAME;
    setUserName(username);
  }, []);

  const { userPermissions } = useSelector((state) => state.appointments);
  const [myPermissions, setPermissions] = useState(null);

  useEffect(() => {
    const mapPermissions = async () => {
      const permissions = await urmService.getPermissionsList(userPermissions);
      setPermissions(permissions);
    };
    mapPermissions();
  }, [userPermissions]);

  useEffect(() => {
    if (
      userListData.length > 0 &&
      roleListData.length > 0 &&
      policyListData.length > 0 &&
      rolePolicyData.length > 0
      // groupListData
    ) {
      const skipUsers = ["service-account-ch-oauth2-token-service", "admin"];
      const userData =
        userListData.length !== 0 && typeof userListData !== "string"
          ? userListData
              .map((item) => {
                const userRoles =
                  item && item.authorities
                    ? item.authorities.map((auth) => auth.authority)
                    : [];

                return {
                  id: item.id,
                  username: item.username,
                  firstName: item.firstName,
                  lastName: item.lastName,
                  roles: userRoles,
                  isActive: item.isActive,
                  email: item.email,
                  accountExpired: item.accountExpired,
                  accountLocked: item.accountLocked,
                  passwordExpired: item.passwordExpired,
                };
              })
              .filter((item) => !skipUsers.includes(item.username))
          : [];

      const roleData =
        roleListData.length !== 0 && typeof roleListData !== "string"
          ? roleListData.map((item) => {
              let rolePolicyArray = [];

              rolePolicyData.forEach((el) => {
                if (el.roleId === item.id) {
                  rolePolicyArray = el.policy.map((pol) => pol.name);
                }
              });

              return {
                id: item.id,
                authority: item.authority,
                authorityAlias: item.authorityAlias,
                dateCreated: new Date(item.dateCreated).toLocaleDateString(),
                createdBy:
                  item && item["createdBy"] ? item["createdBy"].username : "",
                fullName: item.isActive,
                policy: rolePolicyArray,
                isActive: item.isActive,
              };
            })
          : [];

      const policyData =
        policyListData.length !== 0 && typeof policyListData !== "string"
          ? policyListData.map((item) => {
              return {
                id: item.id,
                name: item.name,
                description: item.description,
                dateCreated: new Date(item.dateCreated).toLocaleDateString(),
                createdBy:
                  item && item["createdBy"] ? item["createdBy"].username : "",
                isDeleted: item.isDeleted,
                policyJson: item.policyJson,
              };
            })
          : [];

      // const groupData = groupListData.map((item) => {
      //   const usersCount = groupUserData ? groupUserData[item.id] : 0;
      //   return {
      //     id: item.id,
      //     name: item.groupName,
      //     description: item.groupDescription,
      //     usersCount: usersCount,
      //     dateCreated: new Date(item.dateCreated).toLocaleDateString(),
      //     createdBy: item.createdBy,
      //   };
      // });
      setUserData(userData);
      setRoleData(roleData);
      setPolicyData(policyData);
      // setGroupData(groupData);
    }
  }, [
    userListData,
    roleListData,
    policyListData,
    rolePolicyData,
    // groupListData,
  ]);

  const updateUser = (id, payload, groups) => {
    urmService.updateUser(payload, id).then((res) => {
      if (res && res.status === 200) {
        getUsersData();
      } else {
        toast.error(`Error in user upation`);
      }
    });
    // urmService.saveUserGroup(id, groups);
  };

  const saveGroup = async (id, accessData, group, groupUsers, groupRoles) => {
    let entityId = id;
    const entityType = "group";
    const _accessData = { ...accessData, entityId, entityType };
    const currentUser = localStorage.getItem("REACT_APP_AUTH_USERNAME");
    const _group = {
      groupName: group.groupName,
      currentUser,
      users: [],
      groupDescription: group.description,
      isActive: true,
    };
    try {
      if (!entityId) {
        const groupData = await urmService.createGroup(_group);
        if (groupData?.data?.error) {
          throw new Error();
        }
        entityId = groupData.data.id;
      } else {
        await urmService.updateGroup(entityId, _group);
      }
      await urmService.updateGroupRoles(entityId, groupRoles);
      await urmService.updateGroupUsers(entityId, { users: groupUsers });
      if (_accessData.data.length > 0) getGroupData();
      toast.success(`Group data saved`);
    } catch (e) {
      toast.error(`Duplicate group name or invalid data.`);
    }
  };
  const updateUserAccessData = (payload) => {
    referenceDataService
      .fetchReferenceData("access-data", payload)
      .then((res) => {
        if (res && res.status === 201) {
          toast.success(`User data updated`);
          getUsersData();
        } else {
          toast.error(`Error in user upation`);
        }
      });
  };

  const getAccessDataOfUser = (userId) => {
    urmService.getAccessDataOfUser(userId).then((res) => {
      if (res && res.data) {
        let customers = [],
          segments = [],
          products = [];
        res.data.forEach((item) => {
          switch (item.objectType) {
            case "segment":
              segments.push(item.objectId);
              break;
            case "customer":
              customers.push(item.objectId);
              break;
            case "product":
              products.push(item.objectId);
              break;
          }
        });

        setCurrentUserCustomerData(customers);
        setCurrentUserProductData(products);
        setCurrentUserSegmentData(segments);
      }
    });
  };

  const getAccessDataOfGroup = (groupId) => {
    urmService.getAccessDataOfGroup(groupId).then((res) => {
      if (res && res.data) {
        let customers = [],
          segments = [],
          products = [];
        res.data.forEach((item) => {
          switch (item.objectType) {
            case "segment":
              segments.push(item.objectId);
              break;
            case "customer":
              customers.push(item.objectId);
              break;
            case "product":
              products.push(item.objectId);
              break;
          }
        });

        setCurrentGroupCustomerData(customers);
        setCurrentGroupProductData(products);
        setCurrentGroupSegmentData(segments);
      }
    });
  };
  const createPolicy = (payload) => {
    urmService.createPolicy(payload).then((res) => {
      if (res && res.data.error && res.data.error.code === 404) {
        toast.error(`Error in policy creation: ${res.data.error.message}`);
      } else if (res && res.status === 201) {
        toast.success(`Policy Created`);
      } else {
        toast.error(`Error in policy creation`);
      }
      getPolicyData();
    });
  };

  const updatePolicy = (id, payload) => {
    urmService.updatePolicy(id, payload).then((res) => {
      if (res && res.data.error && res.data.error.code === 404) {
        toast.error(`Error in policy Updation: ${res.data.error.message}`);
      } else if (res && res.status === 200) {
        toast.success(`Policy Updated`);
      } else {
        toast.error(`Error in policy update`);
      }
      getPolicyData();
      getRolesData();
    });
  };

  const deletePolicy = (id) => {
    urmService.deletePolicyById(id).then((res) => {
      if (res && res.data.error && res.data.error.code === 404) {
        toast.error(`Error in policy deletion: ${res.data.error.message}`);
      } else if (res && res.status === 200) {
        toast.success(`Policy Deleted`);
        getPolicyData();
      } else {
        toast.error(`Error in policy deletion`);
      }
    });
  };

  const createRole = (payload, policyPayload) => {
    urmService
      .createRole(payload)
      .then((res) => {
        if (res && res.data.error && res.data.error.code === 400) {
          toast.error(`Error in Role Creation: ${res.data.error.message}`);
        } else if (res && res.status === 200) {
          const currUserObj = { currentUser: "abc" };
          let policyObj =
            Array.isArray(policyPayload) && policyPayload.length > 0
              ? policyPayload[0]
              : currUserObj;
          const pArr = [policyObj];
          const policyArr = pArr;
          attachRolePolicy(res.data.id, policyArr, false);
        }
      })
      .catch((err) => {
        toast.error(`Error in Role Creation: ${err}`);
        getRolesData();
      });
  };

  const updateRole = (payload, id, policyPayload, isUpdate, isActiveUpdate) => {
    payload["currentUser"] = localStorage.getItem("REACT_APP_AUTH_USERNAME");
    urmService.UpdateRole(payload, id).then((res) => {
      if (res && res.data.error && res.data.error.code === 400) {
        toast.error(`Error in Role update: ${res.data.error.message}`);
      } else if (res && res.status === 200 && isActiveUpdate) {
        toast.success(`Role updated`);
        getRolesData();
      } else if (res && res.status === 200 && !isActiveUpdate) {
        policyPayload["currentUser"] = localStorage.getItem(
          "REACT_APP_AUTH_USERNAME"
        );
        attachRolePolicy(res.data.id, policyPayload, isUpdate);
      } else {
        toast.error(`Error in Role Updation`);
      }
    });
  };

  const getRolePolicies = (id) => {
    urmService.getRolePolices(id).then((res) => {});
  };

  const attachRolePolicy = (id, rolePolicies, isUpdate) => {
    urmService.attachPolicyToRole(id, rolePolicies).then((res) => {
      if (res && res.status === 200 && isUpdate) {
        toast.success(`Role Updated`);
      } else if (res && res.status === 200 && !isUpdate) {
        toast.success(`Role Created`);
      } else {
        toast.error(`Error in Role creation/updation`);
      }
      getRolesData();
    });
  };

  const getGroupRoles = (groupId) => {
    urmService.getGroupRoles(groupId).then((res) => {
      if (res && res.data) {
        setCurrentGroupRolesData(res.data.map((x) => x.authority));
      }
    });
  };

  const getGroupUsers = (groupId) => {
    urmService.getGroupUsers(groupId).then((res) => {
      if (res && res.data) {
        setCurrentGroupUsersData(res.data.map((x) => x.id));
      }
    });
  };

  const getUserGroups = (userId) => {
    // urmService.getUserGroups(userId).then((res) => {
    //   if (res && res.data) {
    //     setCurrentUserGroupsData(res.data.map((x) => x.id));
    //   }
    // });
  };

  const updateResourceTypes = (resourceTypes) => {
    const _resourceTypes = [...resourceTypes];

    _resourceTypes.forEach((resource) => {
      if (resource.resource_type === "model") {
        const _permissions = [...resource.permissions];

        _permissions.forEach((permission) => {
          if (permission.permission === "run") {
            permission.permissionName = "Save as Overwrite";
          } else if (permission.permission === "run_new_version") {
            permission.permissionName = "Save as New Version";
          }
        });

        _permissions.forEach((permission, index) => {
          if (permission.permission === "copy") {
            _permissions.splice(index, 1);
          }
        });

        resource.permissions = _permissions;
      }
    });

    return _resourceTypes;
  };

  const onBtn = () => {
    navigate("/dashboard");
  };

  return (
    <>
      {
        <div>
          <main className="URMPage">
            <div className="header-container">
              <div className="container">
                <div className="header-breadcrumb">Dashboard &gt; URM</div>
                <div className="header-title">User Access Management </div>
              </div>
            </div>
            <Permissions
              allow={
                myPermissions &&
                urmService.can(
                  PERMISSIONS_SET.URM_MANAGEMENT,
                  APP_SERVICES.URM,
                  myPermissions
                )
              }
            >
              <UrmComponent
                tableChanged={(tabIndex) => urmService.setURMTabIndex(tabIndex)}
                viewModeChanged={(viewMode) =>
                  urmService.setURMViewMode(viewMode)
                }
                users={userData}
                roles={roleData}
                policies={policyData}
                groups={groupData}
                customers={customers}
                segments={segments}
                products={products}
                username={userName}
                resourceTypes={resourceTypes}
                currentUserCustomerData={currentUserCustomerData}
                currentUserSegmentData={currentUserSegmentData}
                currentUserProductData={currentUserProductData}
                currentGroupCustomerData={currentGroupCustomerData}
                currentGroupSegmentData={currentGroupSegmentData}
                currentGroupProductData={currentGroupProductData}
                currentGroupRolesData={currentGroupRolesData}
                currentGroupUsersData={currentGroupUsersData}
                currentUserGroupsData={currentUserGroupsData}
                getGroupsDataOfUser={getUserGroups}
                getRolesDataOfGroup={getGroupRoles}
                getUsersDataOfGroup={getGroupUsers}
                getAccessDataOfUser={getAccessDataOfUser}
                getAccessDataOfGroup={getAccessDataOfGroup}
                updateUser={updateUser}
                saveGroup={saveGroup}
                updateUserAccessData={updateUserAccessData}
                createRole={createRole}
                updateRole={updateRole}
                getRolePolices={getRolePolicies}
                attachRolePolicy={attachRolePolicy}
                createPolicy={createPolicy}
                updatePolicy={updatePolicy}
                deletePolicy={deletePolicy}
                getPolicyData={getPolicyData}
                getRolesData={getRolesData}
              />
            </Permissions>
            {!urmService.can(
              PERMISSIONS_SET.URM_MANAGEMENT,
              APP_SERVICES.URM,
              myPermissions
            ) && (
              <div>
                <Container>
                  <Row>
                    <Col className="notFundContainer">
                      <h2 className="errtitle">
                        Sorry, you do not have the appropriate permissions to
                        view this content. Please contact an admin.
                      </h2>
                      <p className="text-muted">
                        Please go back to home page
                        <p className="mt-2">
                          <button
                            type="button"
                            className="btn gh-btn-primary"
                            onClick={onBtn}
                          >
                            Go to HomePage
                          </button>
                        </p>
                      </p>
                    </Col>
                  </Row>
                </Container>
              </div>
            )}
            <span></span>
          </main>
        </div>
      }
    </>
  );
};

export default URMHome;
