export const Icons = {
  SEARCH:
    "M16.031 14.617L20.314 18.899L18.899 20.314L14.617 16.031C13.0237 17.3082 11.042 18.0029 9 18C4.032 18 0 13.968 0 9C0 4.032 4.032 0 9 0C13.968 0 18 4.032 18 9C18.0029 11.042 17.3082 13.0237 16.031 14.617ZM14.025 13.875C15.2941 12.5699 16.0029 10.8204 16 9C16 5.132 12.867 2 9 2C5.132 2 2 5.132 2 9C2 12.867 5.132 16 9 16C10.8204 16.0029 12.5699 15.2941 13.875 14.025L14.025 13.875Z",
  LEFT_ARROW:
    "M2.71869 5.33333H10.8334V6.66667H2.71869L6.29469 10.2427L5.35202 11.1853L0.166687 6L5.35202 0.814667L6.29469 1.75733L2.71869 5.33333Z",
  RIGHT_ARROW:
    "M8.78129 5.33312L5.20529 1.75712L6.14796 0.814453L11.3333 5.99979L6.14796 11.1851L5.20529 10.2425L8.78129 6.66645H0.666626V5.33312H8.78129Z",
  CLOSE:
    "M4.99999 4.05732L8.29999 0.757324L9.24266 1.69999L5.94266 4.99999L9.24266 8.29999L8.29999 9.24266L4.99999 5.94266L1.69999 9.24266L0.757324 8.29999L4.05732 4.99999L0.757324 1.69999L1.69999 0.757324L4.99999 4.05732Z",
  Checkmark:
    "M5.50005 7.37912L12.394 0.484375L13.4553 1.54488L5.50005 9.50012L0.727051 4.72712L1.78755 3.66663L5.50005 7.37912Z",
  PENCIL:
    "M12.728 6.68602L11.314 5.27202L2 14.586V16H3.414L12.728 6.68602ZM14.142 5.27202L15.556 3.85802L14.142 2.44402L12.728 3.85802L14.142 5.27202ZM4.242 18H0V13.757L13.435 0.322022C13.6225 0.134551 13.8768 0.0292358 14.142 0.0292358C14.4072 0.0292358 14.6615 0.134551 14.849 0.322022L17.678 3.15102C17.8655 3.33855 17.9708 3.59286 17.9708 3.85802C17.9708 4.12319 17.8655 4.37749 17.678 4.56502L4.243 18H4.242Z",
  TRASH:
    "M15 4H20V6H18V19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H3C2.73478 20 2.48043 19.8946 2.29289 19.7071C2.10536 19.5196 2 19.2652 2 19V6H0V4H5V1C5 0.734784 5.10536 0.48043 5.29289 0.292893C5.48043 0.105357 5.73478 0 6 0H14C14.2652 0 14.5196 0.105357 14.7071 0.292893C14.8946 0.48043 15 0.734784 15 1V4ZM16 6H4V18H16V6ZM7 9H9V15H7V9ZM11 9H13V15H11V9ZM7 2V4H13V2H7Z",
  TABLE_EMPTY:
    "M11.8125 0.00219825C11.4017 0.0411752 11.013 0.206248 10.6997 0.47479C10.3864 0.743331 10.1638 1.1022 10.0625 1.5022L0.0625038 41.5022C0.0206764 41.6656 -0.000323499 41.8336 3.76665e-06 42.0022V66.0022C5.67739e-05 66.5326 0.210787 67.0413 0.585849 67.4164C0.96091 67.7914 1.46959 68.0021 2 68.0022H86C86.5304 68.0021 87.0391 67.7914 87.4142 67.4164C87.7892 67.0413 87.9999 66.5326 88 66.0022V42.0022C88.0003 41.8336 87.9793 41.6656 87.9375 41.5022L77.9375 1.5022C77.8266 1.07274 77.576 0.692325 77.2253 0.420793C76.8746 0.14926 76.4436 0.002009 76 0.00219825H12C11.9375 -0.000732751 11.875 -0.000732751 11.8125 0.00219825ZM13.5625 4.0022H74.4375L83.4375 40.0022H61C59.9528 40.0023 59.0001 40.955 59 42.0022V48.0022H29V42.0022C28.9999 40.955 28.0472 40.0023 27 40.0022H4.5625L13.5625 4.0022ZM4 44.0022H25V50.0022C25.0001 51.0494 25.9528 52.0021 27 52.0022H61C62.0472 52.0021 62.9999 51.0494 63 50.0022V44.0022H84V64.0022H4V44.0022Z,",
  CHEVRON_UP:
    "M7.00023 2.828L2.05023 7.778L0.63623 6.364L7.00023 0L13.3642 6.364L11.9502 7.778L7.00023 2.828Z",
  CHEVRON_DOWN:
    "M7.00023 5.17198L11.9502 0.221985L13.3642 1.63598L7.00023 7.99998L0.63623 1.63598L2.05023 0.221985L7.00023 5.17198Z",
  CHEVRON_LEFT:
    "M2.828 6.99999L7.778 11.95L6.364 13.364L0 6.99999L6.364 0.635986L7.778 2.04999L2.828 6.99999Z",
  CHEVRON_RIGHT:
    "M5.17266 7.00072L0.222656 2.05072L1.63666 0.636719L8.00066 7.00072L1.63666 13.3647L0.222656 11.9507L5.17266 7.00072Z",
  CHECK_CIRCLE:
    "M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM9.003 14L4.76 9.757L6.174 8.343L9.003 11.172L14.659 5.515L16.074 6.929L9.003 14Z",

  APPOINTMENT_LOGO:
    "M41.1469 2.625H35.5688V0.853125C35.5688 0.328125 35.2406 0 34.7156 0C34.1906 0 33.8625 0.328125 33.8625 0.853125V2.625H26.4469V0.853125C26.4469 0.328125 26.1187 0 25.5938 0C25.0688 0 24.7406 0.328125 24.7406 0.853125V2.625H17.325V0.853125C17.325 0.328125 16.9969 0 16.4719 0C15.9469 0 15.6187 0.328125 15.6187 0.853125V2.625H8.20312V0.853125C8.20312 0.328125 7.875 0 7.35 0C6.825 0 6.49687 0.328125 6.49687 0.853125V2.625H0.853125C0.328125 2.625 0 2.95312 0 3.47813V41.0812C0 41.6719 0.328125 42 0.853125 42H41.0812C41.6063 42 41.9344 41.6719 41.9344 41.1469V3.47813C42 2.95312 41.6719 2.625 41.1469 2.625ZM16.0781 16.0125V23.0344H9.77812V16.0125H16.0781ZM24.15 16.0125V23.0344H17.85V16.0125H24.15ZM32.2219 16.0125V23.0344H25.9219V16.0125H32.2219ZM40.2281 16.0125V23.0344H33.9281V16.0125H40.2281ZM24.15 24.7406V31.6313H17.85V24.7406H24.15ZM8.07188 23.0344H1.77188V16.0125H8.07188V23.0344ZM1.77188 24.7406H8.07188V31.6313H1.77188V24.7406ZM9.77812 24.7406H16.0781V31.6313H9.77812V24.7406ZM16.0781 33.3375V40.2281H9.77812V33.3375H16.0781ZM17.85 33.3375H24.15V40.2281H17.85V33.3375ZM25.9219 33.3375H32.2219V40.2281H25.9219V33.3375ZM25.9219 31.5656V24.675H32.2219V31.5656H25.9219ZM33.9281 24.7406H40.2281V31.6313H33.9281V24.7406ZM6.49687 4.39687V6.16875C6.49687 6.69375 6.825 7.02187 7.35 7.02187C7.875 7.02187 8.20312 6.69375 8.20312 6.16875V4.39687H15.5531V6.16875C15.5531 6.69375 15.8813 7.02187 16.4062 7.02187C16.9312 7.02187 17.2594 6.69375 17.2594 6.16875V4.39687H24.6094V6.16875C24.6094 6.69375 24.9375 7.02187 25.4625 7.02187C25.9875 7.02187 26.3156 6.69375 26.3156 6.16875V4.39687H33.6656V6.16875C33.6656 6.69375 33.9937 7.02187 34.5187 7.02187C35.0438 7.02187 35.3719 6.69375 35.3719 6.16875V4.39687H40.0969V14.3062H1.77188V4.39687H6.49687ZM1.77188 33.3375H8.07188V40.2281H1.77188V33.3375ZM33.9281 40.2281V33.3375H40.2281V40.2281H33.9281Z",
  NOTE: "M12.75 1.5H15C15.1989 1.5 15.3897 1.57902 15.5303 1.71967C15.671 1.86032 15.75 2.05109 15.75 2.25V15.75C15.75 15.9489 15.671 16.1397 15.5303 16.2803C15.3897 16.421 15.1989 16.5 15 16.5H3C2.80109 16.5 2.61032 16.421 2.46967 16.2803C2.32902 16.1397 2.25 15.9489 2.25 15.75V2.25C2.25 2.05109 2.32902 1.86032 2.46967 1.71967C2.61032 1.57902 2.80109 1.5 3 1.5H5.25V0H6.75V1.5H11.25V0H12.75V1.5ZM12.75 3V4.5H11.25V3H6.75V4.5H5.25V3H3.75V15H14.25V3H12.75ZM5.25 6H12.75V7.5H5.25V6ZM5.25 9H12.75V10.5H5.25V9Z",
  REFRESH:
    "M2.64192 1.95404C3.85162 0.905811 5.39924 0.329805 6.99992 0.332038C10.6819 0.332038 13.6666 3.3167 13.6666 6.9987C13.6666 8.4227 13.2199 9.7427 12.4599 10.8254L10.3333 6.9987H12.3333C12.3333 5.95312 12.0261 4.93057 11.4497 4.05821C10.8733 3.18584 10.0532 2.50214 9.09137 2.09212C8.12954 1.6821 7.0684 1.56384 6.03989 1.75205C5.01138 1.94026 4.06089 2.42664 3.30659 3.1507L2.64192 1.95404ZM11.3579 12.0434C10.1482 13.0916 8.60059 13.6676 6.99992 13.6654C3.31792 13.6654 0.333252 10.6807 0.333252 6.9987C0.333252 5.5747 0.779919 4.2547 1.53992 3.17204L3.66659 6.9987H1.66659C1.6665 8.04429 1.97375 9.06683 2.55014 9.9392C3.12653 10.8116 3.94663 11.4953 4.90847 11.9053C5.8703 12.3153 6.93144 12.4336 7.95995 12.2454C8.98845 12.0571 9.93895 11.5708 10.6933 10.8467L11.3579 12.0434Z",
  CALENDER:
    "M15 2H19C19.2652 2 19.5196 2.10536 19.7071 2.29289C19.8946 2.48043 20 2.73478 20 3V19C20 19.2652 19.8946 19.5196 19.7071 19.7071C19.5196 19.8946 19.2652 20 19 20H1C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V3C0 2.73478 0.105357 2.48043 0.292893 2.29289C0.48043 2.10536 0.734784 2 1 2H5V0H7V2H13V0H15V2ZM13 4H7V6H5V4H2V8H18V4H15V6H13V4ZM18 10H2V18H18V10Z",
  CALL: "M7.58053 11.1149C8.69759 13.4699 10.3228 15.4201 12.2853 16.7606L13.3377 14.9921C13.5069 14.7077 13.7571 14.5076 14.041 14.4297C14.3249 14.3518 14.6227 14.4014 14.8781 14.5692C16.5619 15.6734 18.4213 16.3374 20.3341 16.5178C20.6326 16.5462 20.9113 16.7085 21.1145 16.9725C21.3177 17.2365 21.4306 17.5827 21.4305 17.9421V24.3163C21.4306 24.67 21.3213 25.0112 21.1238 25.2738C20.9263 25.5364 20.6547 25.7017 20.3615 25.7378C19.7305 25.8163 19.0948 25.8549 18.4543 25.8549C8.26386 25.8549 0.00195312 15.9406 0.00195312 3.71205C0.00195312 2.94348 0.034096 2.18062 0.0995722 1.42348C0.129637 1.07162 0.267411 0.745655 0.486227 0.508674C0.705043 0.271692 0.989341 0.140549 1.2841 0.140625H6.596C6.89548 0.14058 7.18397 0.275984 7.40396 0.51984C7.62394 0.763696 7.75925 1.09809 7.78291 1.45634C7.93317 3.75176 8.48657 5.98302 9.40672 8.00348C9.54656 8.31 9.58792 8.6674 9.52299 9.00805C9.45805 9.3487 9.29132 9.64897 9.05434 9.85205L7.58053 11.1149ZM4.57814 10.1763L6.84005 8.23777C6.19812 6.57504 5.75833 4.8104 5.5341 2.99777H2.39481C2.38767 3.23491 2.3841 3.47348 2.3841 3.71205C2.38291 14.3635 9.57815 22.9978 18.4543 22.9978C18.6531 22.9978 18.852 22.9935 19.0496 22.9835V19.2163C17.5391 18.9473 16.0685 18.4195 14.6829 17.6492L13.0674 20.3635C12.417 20.0602 11.7853 19.7022 11.177 19.2921L11.1079 19.2449C8.77288 17.6502 6.83919 15.3298 5.51029 12.5278L5.471 12.4449C5.12922 11.7149 4.83086 10.9568 4.57814 10.1763Z",
  WARNING:
    "M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM9 13H11V15H9V13ZM9 5H11V11H9V5Z",
};
