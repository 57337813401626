import { createSlice } from "@reduxjs/toolkit";

const patientsSlice = createSlice({
  name: "patients",
  initialState: {
    allPatients: null,
    allPatientsLoading: false,
    allPatientList: null,
    myPatients: null,
    myPatientsLoading: false,
    patientMonitoringFilter: null,
    patientMonitoringFilterLoading: false,
    allAlertCount: null,
    patientProfileDetails: null,

    showConsentModal: false,
    patientAllEnrollmentStatus: [],
    patientEnrollmentStatus: null,
    patientEnrollmentStatusLoading: true,
    patientEnrollmentStatusError: false,

    patientEnrollmentNotFound: false,
    consentNotReceived: true,

    allPatientCarePackages: [],

    patientSummaryVitals: null,
    patientSummaryVitalsLoading: true,
    patientSummaryVitalsError: false,
    patientDetailedVitals: null,
    patientDetailedVitalsLoading: true,
    patientDetailedVitalsError: false,

    patientSummaryClinicalData: null,
    patientSummaryClinicalDataLoading: true,
    patientSummaryClinicalDataError: false,
    patientDetailedClinicalData: null,
    patientDetailedClinicalDataLoading: true,
    patientDetailedClinicalDataError: false,

    patientSummaryConditions: null,
    patientSummaryConditionsLoading: true,
    patientSummaryConditionsError: false,
    patientDetailedCondtions: null,
    patientDetailedConditionsLoading: true,
    patientDetailedCondtionsError: false,

    patientSummaryEncounters: null,
    patientSummaryEncountersLoading: true,
    patientSummaryEncountersError: false,
    patientDetailedEncounters: null,
    patientDetailedEncountersLoading: true,
    patientDetailedEncountersError: false,

    patientsMedication: null,
    patientMedicationLoading: true,
    patientMedicationError: false,

    patientAllergies: null,
    patientAllergiesLoading: true,
    patientAllergiesError: false,

    patientCoverage: null,
    patientCoverageLoading: true,
    patientCoverageError: false,

    patientDocuments: null,
    patientDocumentsLoading: true,
    patientDocumentsError: false,

    patientSurveys: null,
    patientSurveysLoading: true,
    patientSurveysError: false,

    patientDevices: null,
    patientDevicesLoading: true,
    patientDevicesError: false,

    patientDetailDevices: null,
    patientDetailDevicesLoading: true,
    patientDetailDevicesError: false,
    deviceReadingListGlobal: [],
    nonClinicalAlertIndicator: "",
  },
  reducers: {
    getAllPatients(status) {},
    getAllPatientsList(status) {},
    getAllPatientsWithParams(params) {},
    getallAlertCount(params) {},
    setAllPatients(state, action) {
      state.allPatients = action.payload;
    },
    setallAlertCount(state, action) {
      state.allAlertCount = action.payload;
    },
    setAllPatientList(state, action) {
      state.allPatientList = action.payload;
    },
    setAllPatientsLoading(state, action) {
      state.allPatientsLoading = action.payload;
    },
    setNonClinicalAlertIndicator(state, action) {
      state.nonClinicalAlertIndicator = action.payload;
    },
    getMyPatients(email) {},
    getMyPatientsWithParams(id) {},
    getPatientMonitoringFilter(params) {},
    setPatientMonitoringFilter(state, action) {
      state.patientMonitoringFilter = action.payload;
    },
    setMyPatients(state, action) {
      state.myPatients = action.payload;
    },
    setMyPatientsLoading(state, action) {
      state.myPatientsLoading = action.payload;
    },
    setPatientMonitoringFilterLoading(state, action) {
      state.patientMonitoringFilterLoading = action.payload;
    },

    getPatientProfileDetails(id) {},
    setPatientProfileDetails(state, action) {
      state.patientProfileDetails = action.payload;
    },

    setDeviceReadingListGlobal(state, action) {
      state.deviceReadingListGlobal = action.payload;
    },
    getDeviceReadingListGlobal() {},

    // setDeviceReadingListWithDate(state, action) {
    //   state.deviceReadingListWithDate = action.payload;
    // },
    // getDeviceReadingListWithDate() { },

    getPatientEnrollmentStatus(id, state) {
      state.patientEnrollmentStatus = null;
      state.patientAllEnrollmentStatus = [];
      state.patientEnrollmentNotFound = false;
    },
    setPatientEnrollmentStatus(state, action) {
      state.patientAllEnrollmentStatus = action.payload.entry;
      state.patientEnrollmentStatusLoading = false;
      state.patientEnrollmentNotFound = false;
    },
    setPatientEnrollmentStatusError(state, action) {
      state.patientEnrollmentStatusError = action.payload.error;
      state.patientEnrollmentStatusLoading = action.payload.loading;
    },

    setPatientEnrollmentNotFound(state, action) {
      state.patientEnrollmentNotFound = true;
      state.patientEnrollmentStatusLoading = false;
    },

    setSelectedPatientEnrollmentStatus(state, action) {
      state.patientEnrollmentStatus = action.payload;
    },

    setConsentNotReceived(state, action) {
      state.consentNotReceived = action.payload;
    },

    resetAllEnrollmentStatus(state) {
      state.patientAllEnrollmentStatus = [];
      state.patientEnrollmentStatus = null;
    },

    deleteCarePackage() {},
    deletePatient() {},

    setShowConsentModal(state, action) {
      state.showConsentModal = action.payload;
    },

    getAllPatientCarePackages(id) {},
    setAllPatientCarePackages(state, action) {
      state.allPatientCarePackages = action.payload;
    },

    resendEmail(id) {},

    getPatientSummaryVitals(params) {},
    setPatientSummaryVitals(state, action) {
      state.patientSummaryVitals = action.payload.entry;
      state.patientSummaryVitalsLoading = false;
    },
    setPatientSummaryVitalsError(state, action) {
      state.patientSummaryVitalsError = action.payload.error;
      state.patientSummaryVitalsLoading = action.payload.loading;
    },

    getPatientDetailedVitals(params) {},
    setPatientDetailedVitals(state, action) {
      state.patientDetailedVitals = action.payload.entry;
      state.patientDetailedVitalsLoading = false;
    },
    setPatientDetailedVitalsError(state, action) {
      state.patientDetailedVitalsError = action.payload.error;
      state.patientDetailedVitalsLoading = action.payload.loading;
    },

    getPatientSummaryClinicalData(params) {},
    setPatientSummaryClinicalData(state, action) {
      state.patientSummaryClinicalData = action.payload.entry;
      state.patientSummaryClinicalDataLoading = false;
    },
    setPatientSummaryClinicalDataError(state, action) {
      state.patientSummaryClinicalDataError = action.payload.error;
      state.patientSummaryClinicalDataLoading = action.payload.loading;
    },

    getPatientDetailedClinicalData(params) {},
    setPatientDetailedClinicalData(state, action) {
      state.patientDetailedClinicalData = action.payload.entry;
      state.patientDetailedClinicalDataLoading = false;
    },
    setPatientDetailedClinicalDataError(state, action) {
      state.patientDetailedClinicalDataError = action.payload.error;
      state.patientDetailedClinicalDataLoading = action.payload.loading;
    },

    getPatientSummaryConditions(params) {},
    setPatientSummaryConditions(state, action) {
      state.patientSummaryConditions = action.payload.entry;
      state.patientSummaryConditionsLoading = false;
    },
    setPatientSummaryConditionsError(state, action) {
      state.patientSummaryConditionsError = action.payload.error;
      state.patientSummaryConditionsLoading = action.payload.loading;
    },

    getPatientDetailedConditions(params) {},
    setPatientDetailedConditions(state, action) {
      state.patientDetailedConditions = action.payload.entry;
      state.patientDetailedConditionsLoading = false;
    },
    setPatientDetailedConditionsError(state, action) {
      state.patientDetailedConditionsLoading = action.payload.error;
      state.patientDetailedConditionsLoading = action.payload.loading;
    },

    getPatientSummaryEncounters(params) {},
    setPatientSummaryEncounters(state, action) {
      state.patientSummaryEncounters = action.payload.entry;
      state.patientSummaryEncountersLoading = false;
    },
    setPatientSummaryEncountersError(state, action) {
      state.patientSummaryEncountersError = action.payload.error;
      state.patientSummaryEncountersLoading = action.payload.loading;
    },

    getPatientDetailedEncounters(params) {},
    setPatientDetailedEncounters(state, action) {
      state.patientDetailedEncounters = action.payload.entry;
      state.patientDetailedEncountersLoading = false;
    },
    setPatientDetailedEncountersError(state, action) {
      state.patientDetailedEncountersError = action.payload.error;
      state.patientDetailedEncountersLoading = action.payload.loading;
    },

    getPatientsMedication(params) {},
    setPatientsMedication(state, action) {
      state.patientsMedication = action.payload.entry;
      state.patientMedicationLoading = false;
    },
    setPatientMedicationError(state, action) {
      state.patientMedicationError = action.payload.error;
      state.patientMedicationLoading = action.payload.loading;
    },
    getPatientAllergies(id) {},
    setPatientAllergies(state, action) {
      state.patientAllergies = action.payload.entry;
      state.patientAllergiesLoading = false;
    },
    setPatientAllergiesError(state, action) {
      state.patientAllergiesError = action.payload.error;
      state.patientAllergiesLoading = action.payload.loading;
    },
    getPatientCoverage(id) {},
    setPatientCoverage(state, action) {
      state.patientCoverage = action.payload.entry;
      state.patientCoverageLoading = false;
    },
    setPatientCoverageError(state, action) {
      state.patientCoverageError = action.payload.error;
      state.patientCoverageLoading = action.payload.loading;
    },
    getPatientsDocuments(params) {},
    setPatientsDocuments(state, action) {
      state.patientDocuments = action.payload.entry;
      state.patientDocumentsLoading = false;
    },
    setPatientDocumentsError(state, action) {
      state.patientDocumentsError = action.payload.error;
      state.patientDocumentsLoading = action.payload.loading;
    },
    getPatientSurveys(param) {},
    setPatientSurveys(state, action) {
      state.patientSurveys = action.payload.entry;
      state.patientSurveysLoading = false;
    },
    setPatientSurveysError(state, action) {
      state.patientSurveysError = action.payload.error;
      state.patientSurveysLoading = action.payload.loading;
    },
    getPatientDevices(id) {},
    setPatientDevices(state, action) {
      state.patientDevices = action.payload;
      state.patientDevicesLoading = false;
    },
    setPatientDevicesError(state, action) {
      state.patientDevicesError = action.payload.error;
      state.patientDevicesLoading = action.payload.loading;
    },
    getPatientDevicesDetails(params) {},
    setPatientDevicesDetails(state, action) {
      state.patientDetailDevices = action.payload;
      state.patientDetailDevicesLoading = false;
    },
    setPatientDevicesDetailsError(state, action) {
      state.patientDetailDevicesError = action.payload.error;
      state.patientDetailDevicesLoading = action.payload.loading;
    },
    patientPackageInitiation(params) {},
    getCarePackageAuthorName(params) {},

    sendEnrollmentConsent(params) {},
    initiateCareDesign(params) {},
  },
});

export const {
  getCarePackageAuthorName,
  getAllPatients,
  getAllPatientsWithParams,
  setAllPatients,
  getMyPatients,
  setAllPatientList,
  getMyPatientsWithParams,
  getPatientMonitoringFilter,
  setPatientMonitoringFilter,
  setPatientMonitoringFilterLoading,
  setMyPatients,
  setAllPatientsLoading,
  setMyPatientsLoading,
  setNonClinicalAlertIndicator,
  getPatientDevicesDetails,
  setPatientDevicesDetails,
  setPatientDevicesDetailsError,
  getPatientProfileDetails,
  setPatientProfileDetails,
  getPatientEnrollmentStatus,
  setPatientEnrollmentStatus,
  setPatientEnrollmentStatusError,
  setPatientEnrollmentNotFound,
  resetAllEnrollmentStatus,
  setConsentNotReceived,
  setSelectedPatientEnrollmentStatus,
  deleteCarePackage,
  deletePatient,
  resendEmail,
  getPatientSummaryVitals,
  setPatientSummaryVitals,
  setPatientSummaryVitalsError,
  getPatientDetailedVitals,
  setPatientDetailedVitals,
  setPatientDetailedVitalsError,
  getPatientSummaryClinicalData,
  setPatientSummaryClinicalData,
  setPatientSummaryClinicalDataError,
  getPatientDetailedClinicalData,
  setPatientDetailedClinicalData,
  setPatientDetailedClinicalDataError,
  getPatientSummaryConditions,
  setPatientSummaryConditions,
  setPatientSummaryConditionsError,
  getPatientDetailedConditions,
  setPatientDetailedConditions,
  setPatientDetailedConditionsError,
  getPatientSummaryEncounters,
  setPatientSummaryEncounters,
  setPatientSummaryEncountersError,
  getPatientDetailedEncounters,
  setPatientDetailedEncounters,
  setPatientDetailedEncountersError,
  getPatientAllergies,
  setPatientAllergies,
  setPatientAllergiesError,
  getPatientCoverage,
  setPatientCoverage,
  setPatientCoverageError,
  getPatientsMedication,
  setPatientsMedication,
  setPatientMedicationError,
  getPatientsDocuments,
  setPatientsDocuments,
  setPatientDocumentsError,
  patientPackageInitiation,

  getPatientSurveys,
  setPatientSurveys,
  setPatientSurveysError,

  getPatientDevices,
  setPatientDevices,
  setPatientDevicesError,

  setDeviceReadingListGlobal,
  getDeviceReadingListGlobal,
  // setDeviceReadingListWithDate,
  // getDeviceReadingListWithDate,
  getallAlertCount,
  setallAlertCount,
  getAllPatientCarePackages,
  setAllPatientCarePackages,
  sendEnrollmentConsent,
  initiateCareDesign,
  setShowConsentModal,
} = patientsSlice.actions;

export default patientsSlice.reducer;
