import { call, put, select } from "redux-saga/effects";
import {
  setCareTeamTemplatesEnrollment,
  setSelectedCareTeamEnrollment,
  setPractitioners,
  resetNewTemplate,
  setAssociationCareTeam,
  setIsCareTeamHardSet,
  getAssociationCareTeam,
} from "./careTeamSlice";
import {
  requestGetCareTeamTemplatesEnrollment,
  requestGetCareTeamByNameEnrollment,
  requestGetPractitioners,
  requestCreateNewCareTeam,
  requestGetCareTeamById,
  requestUpdateExistingCareTeam,
} from "./careTeamRequest";

import {
  setSelectedPatientCareTeam,
  setReviewer,
} from "./../../../patientCarePackage/patientCarePackageSlice";
import { CARE_TEAM } from "./../../../../utils/constants";

import { History } from "../../../../history";
import { setSelectedPatient } from "./careTeamSlice";
import { requestGetPatientById } from "./careTeamRequest";

const allPractitioners = (state) => state.patientsCareTeam.allPractitioners;

export function* handleGetCareTeamTemplatesEnrollment(action) {
  try {
    const response = yield call(
      requestGetCareTeamTemplatesEnrollment,
      action.payload
    );
    const { data } = response;
    yield put(setCareTeamTemplatesEnrollment({ ...data }));
  } catch (error) {
    console.log("something went wrong!");
  }
}

export function* handleGetCareTeamByNameEnrollment(action) {
  try {
    const response = yield call(
      requestGetCareTeamByNameEnrollment,
      action.payload
    );
    const { entry } = response.data;
    const careTeam = Array.isArray(entry) && entry.length > 0 ? entry[0] : null;
    yield put(setSelectedCareTeamEnrollment(careTeam));
  } catch (error) {
    console.log("something went wrong!");
  }
}

export function* handleGetPractitioners(action) {
  try {
    const response = yield call(requestGetPractitioners, action.payload);
    const { entry } = response.data;
    yield put(setPractitioners({ entry, specialty: action.payload }));
  } catch (error) {
    console.log("something went wrong!");
  }
}

function* handleCreateUpdateCareTeam(action, type) {
  let reviewer;
  let attendingPhysician = _.find(action.payload.care_role_specialists, {
    role: CARE_TEAM.ROLES.ATTENDING_PHYSICIAN,
  });
  const allMembers = yield select(allPractitioners);
  if (attendingPhysician && attendingPhysician.member) {
    reviewer = allMembers.find(
      (m) => m.practitionerRoleId === attendingPhysician.member.reference
    );
    if (reviewer) {
      yield put(setReviewer(reviewer.practitionerEmail));
    }
  }
  try {
    yield put(resetNewTemplate());
    yield put(setSelectedPatientCareTeam(action.payload));

    if (type === "update") {
      yield put(
        getAssociationCareTeam({
          id: action.payload.patient.patientId,
          cpId: action.payload.cpIdentifier,
        })
      );
    }
  } catch (error) {
    if (type === "create") {
      yield put(resetNewTemplate());
      yield put(setSelectedPatientCareTeam(action.payload));
    }
    console.log("something went wrong!");
  }
}

export function* handleCreateNewCareTeam(action) {
  yield handleCreateUpdateCareTeam(action, "create");
}

export function* handleHardCreateNewCareTeam(action) {
  try {
    const response = yield call(requestCreateNewCareTeam, action.payload);
    if (response) {
      yield put(setIsCareTeamHardSet(true));
    }
  } catch (error) {
    console.log("something went wrong!");
    yield put(setIsCareTeamHardSet(false));
  }
}

export function* handleUpdateExistingCareTeam(action) {
  yield handleCreateUpdateCareTeam(action, "update");
}

export function* handleHardUpdateExistingCareTeam(action) {
  try {
    const response = yield call(requestUpdateExistingCareTeam, action.payload);
    if (response) {
      yield put(setIsCareTeamHardSet(true));
    }
  } catch (error) {
    console.log("something went wrong!");
    yield put(setIsCareTeamHardSet(false));
  }
}

export function* handleGetPatientById(action) {
  try {
    const response = yield call(requestGetPatientById, action.payload);
    const { data } = response;
    yield put(setSelectedPatient({ ...data }));
  } catch (error) {
    console.log("something went wrong!");
    History.navigate("/patients");
  }
}

export function* handleGetCareTeamById(action) {
  try {
    const response = yield call(requestGetCareTeamById, action.payload);
    const { entry } = response.data;
    const careTeam = Array.isArray(entry) && entry.length > 0 ? entry[0] : null;
    yield put(setAssociationCareTeam(careTeam));
    if (careTeam) {
      yield put(setSelectedCareTeamEnrollment({ ...careTeam }));
    }
  } catch (error) {
    console.log("something went wrong!");
  }
}
