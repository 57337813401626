import { Suspense } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, useNavigate } from "react-router-dom";
import store from "./store";
import { AuthProvider } from "./contexts/AuthContext";
import Layout from "./components/Layout";
import { History } from "./history";

const NavigateSetter = () => {
  History.navigate = useNavigate();

  return null;
};

function App() {
  return (
    <main>
      <Provider store={store}>
        <BrowserRouter>
          <NavigateSetter />
          <AuthProvider>
            <Suspense
              fallback={
                <div className="d-flex justify-content-center vh-100 mx-auto align-items-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              }
            >
              <Layout />
            </Suspense>
          </AuthProvider>
        </BrowserRouter>
      </Provider>
    </main>
  );
}

export default App;
