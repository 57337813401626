import Modal from "react-bootstrap/Modal";
import "./NoChatPopUp.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { alert } from "../../../assets/icons/index";

const NoChatWindow = ({ showModal = false, OnClose }) => {
  return (
    <div className="NoChatWindow">
      <Modal
        dialogClassName="modal-chat"
        show={showModal}
        size="md"
        onHide={() => OnClose()}
        centered
      >
        <Modal.Body>
          <Container>
            <Row className="mt-2">
              <Col sm={2} className="alert-icon">
                <img src={alert} className="alert-img" />
              </Col>
              <Col sm={10}>
                <p className="font-weight-bold">Patient Chat</p>
                <p>You are not a part of any care team.</p>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end ">
          <>
            <button
              className="btn gh-btn-primary"
              onClick={() => {
                OnClose();
              }}
            >
              Close
            </button>
          </>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default NoChatWindow;
