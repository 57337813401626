import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Navbar, Nav, NavDropdown, Container, Button } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import "./NavigationBar.scss";
import {
  logo1,
  userLogon,
  optionalImage,
  calendar,
  upload,
  MessageIcon,
} from "../../assets/icons/index";
import { useAuth } from "../../contexts/AuthContext";
import {
  getUserProfile,
  getUserPermissions,
  getInsuffPermissions,
  resetAppointments,
  resetPermissions,
} from "./../../store/appointments/appointmentsSlice";
import { getCarePackageCategory } from "./../../store/shared/sharedSlice";
import { useDispatch, useSelector } from "react-redux";
import NumberFormat from "react-number-format";
import RightArrow from "../../assets/icons/line/Chevron Right.png";
import {
  SSO_LOGOUT_URI,
  SSO_BASE_URL,
  SSO_CLIENT_ID,
  CARE_PACKAGE_BASE_URL,
  APP_SERVICES,
  PERMISSIONS_SET,
  CHAT_API_KEY,
  USER_ROLE_MANAGEMENT_URL,
} from "../../utils/constants";
import { StreamChat } from "stream-chat";
import "stream-chat-react/dist/css/index.css";
import ChatWindow from "../ChatWindow/ChatWindow";
import NoChatWindow from "../ChatWindow/NoChat/NoChatPopUp";
import { getChatProfile } from "../../services/chat-service";

import { urmService } from "../../services/urmService";
import { Permissions } from "../../components/Common/Permissions";

const NavigationBar = () => {
  const { currentUser, logout, ssoSignout, email } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [chatClient, setChatClient] = useState(null);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(null);
  const [showNoChatModal, handleNoChatModalPopUp] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showPreferenceModal, setShowPreferenceModal] = useState(false);
  const { currentUserProfile } = useSelector((state) => state.appointments);
  const [getStreamUserId, setGetStreamUserId] = useState("");
  const [name, setName] = useState("");
  const [isAdmin, setUserRole] = useState(false);
  const [alertValue, setAlertValue] = useState(false);

  const { userPermissions } = useSelector((state) => state.appointments);
  const { allAlertCount, nonClinicalAlertIndicator } = useSelector(
    (state) => state.patients
  );

  const [myPermissions, setPermissions] = useState(null);

  useEffect(() => {
    if (allAlertCount > 0) {
      setAlertValue(true);
    } else if (
      nonClinicalAlertIndicator === "red" ||
      nonClinicalAlertIndicator === "yellow"
    ) {
      setAlertValue(true);
    } else {
      setAlertValue(false);
    }
  }, [allAlertCount, nonClinicalAlertIndicator]);

  useEffect(() => {
    if (currentUser && currentUser.email) {
      const userEmail = currentUser?.email ? currentUser.email : email;
      dispatch(getUserProfile(userEmail));
      dispatch(getUserPermissions({ email: userEmail }));
      dispatch(getInsuffPermissions());
      dispatch(getCarePackageCategory());
      localStorage.setItem("UrmServiceProvider", "0");
    } else if (email) {
      dispatch(getUserProfile(email));
      dispatch(getCarePackageCategory());
    }
  }, [currentUser, email, USER_ROLE_MANAGEMENT_URL]);

  useEffect(() => {
    const mapPermissions = async () => {
      const permissions = await urmService.getPermissionsList(userPermissions);
      setPermissions(permissions);
    };
    mapPermissions();
  }, [userPermissions]);

  useEffect(() => {
    let seen = window.sessionStorage.getItem("prefSeen");
    if (
      currentUserProfile &&
      !currentUserProfile.isAppointmentPreferencesSet &&
      !seen
    ) {
      setShowPreferenceModal(true);
      window.sessionStorage.setItem("prefSeen", "true");
    }
    if (currentUserProfile) {
      let names = currentUserProfile.name.split(",");
      let username = names[1].trim() + " " + names[0].trim();
      setName(username);

      const role = currentUserProfile.role || "";
      if (role === "admin" || role === "superadmin") {
        setUserRole(true);
      }
    }
  }, [currentUserProfile]);

  useEffect(() => {
    async function initChat() {
      const emailId = currentUser?.email ? currentUser.email : email;
      const res = await getChatProfile(emailId);
      if (res.data.chatProfileId) {
        setGetStreamUserId(res.data.chatProfileId);
        const client = StreamChat.getInstance(CHAT_API_KEY);
        client.connectUser({ id: res.data.chatProfileId }, async () => {
          const response = await getChatProfile(emailId);
          return response.data.chatProfileToken;
        });

        try {
          setChatClient(client);
          client.on((event) => {
            if (event.total_unread_count !== undefined) {
              setUnreadMessagesCount(event.total_unread_count);
            }
          });
          client.on("health.check", (event) => {
            if (event.me?.total_unread_count !== undefined) {
              setUnreadMessagesCount(event.me.total_unread_count);
            }
          });
        } catch (err) {
          console.log(err);
        }
      }
    }
    initChat();
    return () => {
      if (chatClient) chatClient.disconnectUser();
    };
  }, []);

  const handleModal = async () => {
    if (chatClient) {
      const filter = { members: { $in: [chatClient.user.id] } };
      const sort = { last_message_at: -1 };
      const channels = await chatClient.queryChannels(filter, sort, {
        watch: true,
      });
      if (channels.length > 0) {
        setShowModal(true);
      } else {
        handleNoChatModalPopUp(true);
      }
    } else {
      handleNoChatModalPopUp(true);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const closeNoChatModal = () => {
    handleNoChatModalPopUp(false);
  };

  const logoutUser = async (e) => {
    e.preventDefault();
    if (currentUser && currentUser.email) {
      try {
        await logout();
        dispatch(resetAppointments());
        dispatch(resetPermissions());
        sessionStorage.removeItem("prefSeen");
        localStorage.clear();
        window.location.href = "/";
      } catch {}
    } else {
      await localStorage.clear();
      ssoSignout();
      navigate("/login");
    }
    if (chatClient) {
      chatClient.disconnectUser();
    }
  };

  const ssoLogOut = async () => {
    await localStorage.clear();
    ssoSignout();
  };

  const urmRedirect = async (e) => {
    navigate("/URM");
  };

  const AppointmentPreferences = async (e) => {
    navigate("/appointments/preferences");
  };

  const constructDebugTitle = () => {
    if (CARE_PACKAGE_BASE_URL.includes("api.qa")) {
      return "Running in: QA";
    }
    if (CARE_PACKAGE_BASE_URL.includes("api.dev")) {
      return "Running in: Dev";
    }
    if (CARE_PACKAGE_BASE_URL.includes("api.perf")) {
      return "Running in: Perf";
    }
    if (CARE_PACKAGE_BASE_URL.includes("api.demo")) {
      return "Running in: Demo";
    }
    if (CARE_PACKAGE_BASE_URL.includes("api.test")) {
      return "Running in: Test";
    }

    return "";
  };

  return (
    <React.Fragment>
      <Navbar bg="white" variant="light" className="navigationBar pb-1">
        <Container className="container w-100">
          <Navbar.Brand className="me-5" href="/dashboard">
            <span>
              <img
                title={
                  location?.hostname === "localhost"
                    ? constructDebugTitle()
                    : ""
                }
                src={logo1}
                alt="Logo1"
                className="me-2"
              />
            </span>
          </Navbar.Brand>
          <Nav className="nav justify-content-center navigationBar__linkGroup}">
            <Permissions
              allow={
                myPermissions &&
                urmService.can(
                  PERMISSIONS_SET.VIEW,
                  APP_SERVICES.DASHBOARD,
                  myPermissions
                )
              }
            >
              <NavLink
                className={(navData) =>
                  navData.isActive
                    ? "navigationBar__linkGroup__link--active navigationBar__linkGroup__link"
                    : "navigationBar__linkGroup__link"
                }
                to="/dashboard"
              >
                Dashboard
                {alertValue && (
                  <svg
                    className="red-dot"
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="4" cy="4" r="4" fill="#E83D3D" />
                  </svg>
                )}
              </NavLink>
            </Permissions>
            <Permissions
              allow={
                myPermissions &&
                urmService.can(
                  PERMISSIONS_SET.VIEW,
                  APP_SERVICES.DASHBOARD,
                  myPermissions
                )
              }
            >
              <NavLink
                className={(navData) =>
                  navData.isActive
                    ? "navigationBar__linkGroup__link--active navigationBar__linkGroup__link"
                    : "navigationBar__linkGroup__link"
                }
                to="patients"
              >
                Patients
              </NavLink>
            </Permissions>
            <Permissions
              allow={
                myPermissions &&
                urmService.can(
                  PERMISSIONS_SET.VIEW,
                  APP_SERVICES.APPOINTMENTS,
                  myPermissions
                )
              }
            >
              <NavLink
                className={(navData) =>
                  navData.isActive
                    ? "navigationBar__linkGroup__link--active navigationBar__linkGroup__link"
                    : "navigationBar__linkGroup__link"
                }
                to="/appointments"
              >
                Appointments
              </NavLink>
            </Permissions>
            <Permissions
              allow={
                myPermissions &&
                urmService.can(
                  PERMISSIONS_SET.VIEW,
                  APP_SERVICES.CAREPACKAGE,
                  myPermissions
                )
              }
            >
              <NavLink
                className={(navData) =>
                  navData.isActive
                    ? "navigationBar__linkGroup__link--active navigationBar__linkGroup__link"
                    : "navigationBar__linkGroup__link"
                }
                to="/packages"
              >
                Care Packages
              </NavLink>
            </Permissions>
            <Permissions
              allow={
                myPermissions &&
                urmService.can(
                  PERMISSIONS_SET.VIEW,
                  APP_SERVICES.CARETEAM,
                  myPermissions
                )
              }
            >
              <NavLink
                className={(navData) =>
                  navData.isActive
                    ? "navigationBar__linkGroup__link--active navigationBar__linkGroup__link"
                    : " navigationBar__linkGroup__link"
                }
                to="/careteam"
              >
                Care Team
              </NavLink>
            </Permissions>
          </Nav>
          <Permissions
            allow={
              myPermissions &&
              urmService.can(
                PERMISSIONS_SET.VIEW,
                APP_SERVICES.MESSAGING,
                myPermissions
              )
            }
          >
            <div data-toggle="dropdown" id="collasible-nav-dropdown">
              <img
                src={MessageIcon}
                className="chat-icon"
                onClick={handleModal}
                alt="Chat"
              />
              {unreadMessagesCount > 0 && unreadMessagesCount < 100 && (
                <span className="chat-count">{unreadMessagesCount}</span>
              )}
              {unreadMessagesCount > 99 && (
                <span className="chat-count">99+</span>
              )}
            </div>
          </Permissions>
          <NavDropdown
            title={<img src={userLogon} alt="userLogon" />}
            id="collasible-nav-dropdown"
            className="navdropdownHeader"
          >
            <div className="container">
              <div className="d-flex justify-content-center">
                <img src={userLogon} alt="userLogon" />
              </div>
              <div className="d-flex justify-content-center">
                {currentUserProfile && currentUserProfile.name}
              </div>
              <div className="d-flex justify-content-center">
                {currentUserProfile &&
                  currentUserProfile.practitionerDetail &&
                  currentUserProfile.practitionerDetail.role}
              </div>
              <div
                style={{ textAlign: "center" }}
                className="p-10 d-flex justify-content-center"
              >
                {currentUserProfile &&
                  currentUserProfile.practitionerDetail &&
                  currentUserProfile.practitionerDetail.address}
              </div>
              <div className="d-flex justify-content-center">
                {" "}
                {currentUserProfile &&
                  currentUserProfile.practitionerDetail &&
                  currentUserProfile.practitionerDetail.email_id}
              </div>
              <div className="d-flex justify-content-center">
                <NumberFormat
                  format="(###) ###-####"
                  mask=""
                  name="phoneNumberInput"
                  placeholder="Phone Number Here"
                  displayType={"text"}
                  value={
                    currentUserProfile &&
                    currentUserProfile.practitionerDetail &&
                    currentUserProfile.practitionerDetail.contact_number
                  }
                />
              </div>
              <div className="hrWidth">
                <hr></hr>
              </div>
              <div className="row container">
                <div className="col-md-1">
                  {" "}
                  <img className="calendarimage" src={calendar} alt="group" />
                </div>
                <div
                  className="col-md appointmentPrefText ms-2"
                  onClick={AppointmentPreferences}
                >
                  Appointment Preferences
                </div>
                <div className="col-md-1">
                  <img
                    src={RightArrow}
                    alt="group"
                    onClick={AppointmentPreferences}
                  />
                </div>
              </div>
              <div className="row container">
                <div className="col-md-1"></div>
                <div className="col-md manageText">
                  Manage your appointments from here
                </div>
              </div>
              <Permissions
                allow={
                  myPermissions &&
                  urmService.can(
                    PERMISSIONS_SET.URM_MANAGEMENT,
                    APP_SERVICES.URM,
                    myPermissions
                  )
                }
              >
                <div className="row container mt-2">
                  <div className="col-md-1">
                    {" "}
                    <img
                      src={optionalImage}
                      onClick={urmRedirect}
                      className="iconHeight"
                      alt="group"
                    />
                  </div>
                  <div
                    className="col-md appointmentPrefText ms-2"
                    onClick={urmRedirect}
                  >
                    Access Management
                  </div>
                  <div className="col-md-1">
                    <img src={RightArrow} alt="group" onClick={urmRedirect} />
                  </div>
                </div>
                <div className="row container">
                  <div className="col-md-1"></div>
                  <div className="col-md ml-4 manageText">
                    Manage role access
                  </div>
                </div>
              </Permissions>
              <div className="row container">
                <div className="col-md-1">
                  {" "}
                  <img
                    className="calendarimageLogout"
                    src={upload}
                    alt="group"
                  />
                </div>
                <div className="col-md">
                  {email && email !== "" && (
                    <a
                      className="btn btn-block logout-btn manageText"
                      href={`${SSO_BASE_URL}/logout?client_id=${SSO_CLIENT_ID}&logout_uri=${SSO_LOGOUT_URI}`}
                      onClick={() => ssoLogOut()}
                      target={"_self"}
                    >
                      Logout
                    </a>
                  )}
                  {currentUser && (
                    <Button
                      variant="link"
                      className="btn btn-block logout-btn"
                      onClick={(e) => {
                        if (window.confirm("Are you sure?")) logoutUser(e);
                      }}
                    >
                      Logout
                    </Button>
                  )}
                </div>
                <div className="col-md-1">
                  <img src={RightArrow} alt="group" />
                </div>
              </div>
            </div>
          </NavDropdown>
        </Container>
      </Navbar>
      <ChatWindow
        perms={myPermissions}
        showModal={showModal}
        OnClose={closeModal}
        chatClient={chatClient}
        userId={getStreamUserId}
      />
      <NoChatWindow showModal={showNoChatModal} OnClose={closeNoChatModal} />
      <Modal show={showPreferenceModal} centered>
        <Modal.Body>
          <div className="mx-3">
            <div className="py-2">Hello {currentUserProfile && name},</div>
            <div>
              Let’s set your availability so that patients can schedule their
              appointments with you.
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end border-top-0">
          <>
            <button
              variant="secondary"
              className="btn gh-btn-link-primary"
              onClick={() => {
                setShowPreferenceModal(false);
              }}
            >
              Skip
            </button>
            <button
              variant="primary"
              className="btn gh-btn-primary"
              onClick={() => {
                navigate("/appointments/preferences");
                setShowPreferenceModal(false);
              }}
            >
              Set Availability
            </button>
          </>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default NavigationBar;
