import moment from "moment";
import { find, isUndefined, hasIn } from "lodash";

const renderStringForUnd = (val) => {
  if (typeof val === "undefined") {
    return "NA";
  }

  return val;
};

let PatientsUtil = {
  storeUserData: function (data) {
    let resource = hasIn(data, "entry[0]")
      ? data.entry[0].emrResources.entry[0].resource
      : data.emrResource;

    let officialNameMetadata = find(resource.name, ["use", "official"]);
    // let usualNameMetadata = resource.name[1];
    let addressMetadata = [];
    let telecomMetadata;
    if (resource.telecom) {
      telecomMetadata = resource.telecom;
    }
    if (resource.address) {
      addressMetadata = resource.address;
    }

    const user = {
      patientId: data.patientId || data.id || null,
      enrollmentStatus: data.enrollmentStatus,
      name: {
        full: officialNameMetadata.text,
        family: officialNameMetadata.family,
        given: officialNameMetadata.given.join(" "),
      },
      telecom: {
        homePhone: !isUndefined(find(telecomMetadata, { use: "home" }))
          ? find(telecomMetadata, { use: "home" }).value
          : null,
        workPhone: !isUndefined(find(telecomMetadata, { use: "work" }))
          ? find(telecomMetadata, { use: "work" }).value
          : null,
        mobilePhone: !isUndefined(find(telecomMetadata, { use: "mobile" }))
          ? find(telecomMetadata, { use: "mobile" }).value
          : null,
        email: data?.email ? data.email : null,
      },
      address: {
        old: {
          line: addressMetadata[0]?.line[0].replace(/[,.]/g, ""),
          district: addressMetadata[0]?.district,
          city: addressMetadata[0]?.city,
          postalCode: addressMetadata[0]?.postalCode,
          state: addressMetadata[0]?.state,
          country: addressMetadata[0]?.country,
        },
        home: {
          line: hasIn(addressMetadata[1])
            ? addressMetadata[1].line[1].replace(/[,.]/g, "")
            : null,
          district: hasIn(addressMetadata[1])
            ? addressMetadata[1].district
            : null,
          city: hasIn(addressMetadata[1]) ? addressMetadata[1].city : null,
          postalCode: hasIn(addressMetadata[1])
            ? addressMetadata[1].postalCode
            : null,
          state: hasIn(addressMetadata[1]) ? addressMetadata[1].state : null,
          country: hasIn(addressMetadata[1])
            ? addressMetadata[1].country
            : null,
        },
      },
      maritalStatus:
        resource && resource?.maritalStatus
          ? resource.maritalStatus.text
          : null,
      birthDate: moment(resource.birthDate).format("MM/DD/YYYY"),
      age: moment().diff(resource.birthDate, "years"),
      gender: resource.gender,
    };

    return user;
  },
  storeEnvironmentAcessSafety: function (dataQuestion, dataAnswer) {
    let resource = dataQuestion.questionnaireBody.item[0].item[0].item;
    let resourceServiceSupport =
      dataQuestion.questionnaireBody.item[1].item[0].item;
    let dataAnswerAcessSafety;
    let dataAnswerServiceSupport;
    if (
      dataAnswer.message !== undefined ||
      dataAnswer.message === "No Results found for requested resource"
    ) {
      dataAnswerAcessSafety = "";
      dataAnswerServiceSupport = "";
    } else {
      dataAnswerAcessSafety = dataAnswer.questionnaireResponseBody.item[0].item;
      dataAnswerServiceSupport =
        dataAnswer.questionnaireResponseBody.item[1].item;
    }
    let initialValue = {};
    let obj = resource.reduce((objres, item) => {
      return {
        ...objres,
        [item["linkId"]]: item,
      };
    }, initialValue);
    let objServiceSupport = resourceServiceSupport.reduce((objres, item) => {
      return {
        ...objres,
        [item["linkId"]]: item,
      };
    }, initialValue);
    let objAnswerServiceSupport;
    let objAnswerAcessSafety;
    if (dataAnswer.message !== undefined) {
      objAnswerServiceSupport = "";
    } else {
      objAnswerServiceSupport = dataAnswerServiceSupport.reduce(
        (objSer, item) => {
          return {
            ...objSer,
            [item["linkId"]]: item,
          };
        },
        initialValue
      );
    }
    if (dataAnswer.message !== undefined) {
      objAnswerAcessSafety = "";
    } else {
      objAnswerAcessSafety = dataAnswerAcessSafety.reduce((objAcc, item) => {
        return {
          ...objAcc,
          [item["linkId"]]: item,
        };
      }, initialValue);
    }

    return {
      objAccessSafety: obj,
      objServiceSupport: objServiceSupport,
      objAnswerServiceSupport: objAnswerServiceSupport,
      objAnswerAcessSafety: objAnswerAcessSafety,
    };
  },
  storeVital: function (response) {
    let vitalobject = [];
    if (response.length) {
      for (let i = 0; i < response.length; i++) {
        let object = {};
        if (response[i].code === "BP") {
          object["text"] = response[i].displayText
            ? response[i].displayText
            : response[i].text;
          object["value"] = `${renderStringForUnd(
            response[i]?.component[0]?.valueQuantity?.value
          )}/ ${renderStringForUnd(
            response[i]?.component[1]?.valueQuantity?.value
          )}`;
          object["date"] = moment(response[i].effectiveDateTime).format(
            "MMM Do, YYYY"
          );
          object["unit"] = renderStringForUnd(
            response[i]?.component[0]?.valueQuantity?.unit
          );
        } else {
          object["text"] = response[i].displayText
            ? response[i].displayText
            : response[i].text;
          object["value"] = response[i].valueQuantity.value;
          object["date"] = moment(response[i].effectiveDateTime).format(
            "MMM Do, YYYY"
          );
          object["unit"] = response[i].valueQuantity.unit;
        }
        vitalobject.push(object);
      }
      return vitalobject;
    }
  },
  storeClinicalData: function (response) {
    let vitalobject = [];
    if (response.length) {
      for (let i = 0; i < response.length; i++) {
        let object = {};
        object["text"] = response[i].code.text;
        if (response[i].valueQuantity) {
          object["value"] = response[i].valueQuantity.value;
        }
        object["date"] = moment(response[i].effectiveDateTime).format(
          "MMM Do, YYYY"
        );
        vitalobject.push(object);
      }
      return vitalobject;
    }
  },
  storeCondition: function (response) {
    let conditionobject = [];
    if (response.length) {
      for (let i = 0; i < response.length; i++) {
        let object = {};
        object["text"] = response[i].code.text;
        object["status"] = response[i].clinicalStatus.text;
        object["date"] = moment(response[i].onsetPeriod.start).format(
          "MMM Do, YYYY"
        );
        conditionobject.push(object);
      }
      return conditionobject;
    }
  },
  storeEncounters: function (response) {
    let encountersobject = [];
    if (response.length) {
      for (let i = 0; i < response.length; i++) {
        let object = {};
        object["text"] = response[i].class.display;
        object["doctor"] = response[i].participant[0]?.individual.display;
        object["date"] = moment(response[i].period.start).format(
          "MMM Do, YYYY"
        );
        encountersobject.push(object);
      }
      return encountersobject;
    }
  },
  storeMedication: function (response) {
    let medicationObject = [];
    if (response.length) {
      for (let i = 0; i < response.length; i++) {
        let object = {};
        object["text"] = response[i].medicationReference.display;
        object["status"] = response[i].status;
        object["date"] = moment(response[i].authoredOn).format("MMM Do, YYYY");
        medicationObject.push(object);
      }
      return medicationObject;
    }
  },
  storeAllergies: function (response) {
    let allergiesObject = [];
    if (response.length) {
      for (let i = 0; i < response.length; i++) {
        let object = {};
        object["category"] = response[i].category;
        object["text"] = response[i].code.text;
        object["criticality"] = response[i].criticality
          ? response[i].criticality
          : "-";
        object["reaction"] = response[i].reaction[0]?.manifestation[0]?.text;
        object["date"] = response[i].onsetDateTime
          ? response[i].onsetDateTime
          : "-";
        allergiesObject.push(object);
      }
      return allergiesObject;
    }
  },
  storeDocuments: function (response) {
    let documentsObject = [];
    if (response.length) {
      for (let i = 0; i < response.length; i++) {
        let object = {};
        if (response[i].hasOwnProperty("id")) {
          object["name"] = response[i]?.type?.text;
          object["category"] = response[i]?.category[0].text;
          object["date"] = moment(response[i]?.date).format("MMM Do, YYYY");
          object["author"] = response[i]?.author?.display;
          object["status"] = response[i]?.status;
          object["binary"] = response[i]?.content[0]?.attachment?.url
            .split("/")
            .pop();

          documentsObject.push(object);
        }
      }
      return documentsObject;
    }
  },
  allergiesCondition: function (response) {
    let conditionobject = [];
    if (response) {
      if (Object.keys(response.data).length > 0 && response.data.count > 0) {
        for (let i = 0; i < response.data.entry.length; i++) {
          if (response.data.entry[i].reaction.length > 0) {
            for (let j = 0; j < response.data.entry[i].reaction.length; j++) {
              let object = {};
              object["text"] = response.data.entry[i].code.text;
              object["criticality"] = response.data.entry[i].criticality;
              object["onsetDateTime"] = response.data.entry[i].onsetDateTime;
              object["category"] = response.data.entry[i].category;
              if (response.data.entry[i].reaction[j].manifestation.length > 0) {
                object["text"] =
                  response.data.entry[i].reaction[j].manifestation[0].text;
                object["severity"] =
                  response.data.entry[i].reaction[0].severity;
              }
              conditionobject.push(object);
            }
          }
        }
        return conditionobject;
      } else return "No Record Found";
    }
    return "No Record Found";
  },
  getEmail: function () {},
};

export default PatientsUtil;
