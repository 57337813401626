import axios from "axios";
import NProgress from "nprogress";
import { get } from "lodash";
import jwt_decode from "jwt-decode";

NProgress.configure({
  showSpinner: false,
  easing: "ease",
  speed: 500,
  trickleRate: 0.05,
  trickleSpeed: 800,
});

const customAxios = axios.create({
  baseURL: `https://us-con-gcp-sbx-0000300-031721.web.app/`,
  headers: {
    "Content-type": "application/json",
  },
});

const getAccessToken = async () => {
  return localStorage.getItem("token");
};

customAxios.interceptors.request.use(
  async (config) => {
    NProgress.done();
    NProgress.start();
    const token = await getAccessToken();
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  () => {
    return Promise.reject(function () {});
  }
);

customAxios.interceptors.response.use(
  (response) => {
    NProgress.done(true);
    return response;
  },
  (error) => {
    if (get(error, "response")) {
      if (error.response.status === 401) {
        console.log("Unauthorized event happened...");
        window.location.href = "/";
      }
      if (error.response.status === 403) {
        let token = localStorage.getItem("token");
        const currtime = Math.floor(Date.now() / 1000);
        if (token) {
          const decode = jwt_decode(token);
          const decoded_token = token
            ? decode
            : { exp: parseInt(currtime) + 1000 };
          const exp = decoded_token["exp"];

          if (exp <= currtime) {
            window.localStorage.clear();
            window.location.href = "/";
          } else {
            //Insufficient Permissions
            localStorage.setItem("insufficientPermissions", "1");
          }
        }
      }
    }
    return Promise.reject(error);
  }
);

export default customAxios;
