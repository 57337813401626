import { takeLatest, takeEvery } from "redux-saga/effects";
import {
  handleGetCarePackageCategory,
  handleGetSpecialtiesShared,
  handleOnBoardPractitioners,
  handlevalidatePractitioners,
  handleResendvalidatePractitioners,
  handleGetPractitionersShared,
  handleLoginWithSSO,
  handleCallAlertRenderAPIsOnLoad,
} from "./sharedHandler";
import {
  getCarePackageCategory,
  getSpecialties,
  setOnBoardPractitioners,
  setvalidatePractitioners,
  setResendvalidatePractitioners,
  getPractitioners,
  CallLoginWithSSO,
  CallAlertRenderAPIsOnLoad,
} from "./sharedSlice";
export const sharedSaga = [
  takeLatest(getCarePackageCategory.type, handleGetCarePackageCategory),
  takeLatest(getSpecialties.type, handleGetSpecialtiesShared),
  takeLatest(setOnBoardPractitioners.type, handleOnBoardPractitioners),
  takeLatest(setvalidatePractitioners.type, handlevalidatePractitioners),
  takeLatest(
    setResendvalidatePractitioners.type,
    handleResendvalidatePractitioners
  ),
  takeEvery(getPractitioners.type, handleGetPractitionersShared),
  takeEvery(CallLoginWithSSO.type, handleLoginWithSSO),
  takeEvery(CallAlertRenderAPIsOnLoad.type, handleCallAlertRenderAPIsOnLoad),
];
