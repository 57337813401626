import { takeLatest } from "redux-saga/effects";

import {
  handleGetUserProfile,
  handleGetInsuffPermissions,
  handleGetUserPermissions,
  handleUpdateUserProfile,
  handleGetAppointments,
  handleUpdateAppointmentPreference,
  handleEndAppointments,
  handleJoinAppointment
} from "./appointmentsHandler";
import {
  getUserProfile,
  getUserPermissions,
  getInsuffPermissions,
  updateUserProfile,
  getAppointments,
  updateAppointmentPreference,
  endAppointments,
  joinAppoinment
} from "./appointmentsSlice";

export const appointmentsSaga = [
  takeLatest(getUserProfile.type, handleGetUserProfile),
  takeLatest(getInsuffPermissions.type, handleGetInsuffPermissions),
  takeLatest(getUserPermissions.type, handleGetUserPermissions),
  takeLatest(updateUserProfile.type, handleUpdateUserProfile),
  takeLatest(joinAppoinment.type, handleJoinAppointment),
  takeLatest(getAppointments.type, handleGetAppointments),
  takeLatest(
    updateAppointmentPreference.type,
    handleUpdateAppointmentPreference
  ),
  takeLatest(endAppointments.type, handleEndAppointments),
];
