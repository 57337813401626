import _ from "lodash";
import { SPECCHAR_TOAVOID_CPCREATION } from "../constants";

export const modifyCareGoalsList = (careGoals) => {
  let modifiedCareGoals = [];
  for (let i = 0; i < careGoals.length; i++) {
    let data = {
      activity: careGoals[i].activity,
      careAreaName: careGoals[i].careAreaName,
      goalName: careGoals[i].goalName,
      status: "Draft",
      goalCancellationDate: {
        dateOfInitiation: "",
        laterDate: "",
      },
    };
    modifiedCareGoals.push(data);
  }
  return modifiedCareGoals;
};

export const modifyActionList = (action) => {
  let modifiedAction = [];
  for (let i = 0; i < action.length; i++) {
    let data = {
      ...action[i],
      status: "Draft",
      activityCancellationDate: {
        dateOfInitiation: "",
        laterDate: "",
      },
    };
    modifiedAction.push(data);
  }
  return modifiedAction;
};

export const modifyCareAreaList = (careArea) => {
  let modifiedCareArea = [];
  for (let i = 0; i < careArea.length; i++) {
    let data = {
      name: typeof careArea[i] === "string" ? careArea[i] : careArea[i].name,
      status: "Draft",
      areaCancellationDate: {
        dateOfInitiation: "",
        laterDate: "",
      },
    };
    modifiedCareArea.push(data);
  }
  return modifiedCareArea;
};

export const doRemoveSpacesFromObj = (patientCarePackage) => {
  const hasNumber = (myString) => {
    return /\d/.test(myString);
  };

  let patientCarePackageT = { ...patientCarePackage };
  const newActionArr = patientCarePackage.action.map((e) => {
    let obj = { dateOfInitiation: "", laterDate: "" };
    obj.dateOfInitiation = e.activityStartDate?.dateOfInitiation?.replace(
      /\s/g,
      ""
    );
    obj.laterDate = e.activityStartDate?.laterDate?.replace(/\s/g, "");
    if (!hasNumber(obj.laterDate)) {
      obj.laterDate = "";
    }

    if (
      (e.frequency &&
        e.frequency.endAfter.replace(/\s/g, "").length === 0 &&
        e.frequency.repeatEvery.replace(/\s/g, "").length === 0) ||
      (e.frequency &&
        (!hasNumber(e.frequency.endAfter.replace(/\s/g, "")) ||
          !hasNumber(e.frequency.repeatEvery.replace(/\s/g, ""))))
    ) {
      delete e.frequency;
    }

    if (
      e.activityType === "Appointment" &&
      e.appointmentType === "Online consultation"
    ) {
      if (e.speciality) {
        e.speciality = "";
      }
    }

    if (
      e.activityType === "Appointment" &&
      e.appointmentType !== "Online consultation"
    ) {
      if (e.participant) {
        e.participant = "";
        e.practitionerRoleId = "";
      }
    }

    return {
      ...e,
      activityStartDate: obj,
    };
  });
  patientCarePackageT.action = newActionArr;

  return patientCarePackageT;
};

export const checkForParticipants = (action) => {
  let results = action.filter(
    (activity) =>
      (activity.activityType === "Appointment" &&
        activity.appointmentType === "Online consultation" &&
        activity.participant === "") ||
      (activity.activityType === "Appointment" &&
        activity.appointmentType === "Online consultation" &&
        !activity.hasOwnProperty("participant"))
  );
  let list = [];
  if (results.length > 0) {
    list.push(results[0]);
    return list;
  } else {
    return list;
  }
};

export const checkForSpecialty = (action) => {
  let results = action.filter(
    (activity) =>
      (activity.activityType === "Appointment" &&
        activity.appointmentType !== "Online consultation" &&
        activity.speciality === "") ||
      (activity.activityType === "Appointment" &&
        activity.appointmentType !== "Online consultation" &&
        !activity.hasOwnProperty("speciality"))
  );
  let list = [];
  if (results.length > 0) {
    list.push(results[0]);
    return list;
  } else {
    return list;
  }
};

export const checkForDeviceBrandsMissing = (action) => {
  let results = action.filter(
    (activity) =>
      (activity.activityType === "Device" && !activity.deviceBrand) ||
      (activity.activityType === "Device" && activity.deviceBrand === "")
  );
  let list = [];
  if (results.length > 0) {
    list.push(results[0]);
    return list;
  } else {
    return list;
  }
};

export const checkIfSpecCharInLink = (action) => {
  let resultsHavingLinksAsElements = _.flatten(
    action.map((activity) => activity.additionalPatientResource)
  );

  const returnValidity = (str) => {
    if (typeof str === "object" && !Array.isArray(str) && str !== null) {
      str = str?.url;
    }
    // var iChars = "&'/\"";
    var iChars = SPECCHAR_TOAVOID_CPCREATION.join("");

    if (!!str)
      for (var i = 0; i < str.length; i++) {
        if (iChars.indexOf(str.charAt(i)) !== -1) {
          return true;
        }
      }
    return false;
  };

  const results = resultsHavingLinksAsElements.filter((e) => returnValidity(e));

  let list = [];
  if (results.length > 0) {
    list.push(results[0]);
    return list;
  } else {
    return list;
  }
};
