import { call, put } from "redux-saga/effects";
import { setUserProfile, setAppointments, setUserPermissions, setInsuffPermissions } from "./appointmentsSlice";
import {
  requestGetUserProfile,
  requestGetPermissions,
  requestUpdateUserProfile,
  requestGetAppointments,
  requestUpdateAppointmentPreference,
  requestEndAppointments,
  requestJoinAppointment
} from "./appointmentsRequest";

import { openSnackbar } from "./../toast/toastSlice";

export function* handleGetInsuffPermissions(action){
  try {
    const checkPermFlag = localStorage.getItem('insufficientPermissions') ? localStorage.getItem('insufficientPermissions') : '0';
    const showInsuffPermissions = (checkPermFlag === '1');
    const response = yield showInsuffPermissions;
    
    yield put(setInsuffPermissions(response));
  } catch (error) {
    console.log('something went wrong!');
  }
}

export function* handleGetUserPermissions(action){
  try {
    const response = yield call(requestGetPermissions, action.payload);
    // console.log('response', response);
    const permissions = response.data;
    
    yield put(setUserPermissions(permissions));
  } catch (error) {
    console.log('something went wrong!');
  }
}

export function* handleGetUserProfile(action) {
  try {
    const response = yield call(requestGetUserProfile, action.payload);
    const { data } = response;

    yield put(setUserProfile({ ...data }));
  } catch (error) {
    console.log('something went wrong!');
  }
}

export function* handleUpdateUserProfile(action) {
  try {
    const response = yield call(requestUpdateUserProfile, action.payload);
    yield put(
      openSnackbar({
        snackbarTitle: "Appointment preferences set successfully",
        icon: "",
        severity: "success",
      })
    );
  } catch (error) {
    console.log('something went wrong!');
  }
}

export function* handleGetAppointments(action) {
  try {
    const response = yield call(requestGetAppointments, action.payload);

    let { booked, completed } = response.data;

    booked = booked.map((b, i) => {
      return { ...b, id: "b" + b.appointmentDate + b.appointmentTime + i };
    });

    completed = completed.map((c, i) => {
      return { ...c, id: "c" + c.appointmentDate + c.appointmentTime + i };
    });

    yield put(setAppointments({ booked, completed }));
  } catch (error) {
    console.log('something went wrong!');
  }
}

export function* handleUpdateAppointmentPreference(action) {
  try {
    yield call(
      requestUpdateAppointmentPreference,
      action.payload
    );
    yield put(
      openSnackbar({
        snackbarTitle: "Appointment preferences updated",
        icon: "",
        severity: "success",
      })
    );
  } catch (error) {
    yield put(
      openSnackbar({
        snackbarTitle: "Something went wrong",
        icon: "",
        severity: "error",
      })
    );
    console.log('something went wrong!');
  }
}

export function* handleJoinAppointment(action) {
  try {
    yield call(
      requestJoinAppointment,
      action.payload
    );
    yield put(
      openSnackbar({
        snackbarTitle: "Appointment Details Saved",
        icon: "",
        severity: "success",
      })
    );
  } catch (error) {
    yield put(
      openSnackbar({
        snackbarTitle: "Something went wrong",
        icon: "",
        severity: "error",
      })
    );
    console.log('something went wrong!');
  }
}



export function* handleEndAppointments(action) {
  try {
    const response = yield call(requestEndAppointments, action.payload);
    yield put(
      openSnackbar({
        snackbarTitle: "Appointment Ended",
        icon: "",
        severity: "success",
      })
    );
  } catch (error) {
    console.log('something went wrong!');
  }
}
