import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showSnackbar: false,
  snackbarTitle: "",
  icon: "",
  severity: "success",
};

const toastSlice = createSlice({
  name: "toast",
  initialState: initialState,
  reducers: {
    openSnackbar(state, action) {
      if (action.payload.dontCloseOnClickOutside) {
        state.showSnackbar = "dontCloseOnClickOutside";
      } else {
        state.showSnackbar = true;
      }
      state.snackbarTitle = action.payload.snackbarTitle;
      state.icon = action.payload.icon;
      state.severity = action.payload.severity;
    },

    closeSnackbar(state, action) {
      state.showSnackbar = false;
      state.snackbarTitle = "";
      state.icon = "";
    },
  },
});

export const { openSnackbar, closeSnackbar } = toastSlice.actions;

export default toastSlice.reducer;
