import { useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { useDispatch, useSelector } from "react-redux";
import ErrorFallBackComponent from "../../components/Error/Fallback";
import NavigationBar from "../../pages/NavigationBar/NavigationBar";
import {
  Dashboard,
  Packages,
  Patients,
  CareTeam,
  AppointmentsPage,
} from "../../utils/LazyLoaded";
import Login from "../../pages/Login/Login";
import { useAuth } from "../../contexts/AuthContext";
import { closeSnackbar } from "./../../store/toast/toastSlice";
import LoginWithSSO from "../../pages/LoginWithSSO/LoginWithSSO";
import {
  getCarePackageCategory,
  getSpecialties,
  CallAlertRenderAPIsOnLoad,
} from "./../../store/shared/sharedSlice";
import URMHome from "../../pages/URM/urm";
import PermissionModal from "../../pages/PermissionModal/permissionModal";

const Layout = () => {
  const { currentUser, isToken } = useAuth();
  const dispatch = useDispatch();

  const { snackbarTitle, showSnackbar, icon, severity } = useSelector(
    (state) => state.toast
  );
  const { currentUserProfile } = useSelector((state) => state.appointments);
  const { insuffPermissions } = useSelector((state) => state.appointments);

  useEffect(async () => {
    if (currentUserProfile && !window.location.pathname.includes("dashboard")) {
      dispatch(
        CallAlertRenderAPIsOnLoad(
          currentUserProfile?.practitionerDetail?.practitioner_role_id
        )
      );
    }
  }, [currentUserProfile]);

  useEffect(() => {
    if (currentUser || isToken) {
      dispatch(getSpecialties());
      dispatch(getCarePackageCategory());
    }
  }, [currentUser, isToken]);

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={() => {
          if (showSnackbar !== "dontCloseOnClickOutside") {
            dispatch(closeSnackbar());
          } else {
            setTimeout(() => {
              dispatch(closeSnackbar());
            }, 3000);
          }
        }}
        classes={{ anchorOriginTopCenter: "snackbarTop" }}
      >
        <Alert
          icon={icon}
          severity={severity}
          onClose={() => {
            dispatch(closeSnackbar());
          }}
        >
          {snackbarTitle}
        </Alert>
      </Snackbar>
      <main>
        <ErrorBoundary FallbackComponent={ErrorFallBackComponent}>
          {insuffPermissions && (
            <PermissionModal showModal={insuffPermissions} />
          )}
          {((currentUser && currentUser.email) || isToken) && <NavigationBar />}

          <Routes>
            {!currentUser && !isToken && (
              <>
                <Route path="/login" element={<Login />} />
                <Route path="/oauth2/callback" element={<LoginWithSSO />} />
              </>
            )}

            {((currentUser && currentUser.email) || isToken) && (
              <>
                <Route path="/packages/*" element={<Packages />} />
                <Route path="/patients/*" element={<Patients />} />
                <Route path="/URM/*" element={<URMHome />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/appointments/*" element={<AppointmentsPage />} />
                <Route path="/careteam/*" element={<CareTeam />} />{" "}
              </>
            )}

            <Route
              path="*"
              element={
                <Navigate
                  replace
                  to={
                    (currentUser && currentUser.email) || isToken
                      ? "/dashboard"
                      : "/login"
                  }
                />
              }
            />
          </Routes>
        </ErrorBoundary>
      </main>
    </div>
  );
};

export default Layout;
