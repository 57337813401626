import API from "../../lib/api";

import {
  CARE_PACKAGE_BASE_URL,
  ENROLLMENT_BASE_URL,
  CARE_PACKAGE_PATIENT_BASE_URL
} from "./../../utils/constants";

export function requestGetCareTeamTemplates() {
  return API.get(`${CARE_PACKAGE_BASE_URL}/CareTeams`);
}

export function requestGetCareTeamByName(name) {
  return API.get(`${CARE_PACKAGE_PATIENT_BASE_URL}/CareTeam`, { params: { name: name } });
}

export function requestGetSpecialties() {
  return API.get(`${CARE_PACKAGE_BASE_URL}/Specialities`);
}

export function requestGetPractitioners(specialty) {
  return API.get(
    `${CARE_PACKAGE_BASE_URL}/Practitioners?specialty=${specialty}`
  );
}

export function requestCreateNewCareTeam(template) {
  return API.post(`${CARE_PACKAGE_PATIENT_BASE_URL}/CareTeam`, template);
}

export function requestUpdateExistingCareTeam(template) {
  return API.put(`${CARE_PACKAGE_PATIENT_BASE_URL}/CareTeam`, template);
}

export function requestGetCarePrograms() {
  return API.get(`${ENROLLMENT_BASE_URL}/CarePrograms`);
}

export function requestDeleteCareTeam(id) {
  return API.delete(
    `${CARE_PACKAGE_PATIENT_BASE_URL}/CareTeam/${id}`
  );
}

// export function requestUpdateExistingCareTeam(template) {
//   return API.put(`${CARE_PACKAGE_PATIENT_BASE_URL}/PatientCareTeam`, template);
// }
