import { createSlice, combineReducers } from "@reduxjs/toolkit";
// import { createStore, combineReducers } from 'redux'
// import {signupUser,loginUser,fetchUserBytoken } from './data-service'

const EnvironmentSlice = createSlice({
  name: "EnvironmentalAssesment",
  initialState: {
    AuthToken: "",
    SignUser: {},
    Servicesupportobj: "",
    AccessSafetyobj: "",
    isAccessSafetycompleted: false,
    isServicesupportcompleted: false,
    isAppointmentCompleted: false,
    isPatientResourcesCompleted: false,
    isCareAreaCompleted: false,
    isSelectCareAreaCompleted: false,
    isDevicesCompleted: false,
    isSurveyCompleted: false,
    isReminderCompleted: false,
    //Care package creation
    isCarePackageDetailsCompleted: false,
    isCarePackageCareAreaCompleted: false,
    isCarePackageGoalsCompleted: false,
    isCarePackageReviewCompleted: false,
    carePackageDetailsForm: {
      carePackageName: "",
      carePackageCategory: "",
      procedureCode: [],
      diagnosisCode: [],
      otherTrigger: "",
      packageDescription: "",
    },
    carePackageCareArea: [],
    carePackageGoalsandActivity: {},
    Enrollmentstatus: "",
    SearchApi: [],
    dropchip: [],
    appointmentdetail: {
      VisitName: "Post Discharge Follow-up",
      VisitPurpose: "Ensure Patient Recovery is proceding as recorded",
      Category: "Video-Visit",
      Frequency: "Every 4 weeks",
      Participants: "select",
      content:
        "Visual Analogue Scale of pain is a linear scale upon which the patients can mark the level of pain or discomfort felt by them.",
    },
    pending: true,
    EnrollmentAcessSafetyQuestion: {},
    EnrollmentServiceSupportQuestion: {},
    EnrollmentServiceSupportAnswer: {},
    EnrollmentAcessSafetyAnswer: {},
    AcessSafetyForm: {},
    ServiceSupportForm: {},
    searchPatientId: "",
    consentEmailPatientId: "",
    searchFhirPatientId: "",
    PatientVitalCard: {},
    PatientLabObservation: {},
    PatientCondition: {},
    PatientAllergies: {},
    formErrors: {
      Caregive: "",
      HouseApartment: "",
      Structuralfunctional: "",
      transportation: "",
    },
  },
  reducers: {
    getpatientFHIRId(id) {},
    getState: (state) => {
      state.dropchip = false;
      return state;
    },
    updateState: (state) => {
      state.dropchip = false;
      return state;
    },
    resetdropChip: (state) => {
      return {
        ...state,
        dropchip: [],
      };
    },
    fetchData: (state, action) => {
      return {
        ...state,
        SearchApi: action.payload,
      };
    },
    saveAcessSafety: (state, action) => {
      return {
        ...state,
        AcessSafetyForm: action.payload,
      };
    },
    saveServiceSupport: (state, action) => {
      return {
        ...state,
        ServiceSupportForm: action.payload,
      };
    },
    fetchDataEnrollmentstatus: (state, action) => {
      return { ...state, Enrollmentstatus: action.payload };
    },
    saveState: (state, action) => {
      switch (action.payload.key) {
        case "AuthToken":
          return {
            ...state,
            AuthToken: action.payload.payload,
          };
        case "SignUser":
          return {
            ...state,
            SignUser: action.payload.payload,
          };
        case "isAccessSafetycompleted":
          return {
            ...state,
            isAccessSafetycompleted: action.payload.payload,
          };
        case "isServicesupportcompleted":
          return {
            ...state,
            isServicesupportcompleted: action.payload.payload,
          };
        case "isSelectCareAreaCompleted":
          return {
            ...state,
            isSelectCareAreaCompleted: action.payload.payload,
          };
        case "isCareAreaCompleted":
          return {
            ...state,
            isCareAreaCompleted: action.payload.payload,
          };
        case "isDevicesCompleted":
          return {
            ...state,
            isDevicesCompleted: action.payload.payload,
          };
        case "isSurveyCompleted":
          return {
            ...state,
            isSurveyCompleted: action.payload.payload,
          };
        case "isAppointmentCompleted":
          return {
            ...state,
            isAppointmentCompleted: action.payload.payload,
          };
        case "isPatientResourcesCompleted":
          return {
            ...state,
            isPatientResourcesCompleted: action.payload.payload,
          };
        case "isReminderCompleted":
          return {
            ...state,
            isReminderCompleted: action.payload.payload,
          };
        case "dropchip":
          return {
            ...state,
            dropchip: action.payload.payload,
          };
        case "appoinmentdetail":
          return {
            ...state,
            appointmentdetail: action.payload.payload,
          };
        case "isCarePackageDetailsCompleted":
          return {
            ...state,
            isCarePackageDetailsCompleted: action.payload.payload,
          };
        case "isCarePackageCareAreaCompleted":
          return {
            ...state,
            isCarePackageCareAreaCompleted: action.payload.payload,
          };
        case "carePackageCareArea":
          return {
            ...state,
            carePackageCareArea: action.payload.payload,
          };
        case "isCarePackageGoalsCompleted":
          return {
            ...state,
            isCarePackageGoalsCompleted: action.payload.payload,
          };
        case "isCarePackageReviewCompleted":
          return {
            ...state,
            isCarePackageReviewCompleted: action.payload.payload,
          };
        case "carePackageDetailsForm":
          return {
            ...state,
            carePackageDetailsForm: action.payload.payload,
          };
        case "carePackageGoalsandActivity":
          return {
            ...state,
            carePackageGoalsandActivity: action.payload.payload,
          };
      }
    },
    getSucess: (state, action) => {
      return { ...state, pending: action.payload };
    },
    getError: (state, action) => {
      return { ...state, pending: action.payload };
    },
    saveEnrollmentAcessSafetyQuestion: (state, action) => {
      return {
        ...state,
        EnrollmentAcessSafetyQuestion: action.payload.objAccessSafety,
        EnrollmentServiceSupportQuestion: action.payload.objServiceSupport,
        EnrollmentAcessSafetyAnswer: action.payload.objAnswerAcessSafety,
        EnrollmentServiceSupportAnswer: action.payload.objAnswerServiceSupport,
      };
    },
    resetEnrollmentAcessSafetyQuestion: (state) => {
      // state.EnrollmentAcessSafetyAnswer = {};
      // state.EnrollmentServiceSupportAnswer = {};
      state.AcessSafetyForm = {};
      state.ServiceSupportForm = {};
    },
    savePatientId: (state, action) => {
      return { ...state, searchPatientId: action.payload };
    },
    consentEmailPatientId: (state, action) => {
      return { ...state, consentEmailPatientId: action.payload };
    },
    searchFhirPatientId: (state, action) => {
      return { ...state, searchFhirPatientId: action.payload };
    },
    savePatientVital: (state, action) => {
      return { ...state, PatientVitalCard: action.payload };
    },
    savePatientLabObservation: (state, action) => {
      return { ...state, PatientLabObservation: action.payload };
    },
    savePatientCondition: (state, action) => {
      return { ...state, PatientCondition: action.payload };
    },
    savePatientAllergies: (state, action) => {
      return { ...state, PatientAllergies: action.payload };
    },
    getEnrollmentAssesmentQuestion(action) {},
    setformErrors(state, action) {
      state.formErrors = action.payload;
    },
  },
  extraReducers: {},
});

const CpIDSLice = createSlice({
  name: "PatientCPID",
  initialState:{
    cpId: "",
    patientID: ""
  },
  reducers: {
    getCpId: (state) =>{
      return state;
    },
    updateCpId: (state, action)=>{
      return {...state, cpId: action.payload.cpId, patientID: action.payload.patientID}
    }
  }

})

export const reducer = combineReducers({
  // Gardenia: GardeniaSlice.reducer,
  EnvironmentalAssesment: EnvironmentSlice.reducer,
  PatientCPID: CpIDSLice.reducer
});

export const {
  getCpId,
  updateCpId
} = CpIDSLice.actions;

export const {
  resetdropChip,
  setformErrors,
  resetEnrollmentAcessSafetyQuestion,
  getState,
  getpatientFHIRId,
  getEnrollmentAssesmentQuestion,
  saveServiceSupport,
  saveAcessSafety,
  updateState,
  savePatientAllergies,
  savePatientCondition,
  saveState,
  fetchData,
  savePatientVital,
  savePatientLabObservation,
  fetchDataEnrollmentstatus,
  getError,
  getSucess,
  searchFhirPatientId,
  consentEmailPatientId,
  savePatientId,
  saveEnrollmentAcessSafetyQuestion,
} = EnvironmentSlice.actions;

// export const userSelector = (state) => state.user.username;

export default EnvironmentSlice.reducer;
