import { takeEvery, takeLatest } from "redux-saga/effects";

import {
  handleGetCarePackageActivityByName,
  handleGetCarePackageProgram,
  handleGetCarePackageTemplate,
  handleGetCarePackageActivityList,
  handleGetAllCarePackagesTemplates,
  handlegetCareGoalModalActivityDetails,
  handlepostcarePackageApprover,
  handlePostCareActivity,
  handlePutCareActivity,
  handleRequestDeleteCareActivity,
  handleGetAllMyCarePackagesTemplates,
  handleGetCarePackageActivityDeviceType,
  handleGetApproverList,
  handlegetCareGoallist,
  handlegetmeasurementsMetricArray,
  handleGetActivitiesList,
} from "./carePackageHandler";
import {
  getCarePackageActivityByName,
  getcarePackageAllActivityListfromApi,
  getCarePackageProgram,
  postCarePackageTemplate,
  getGetAllCarePackagesTemplates,
  getCareGoalModalActivityDetails,
  requestCarePackageApprover,
  requestPostCareActivity,
  requestPutCareActivity,
  requestDeleteCareActivity,
  getGetAllMyCarePackagesTemplates,
  getDeviceType,
  getApproverList,
  getGoalList,
  getmeasurementsMetricArray,
  getActivitiesList,
} from "./carePackageSlice";

export const carePackageSaga = [
  takeEvery(
    getCarePackageActivityByName.type,
    handleGetCarePackageActivityByName
  ),
  takeLatest(getCarePackageProgram.type, handleGetCarePackageProgram),
  takeLatest(postCarePackageTemplate.type, handleGetCarePackageTemplate),
  takeLatest(
    getcarePackageAllActivityListfromApi.type,
    handleGetCarePackageActivityList
  ),
  takeLatest(
    getGetAllCarePackagesTemplates.type,
    handleGetAllCarePackagesTemplates
  ),
  takeLatest(
    getGetAllMyCarePackagesTemplates.type,
    handleGetAllMyCarePackagesTemplates
  ),
  takeLatest(
    getCareGoalModalActivityDetails.type,
    handlegetCareGoalModalActivityDetails
  ),

  takeLatest(requestCarePackageApprover.type, handlepostcarePackageApprover),
  takeLatest(requestPostCareActivity.type, handlePostCareActivity),
  takeLatest(requestPutCareActivity.type, handlePutCareActivity),
  takeLatest(requestDeleteCareActivity.type, handleRequestDeleteCareActivity),
  takeLatest(getDeviceType.type, handleGetCarePackageActivityDeviceType),
  takeLatest(getApproverList.type, handleGetApproverList),
  takeLatest(getGoalList.type, handlegetCareGoallist),
  takeLatest(getmeasurementsMetricArray.type, handlegetmeasurementsMetricArray),
  takeLatest(getActivitiesList.type, handleGetActivitiesList),
];
