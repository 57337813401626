import { createSlice } from "@reduxjs/toolkit";

const initialTemplates = {
  name: "",
  category: "",
  procedureCode: "",
  diagnosisCode: "",
  trigger: "",
  description: "",
  state: "Pending Approval",
  cpIdentifier: "",
  reviewer: [
    {
      current_state: "Pending Approval",
      assigned_to: "",
      assigned_on: new Date().toISOString().slice(0, 10),
      commentedDate: new Date().toISOString().slice(0, 10),
      comment: "initial draft",
    },
    {
      current_state: "Draft",
      assigned_to: "",
      assigned_on: new Date().toISOString().slice(0, 10),
      commentedDate: new Date().toISOString().slice(0, 10),
      comment: "initial draft",
    },
  ],
  author: [
    {
      author_name: "",
      created_on: new Date().toISOString().slice(0, 10),
    },
  ],
  careArea: [],
  careGoal: [],
  action: [],
};

const carePackageSlice = createSlice({
  name: "carePackage",
  initialState: {
    allCarePackageTemplates: [],
    allMyCarePackageTemplates: [],
    carePackageTemplates: initialTemplates,
    carePackageActivityByName: [],
    carePackageDetailsForm: {
      carePackageName: "",
      carePackageCategory: "",
      procedureCode: "",
      diagnosisCode: "",
      otherTrigger: "",
      packageDescription: "",
    },
    carePackageProgramCategory: [],
    carePackageGoalandActivitiesobj: {},
    carePackagesavedActivityListInGoalandActivities: [],
    carePackageAllActivityListfromApi: [],
    carePackageProgramCategories: [],
    careGoalModalActivity: { 0: {} },
    ActivityTypesurvey: [],
    CarePackageApproval: {},
    CarePackageApprovalApistatus: "",
    SnackAlertTitle: "",
    SnackAlertshow: null,
    initialcarePackageTemplates: initialTemplates,
    AreaDisabled: false,
    DeviceType: [],
    CarePackageSuccess: false,
    ApproverList: [],
    isRecurringFrequency: [],
    careGoalList: [],
    measurementsMetricArray: [],
    cpFetchLoaderAll: false,
    cpFetchLoaderMy: false,
    activitiesList: [],
    activitiesListLoaded: false,
    activitiesChanged: false,
    carePackageTemplatesUpdated: false,
    carePackageTemplatesUpdated2: false,
    carePackageTemplatesUpdated3: false,
    carePackageTemplatesUpdated4: false,
  },
  reducers: {
    getCarePackageTemplates() {},
    getCarePackageProgram() {},
    getGoalList() {},
    getActivitiesList(payload) {},
    getmeasurementsMetricArray(payload) {},
    postCarePackageTemplate(payload) {},
    getCareGoalModalActivityDetails(payload) {},
    getcarePackageAllActivityListfromApi() {},
    getGetAllMyCarePackagesTemplates(payload) {},
    getGetAllCarePackagesTemplates() {},
    getDeviceType() {},
    setCPFetchLoaderAll(state, action) {
      state.cpFetchLoaderAll = action.payload;
    },
    setCPFetchLoaderMy(state, action) {
      state.cpFetchLoaderMy = action.payload;
    },
    setActivitiesListLoaded(state, action) {
      state.activitiesListLoaded = action.payload;
    },
    setActivityChanged(state, action) {
      state.activitiesChanged = action.payload;
    },
    setmeasurementsMetricArray(state, action) {
      state.measurementsMetricArray = action.payload;
    },
    setCarePackagesTemplatesSuccess(state, action) {
      state.CarePackageSuccess = action.payload;
    },
    setGetAllCarePackagesTemplates(state, action) {
      state.allCarePackageTemplates = action.payload;
    },
    setGetAllMyCarePackagesTemplates(state, action) {
      state.allMyCarePackageTemplates = action.payload;
    },
    setDisabledPropertyforAreaTypeAhead(state, action) {
      state.AreaDisabled = action.payload;
    },

    setDeviceType(state, action) {
      state.DeviceType = action.payload;
    },
    getCarePackageActivityByName(name) {},
    setCarePackageActivityByName(state, action) {
      // const { entry } = action.payload;
      state.carePackageActivityByName.push(action.payload);
    },
    setCarePackageTemplatesWhole(state, action) {
      state.carePackageTemplates = action.payload;
    },
    setCarePackageTemplates(state, action) {
      // const entry = action.payload;
      state.carePackageTemplates.name = action.payload.carePackageName;
      state.carePackageTemplates.category = action.payload.carePackageCategory;
      state.carePackageTemplates.procedureCode = action.payload.procedureCode;
      state.carePackageTemplates.diagnosisCode = action.payload.diagnosisCode;
      state.carePackageTemplates.trigger = action.payload.otherTrigger;
      state.carePackageTemplates.description =
        action.payload.packageDescription;
      state.carePackageDetailsForm = action.payload;

      if (action.payload.isSaveAndExit) {
        state.carePackageTemplatesUpdated = true;
      }
    },
    resetcarePackageTemplatesUpdated(state, action) {
      state.carePackageTemplatesUpdated = false;
      state.carePackageTemplatesUpdated2 = false;
      state.carePackageTemplatesUpdated3 = false;
      state.carePackageTemplatesUpdated4 = false;
    },
    setcarePackageTemplatesUpdated(state, action) {
      state.carePackageTemplatesUpdated = true;
    },
    setcarePackageTemplatesUpdated2(state, action) {
      state.carePackageTemplatesUpdated2 = true;
    },
    setcarePackageTemplatesUpdated3(state, action) {
      state.carePackageTemplatesUpdated3 = true;
    },
    setcarePackageTemplatesUpdated4(state, action) {
      state.carePackageTemplatesUpdated4 = true;
    },
    resetsetCarePackageTemplatesForm(state, action) {
      state.carePackageDetailsForm = action.payload;
    },
    setCareTeamArea(state, action) {
      if (
        typeof action.payload === "object" &&
        !Array.isArray(action.payload) &&
        action.payload !== null
      ) {
        if (state.carePackageTemplates?.careArea) {
          state.carePackageTemplates.careArea = action.payload.data;
        }

        if (action.payload.isSaveAndExit) {
          state.carePackageTemplatesUpdated2 = true;
        }
      } else {
        if (state.carePackageTemplates?.careArea) {
          state.carePackageTemplates.careArea = action.payload;
        }
      }
    },
    setCareTeamGoal(state, action) {
      if (
        typeof action.payload === "object" &&
        !Array.isArray(action.payload) &&
        action.payload !== null
      ) {
        state.carePackageTemplates.careGoal = action.payload.data;

        if (action.payload.isSaveAndExit) {
          state.carePackageTemplatesUpdated3 = true;
        }
      } else {
        state.carePackageTemplates.careGoal = action.payload;
      }
    },
    setCarePackageAction(state, action) {
      if (action.payload.isSaveAndExit) {
        let payloadToConsider = { ...action.payload };
        delete payloadToConsider.isSaveAndExit;
        const found = state.carePackageTemplates.action.some(
          (el) => el.name === payloadToConsider.name
        );

        if (!found) state.carePackageTemplates.action.push(payloadToConsider);
        if (
          // !found &&
          action.payload.isSaveAndExit ===
          state.carePackageTemplates.action.length
        ) {
          state.carePackageTemplatesUpdated4 = true;
        }
      } else {
        const found = state.carePackageTemplates.action.some(
          (el) => el.name === action.payload.name
        );
        if (!found) state.carePackageTemplates.action.push(action.payload);
      }
    },
    setcareGoalModalActivity(state, action) {
      state.careGoalModalActivity[0] = action.payload;
    },
    setcompleteCarePackageAction(state, action) {
      if (
        typeof action.payload === "object" &&
        !Array.isArray(action.payload) &&
        action.payload !== null
      ) {
        state.carePackageTemplates.action = action.payload.data;

        if (action.payload.isSaveAndExit) {
          state.carePackageTemplatesUpdated4 = true;
        }
      } else {
        state.carePackageTemplates.action = action.payload;
      }
    },
    setcompleteCarePackagecareGoal(state, action) {
      state.carePackageTemplates.careGoal = action.payload;
    },
    resetCarePackageAction(state, action) {
      state.carePackageTemplates.action = [];
    },
    resetCarePackageTemplates(state, action) {
      state.carePackageTemplates = state.initialcarePackageTemplates;
    },
    setCarePackageProgram(state, action) {
      state.carePackageProgramCategory = action.payload;
    },
    setCarePackageGoalandActivityobj(state, action) {
      state.carePackageGoalandActivitiesobj = action.payload;
    },
    setcarePackagesavedActivityListInGoalandActivities(state, action) {
      let previousarray = state.carePackagesavedActivityListInGoalandActivities;
      let array = _.union(previousarray, action.payload);
      // let array = previousarray.concat(action.payload);
      state.carePackagesavedActivityListInGoalandActivities = array;
    },
    setcarePackagenewActivityListInGoalandActivities(state, action) {
      // let array = previousarray.concat(action.payload);
      state.carePackagesavedActivityListInGoalandActivities = action.payload;
    },
    setcarePackageAllActivityListfromApi(state, action) {
      state.carePackageAllActivityListfromApi = action.payload;
    },
    setcarePackageTemplateApprover(state, action) {
      let reviewerArray = state.carePackageTemplates.reviewer;
      for (var i in reviewerArray) {
        review[i].assigned_to = action.payload;
      }
      state.carePackageTemplates.reviewer = reviewerArray;
    },
    setcarePackageProgramCategory(state, action) {
      state.carePackageProgramCategories = action.payload;
      // return { ...state };
    },
    setSurveyArray(state, action) {
      state.ActivityTypesurvey = action.payload;
    },
    setCarePackageApproval(state, action) {
      state.CarePackageApproval = action.payload;
    },
    requestCarePackageApprover(modificationobject) {},
    requestPostCareActivity(modificationobject) {},
    requestPutCareActivity(modificationobject) {},
    requestDeleteCareActivity(modificationobject) {},
    setcarePackageApproverStatus(state, action) {
      state.CarePackageApprovalApistatus = action.payload;
    },
    setcarePackageSnackAlertTitle(state, action) {
      state.SnackAlertTitle = action.payload;
    },
    setcarePackageSnackAlertshow(state, action) {
      state.SnackAlertshow = action.payload;
    },
    setApproverList(state, action) {
      state.ApproverList = action.payload;
    },
    getApproverList() {},
    setisRecurringFrequency(state, action) {
      state.isRecurringFrequency = action.payload;
    },
    setGoalList(state, action) {
      state.careGoalList = action.payload;
    },
    setActivitiesList(state, action) {
      state.activitiesList = action.payload;
    },
    // getGoalList() {},
  },
});

export const {
  setGoalList,
  getGoalList,
  setApproverList,
  getApproverList,
  setisRecurringFrequency,
  setGetAllMyCarePackagesTemplates,
  getGetAllMyCarePackagesTemplates,
  setDisabledPropertyforAreaTypeAhead,
  resetsetCarePackageTemplatesForm,
  setcarePackageSnackAlertTitle,
  setcarePackageSnackAlertshow,
  setcarePackageApproverStatus,
  requestCarePackageApprover,
  requestPostCareActivity,
  requestPutCareActivity,
  requestDeleteCareActivity,
  setCarePackageApproval,
  setSurveyArray,
  setcompleteCarePackagecareGoal,
  postCarePackageTemplate,
  setcarePackageProgramCategory,
  setcareGoalModalActivity,
  getCareGoalModalActivityDetails,
  resetCarePackageTemplates,
  resetcarePackageTemplatesUpdated,
  setcarePackageTemplatesUpdated,
  setcarePackageTemplatesUpdated2,
  setcarePackageTemplatesUpdated3,
  setcarePackageTemplatesUpdated4,
  resetCarePackageAction,
  setcompleteCarePackageAction,
  setcarePackageTemplateApprover,
  getcarePackageAllActivityListfromApi,
  setcarePackageAllActivityListfromApi,
  getmeasurementsMetricArray,
  setmeasurementsMetricArray,
  getCarePackageTemplates,
  setcarePackagesavedActivityListInGoalandActivities,
  setcarePackagenewActivityListInGoalandActivities,
  setCarePackageGoalandActivityobj,
  setCarePackageProgram,
  getCarePackageProgram,
  setCarePackageActivityByName,
  setCarePackageAction,
  setCarePackageTemplates,
  setCarePackageTemplatesWhole,
  setCareTeamArea,
  setCareTeamGoal,
  getCarePackageActivityByName,
  getGetAllCarePackagesTemplates,
  setGetAllCarePackagesTemplates,
  setDeviceType,
  getDeviceType,
  setCPFetchLoaderAll,
  setCPFetchLoaderMy,
  setCarePackagesTemplatesSuccess,
  getActivitiesList,
  setActivitiesList,
  setActivitiesListLoaded,
  setActivityChanged,
} = carePackageSlice.actions;

export default carePackageSlice.reducer;
