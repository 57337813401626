import { call, put } from "redux-saga/effects";
import {
  setCareTeamTemplates,
  setCarePackageCategory,
  setSelectedCareTeam,
  setSpecialties,
  setPractitioners,
  resetNewTemplate,
  setShowSnackbar,
  setSnackbarTitle,
} from "./careTeamSlice";
import {
  requestGetCareTeamTemplates,
  requestGetCareTeamByName,
  requestGetSpecialties,
  requestGetPractitioners,
  requestCreateNewCareTeam,
  requestGetCarePrograms,
  requestUpdateExistingCareTeam,
} from "./careTeamRequest";

export function* handleGetCareTeamTemplates() {
  try {
    const response = yield call(requestGetCareTeamTemplates);
    const { data } = response;
    yield put(setCareTeamTemplates({ ...data }));
  } catch (error) {
    console.log("something went wrong!");
  }
}

export function* handleGetCareTeamByName(action) {
  try {
    const response = yield call(requestGetCareTeamByName, action.payload);
    const { entry } = response.data;
    const careTeam = Array.isArray(entry) && entry.length > 0 ? entry[0] : null;
    yield put(setSelectedCareTeam(careTeam));
  } catch (error) {
    console.log("something went wrong!");
  }
}

export function* handleGetCarePackageCategory(action) {
  try {
    const response = yield call(requestGetCarePrograms);
    const { recCarePrograms } = response.data;
    yield put(setCarePackageCategory([...recCarePrograms]));
  } catch (error) {
    console.log("something went wrong!");
  }
}

export function* handleGetSpecialties() {
  try {
    const response = yield call(requestGetSpecialties);
    const { entry } = response.data;
    yield put(setSpecialties(entry));
  } catch (error) {
    console.log("something went wrong!");
  }
}

export function* handleGetPractitioners(action) {
  try {
    const response = yield call(requestGetPractitioners, action.payload);
    const { entry } = response.data;
    yield put(setPractitioners({ entry, specialty: action.payload }));
  } catch (error) {
    console.log("something went wrong!");
  }
}

export function* handleCreateNewCareTeam(action) {
  try {
    const createTeamRes = yield call(requestCreateNewCareTeam, action.payload);
    const getCareTeamRes = yield call(requestGetCareTeamTemplates);
    const { name } = createTeamRes.data;
    yield put(resetNewTemplate());
    yield put(setCareTeamTemplates({ ...getCareTeamRes.data }));
    yield put(
      setSnackbarTitle(
        `Your care team - ‘${name}’ has been successfully created.`
      )
    );
    yield put(setShowSnackbar(true));
  } catch (error) {
    yield put(resetNewTemplate());
    console.log("something went wrong!");
  }
}

export function* handleUpdateExistingCareTeam(action) {
  try {
    const createTeamRes = yield call(
      requestUpdateExistingCareTeam,
      action.payload
    );
    const getCareTeamRes = yield call(requestGetCareTeamTemplates);
    const { name } = createTeamRes.data;
    yield put(resetNewTemplate());
    yield put(setCareTeamTemplates({ ...getCareTeamRes.data }));
    yield put(
      setSnackbarTitle(
        `Your care team - ‘${action.payload.name}’ has been successfully updated.`
      )
    );
    yield put(setShowSnackbar(true));
  } catch (error) {
    yield put(resetNewTemplate());
    console.log("something went wrong!");
  }
}
