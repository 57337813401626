import axios from "axios";
import {
  REACT_APP_VACA_LOGIN_API,
  REACT_APP_VACA_PDM_API,
  REACT_APP_AUTH_USERNAME,
  REACT_APP_AUTH_PASSWORD,
  VALID_PERMISSIONS,
  PERMISSIONS_SET,
} from "./../utils/constants";
import { trackPromise } from "react-promise-tracker";

export class URMService {
  #REACT_APP_VACA_PDM_API = `${REACT_APP_VACA_PDM_API}`;
  // #REACT_APP_VACA_PDM_API = `${REACT_APP_VACA_PDM_API}`;

  static #instance = null;
  #setAuthHeader() {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error?.response?.status === 401) {
          return (window.location.href = "/login");
        }
        return Promise.reject(error);
      }
    );
  }
  #tabIndex = 0;
  #viewMode = "list";

  async #get(endpoint, options = {}) {
    this.#setAuthHeader();
    return await trackPromise(
      axios.get(`${this.#REACT_APP_VACA_PDM_API}/${endpoint}`, options)
    );
  }

  async #getWithoutV2(endpoint, options = {}) {
    this.#setAuthHeader();
    const baseURL = this.#REACT_APP_VACA_PDM_API.replace("/v2", "");
    return await trackPromise(axios.get(`${baseURL}/${endpoint}`, options));
  }

  async #post(endpoint, payload, options = {}) {
    // this.#setAuthHeader();
    return await trackPromise(
      axios.post(
        `${this.#REACT_APP_VACA_PDM_API}/${endpoint}`,
        payload,
        options
      )
    );
  }

  async #put(endpoint, payload, options = {}) {
    // this.#setAuthHeader();
    // return await trackPromise(axios.put(`${process.env.REACT_APP_VACA_PDM_API}/api/urm/${endpoint}`, payload, options));
    return await trackPromise(
      axios.put(
        `${process.env.REACT_APP_VACA_PDM_API}/${endpoint}`,
        payload,
        options
      )
    );
  }

  async #delete(endpoint, options = {}) {
    // this.#setAuthHeader();
    return await trackPromise(
      axios.delete(`${process.env.REACT_APP_VACA_PDM_API}/${endpoint}`, options)
    );
  }

  async getToken(uname, pass) {
    const payload = {
      username: uname || REACT_APP_AUTH_USERNAME,
      password: pass || REACT_APP_AUTH_PASSWORD,
    };
    // const tokenResponse = await trackPromise(axios.post(REACT_APP_VACA_LOGIN_API, payload, {}));
    // localStorage.setItem('REACT_APP_AUTH_TOKEN_KEY', tokenResponse.data.access_token);
    // localStorage.setItem('REACT_APP_AUTH_USERNAME', tokenResponse.data.username);
    // return tokenResponse;
    return { data: { access_token: "" } };
  }

  getCurrentUserName() {
    return localStorage.getItem("REACT_APP_AUTH_USERNAME");
  }

  getAllUser() {
    return this.#get(`users`);
  }
  getUserByName(username) {
    return this.#get(`users/${username}`);
  }

  getUserPermissionById(userId) {
    return this.#get(`permission/users/${userId}`);
  }
  createUser(user) {
    return this.#post(`users`, user);
  }

  updateUser(user, userId) {
    return this.#put(`users/${userId}`, user);
  }

  deleteUserById(id) {
    return this.#delete(`users/${id}`);
  }

  getRoles() {
    return this.#get(`roles`);
  }

  getRoleByName(rolename) {
    return this.#get(`roles/${rolename}`);
  }

  createRole(role) {
    return this.#post(`roles`, role);
  }

  UpdateRole(role, roleId) {
    const username = this.getCurrentUserName();
    return this.#put(`roles/${roleId}`, role);
  }

  deleteRole(roleId) {
    return this.#delete(`roles/${roleId}`);
  }

  getRolesPolicies() {
    const username = this.getCurrentUserName();
    return this.#getWithoutV2(`v3/role/policy`);
  }

  getRolePolices(roleId) {
    return this.#get(`roles/${roleId}/policies`);
  }

  attachPolicyToRole(roleId, rolePolicies) {
    const username = this.getCurrentUserName();
    return this.#put(`roles/${roleId}/policy`, rolePolicies);
  }

  getPolicyById(policyId) {
    return this.#get(`/policies/${policyId}`);
  }

  getAllPolicy() {
    return this.#get(`/policies/`);
  }

  createPolicy(policy) {
    return this.#post(`policies`, policy);
  }

  updatePolicy(policyId, policy) {
    return this.#put(`policy/${policyId}`, policy);
  }

  deletePolicyById(policyId) {
    const username = this.getCurrentUserName();
    return this.#delete(`policies/${policyId}`);
  }

  static getInstance() {
    if (!this.#instance) {
      this.#instance = new URMService();
    }
    return this.#instance;
  }

  getPolicies() {
    return this.#get(`policies`);
  }

  getAccessDataOfUser() {
    return Promise.resolve({ data: [] });
  }

  getAccessDataOfGroup() {
    return Promise.resolve({ data: [] });
  }
  getResourceTypes() {
    return this.#get(`permissions/resourceTypes`);
  }

  getGroups() {
    return this.#get(`groups`);
  }

  getGroup(id) {
    return this.#get(`groups/${id}`);
  }

  getGroupsUsersCount() {
    return this.#get(`groups/users/count`);
  }

  getGroupRoles(id) {
    return this.#get(`groups/${id}/roles`);
  }

  getGroupUsers(id) {
    return this.#get(`groups/${id}/users`);
  }

  createGroup(group) {
    return this.#post(`groups`, group);
  }

  updateGroup(id, group) {
    return this.#put(`groups/${id}`, group);
  }

  updateGroupRoles(id, roles) {
    return this.#post(`groups/${id}/roles`, roles);
  }

  updateGroupUsers(id, users) {
    return this.#post(`groups/${id}/users`, users);
  }

  saveUserGroup(id, group) {
    return this.#put(`users/${id}/groups`, group);
  }
  getUserGroups(id) {
    return this.#get(`users/${id}/groups`);
  }

  getURMTabIndex() {
    return this.#tabIndex;
  }
  setURMTabIndex(tabIndex) {
    this.#tabIndex = tabIndex;
  }

  getURMViewMode() {
    return this.#viewMode;
  }
  setURMViewMode(viewMode) {
    this.#viewMode = viewMode;
  }

  can(resource_model, service, permissions) {
    if (
      permissions &&
      (permissions.hasOwnProperty(PERMISSIONS_SET.EDIT) ||
        permissions.hasOwnProperty(PERMISSIONS_SET.VIEW))
    ) {
      const model = permissions[resource_model];
      const editModel = permissions[PERMISSIONS_SET.EDIT];
      if (model.includes(service)) {
        return true;
      } else if (editModel.includes(service)) {
        //considering if only edit permission is defined and resource is not Approve and Delete
        if (
          resource_model !== PERMISSIONS_SET.APPROVE &&
          resource_model !== PERMISSIONS_SET.DELETE
        ) {
          return true;
        } else {
          return false;
        }
      }
    }
    return false;
  }

  async getPermissionsList(allows) {
    const permObj = {};
    const permissions = VALID_PERMISSIONS;
    const userPermissions = await allows;

    if (userPermissions && userPermissions.length > 0) {
      for (let perm of permissions) {
        const arr = [];
        userPermissions.forEach((entry) => {
          // let [key, value] = entry;

          const key = entry["resource_type"];
          const value = entry["actions"];
          if (value.includes(perm)) {
            arr.push(key);
          }
        });
        permObj[perm] = arr;
      }
    }
    return permObj;
  }
}

export const urmService = URMService.getInstance();
