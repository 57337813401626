import { call, put } from "redux-saga/effects";

import {
  setTotalPatients,
  setTotalPatientsError,
  setTopCarePlan,
  setTopCarePlanError,
  setCareTeamStatistics,
  setCareTeamStatisticsError,
  setActivePatientsByCategory,
  setActivePatientsByCategoryError,
  setAppOnboardStatus,
  setAppOnboardStatusError,
} from "./dashboardSlice";
import {
  requestGetActivePatientsByCategory,
  requestGetAppOnboardStatus,
  requestGetCareTeamStatistics,
  requestGetTopCarePlan,
  requestGetTotalPatients,
  requestSearchPatients,
} from "./dashboardRequest";

import { fetchData } from "./../reducer";

export function* handleGetTotalPatients(action) {
  try {
    const response = yield call(requestGetTotalPatients, action.payload);
    yield put(setTotalPatients({ ...response.data, category: action.payload }));
  } catch (error) {
    console.log('something went wrong!');
    yield put(setTotalPatientsError({ error: true, loading: false }));
  }
}

export function* handleGetTopCarePlan(action) {
  try {
    const response = yield call(requestGetTopCarePlan, action.payload);
    yield put(setTopCarePlan({ ...response.data, category: action.payload }));
  } catch (error) {
    console.log('something went wrong!');
    yield put(setTopCarePlanError({ error: true, loading: false }));
  }
}

export function* handleGetCareTeamStatistics(action) {
  try {
    const response = yield call(requestGetCareTeamStatistics);
    yield put(setCareTeamStatistics({ ...response.data }));
  } catch (error) {
    console.log('something went wrong!');
    yield put(setCareTeamStatisticsError({ error: true, loading: false }));
  }
}

export function* handleGetActivePatientsByCategory(action) {
  try {
    const response = yield call(
      requestGetActivePatientsByCategory
    );
    const { entry } = response.data;
    yield put(setActivePatientsByCategory(entry));
  } catch (error) {
    console.log('something went wrong!');
    yield put(
      setActivePatientsByCategoryError({ error: true, loading: false })
    );
  }
}

export function* handleGetAppOnboardStatus(action) {
  try {
    const response = yield call(requestGetAppOnboardStatus);
    yield put(setAppOnboardStatus(response.data));
  } catch (error) {
    console.log('something went wrong!');
    yield put(setAppOnboardStatusError({ error: true, loading: false }));
  }
}

export function* handleGetSearchPatientsEMR(action) {
  try {
    const response = yield call(requestSearchPatients, action.payload);
    yield put(fetchData(response.data));
  } catch (error) {
    console.log('something went wrong!');
  }
}
