import { Avatar } from "stream-chat-react";
import "stream-chat-react/dist/css/index.css";

const CustomResultItem = (props) => {
  const { focusedUser, index, result, selectResult } = props;

  const focused = focusedUser === index;

  const name =
    result.data.name.length > 36
      ? result.data.name.slice(0, 36) + "..."
      : result.data.name;

  return (
    <div
      className={`str-chat__channel-search-result ${focused ? "focused" : ""}`}
      onClick={() => selectResult(result)}
    >
      <Avatar image={result?.image} name={result.data.name[0]} />
      {name}
    </div>
  );
};

const CustomSearchResult = (props) => <CustomResultItem {...props} />;

export default CustomSearchResult;
