import { combineReducers } from "@reduxjs/toolkit";

import careTeamSlice from "./careteam/careTeamSlice";
import carePackageSlice from "./carepackage/carePackageSlice";

import patientsSlice from "./patients/patientsSlice";
import EnvironmentSlice from "./reducer";
import patientCarePackageSlice from "./patientCarePackage/patientCarePackageSlice";
import patientsCareTeamSlice from "./patients/enrollment/careTeam/careTeamSlice";
import patientAssociationSlice from "./patients/association/associationSlice";
import appointmentsSlice from "./appointments/appointmentsSlice";
import sharedSlice from "./shared/sharedSlice";
import toastSlice from "./toast/toastSlice";
import dashboardSlice from "./dashboard/dashboardSlice";
import commentsSlice from "./patients/comments/commentsSlice"

export default combineReducers({
  careTeam: careTeamSlice,
  carePackage: carePackageSlice,
  patientCarePackage: patientCarePackageSlice,
  EnvironmentalAssesment: EnvironmentSlice,
  patients: patientsSlice,
  patientsCareTeam: patientsCareTeamSlice,
  shared: sharedSlice,
  patientAssociation: patientAssociationSlice,
  appointments: appointmentsSlice,
  toast: toastSlice,
  dashboard: dashboardSlice,
  comments:commentsSlice
});
