import { History } from "./../../history";
import {
  requestPatientProfileDetails,
  requestPatientProfileDevices,
  requestPatientSummaryVitals,
  requestPatientSummaryClinicalData,
  requestPatientSummaryConditions,
  requestPatientSummaryEncounters,
  requestPatientAllergies,
  requestPatientCoverage,
  requestPatientMedication,
  requestPatientDocuments,
  requestPatientDetailedVitals,
  requestPatientDetailedClinicalData,
  requestPatientDetailedConditions,
  requestPatientDetailedEncounters,
  requestPatientEnrollmentStatus,
  requestResendEmail,
  requestPackageInitiation,
  requestGetAllPatients,
  requestGetAllPatientsWithParams,
  requestALlAlert,
  requestGetMyPatients,
  requestGetMyPatientsWithParams,
  requestGetPatientMonitoringFilter,
  requestPatientDeviceDetailed,
  requestGetPatientSurvey,
  requestgetCarePackageAuthorbyName,
  requestGetAllPatientCarePackage,
  requestDeleteCarePackage,
  requestRemovePatient,
  requestSendPatientEnrollmentConsent,
  requestGetDeviceReadingList,
  sendActivationNotification,
} from "./patientsRequest";
import {
  setPatientProfileDetails,
  setPatientSummaryVitals,
  setPatientSummaryClinicalData,
  setPatientSummaryConditions,
  setPatientSummaryEncounters,
  setPatientAllergies,
  setPatientCoverage,
  setPatientsMedication,
  setPatientsDocuments,
  setPatientSummaryVitalsError,
  setPatientSummaryClinicalDataError,
  setPatientSummaryConditionsError,
  setPatientSummaryEncountersError,
  setPatientMedicationError,
  setPatientAllergiesError,
  setPatientDocumentsError,
  setPatientCoverageError,
  setPatientDetailedVitalsError,
  setPatientDetailedVitals,
  setPatientDetailedClinicalDataError,
  setPatientDetailedClinicalData,
  setPatientDetailedConditionsError,
  setPatientDetailedConditions,
  setPatientDetailedEncountersError,
  setPatientDetailedEncounters,
  setPatientEnrollmentStatusError,
  setPatientEnrollmentStatus,
  setAllPatients,
  setMyPatients,
  setAllPatientsLoading,
  setMyPatientsLoading,
  setPatientMonitoringFilter,
  setPatientMonitoringFilterLoading,
  setPatientDevices,
  setPatientDevicesError,
  setPatientDevicesDetailsError,
  setPatientDevicesDetails,
  setPatientSurveysError,
  setPatientSurveys,
  getPatientEnrollmentStatus,
  setPatientEnrollmentNotFound,
  setAllPatientCarePackages,
  setDeviceReadingListGlobal,
  setAllPatientList,
  setallAlertCount,
} from "./patientsSlice";
import { setSelectedPatient } from "./enrollment/careTeam/careTeamSlice";

import { call, put } from "redux-saga/effects";
import moment from "moment";
import { openSnackbar } from "./../../store/toast/toastSlice";

export function* handleGetAllPatients(action) {
  try {
    yield put(setAllPatientsLoading(true));
    const response = yield call(requestGetAllPatients, action.payload);
    const { data } = response;
    yield put(setAllPatientList({ ...data }));
    yield put(setAllPatientsLoading(false));
  } catch (error) {
    console.log("something went wrong!");
  }
}
export function* handleGetAllPatientsWithParams(action) {
  try {
    yield put(setAllPatientsLoading(true));
    const response = yield call(
      requestGetAllPatientsWithParams,
      action.payload
    );
    const { data } = response;
    yield put(setAllPatients({ ...data }));
    const res = yield call(
      requestALlAlert,
      `patientId=${response.data.entry[0].id}&cpIdentifier=${response.data.entry[0].cpIdentifier}&status=in-progress`
    );
    yield put(setallAlertCount(res.data.totalActiveAlertsInSystem));
    yield put(setAllPatientsLoading(false));
  } catch (error) {
    console.log(error);
    yield put(setAllPatientsLoading(false));
  }
}

export function* handleGetrequestALlAlert(action) {
  try {
    yield put(setAllPatientsLoading(true));
    const response = yield call(requestALlAlert, action.payload);
    const { data } = response;
    yield put(setallAlertCount({ ...data.data.totalActiveAlertsInSystem }));
    yield put(setAllPatientsLoading(false));
  } catch (error) {
    console.log("something went wrong!");
    yield put(setAllPatientsLoading(false));
  }
}

export function* handlegetCarePackageAuthorName(action) {
  try {
    const response = yield call(
      requestgetCarePackageAuthorbyName,
      action.payload
    );
    const { data } = response;
    // yield put(setAllPatients({ ...data }));
    // yield put(setAllPatientsLoading(false));
  } catch (error) {
    console.log("something went wrong!");
  }
}

export function* handleGetMyPatients(action) {
  try {
    yield put(setMyPatientsLoading(true));
    const response = yield call(requestGetMyPatients, action.payload);
    const { data } = response;
    yield put(setMyPatients({ ...data }));
    yield put(setMyPatientsLoading(false));
  } catch (error) {
    console.log("something went wrong!");
  }
}

export function* handleGetMyPatientsWithParams(action) {
  try {
    yield put(setMyPatientsLoading(true));
    const response = yield call(requestGetMyPatientsWithParams, action.payload);
    const { data } = response;
    yield put(setMyPatients({ ...data }));
    yield put(setMyPatientsLoading(false));
  } catch (error) {
    yield put(setMyPatientsLoading(false));
    console.log("something went wrong!");
  }
}

export function* handleGetPatientMonitoringFilter(action) {
  try {
    yield put(setPatientMonitoringFilterLoading(true));
    const response = yield call(requestGetPatientMonitoringFilter);
    const { data } = response;
    yield put(setPatientMonitoringFilter({ ...data }));
    yield put(setPatientMonitoringFilterLoading(false));
  } catch (error) {
    console.log("something went wrong!");
  }
}

export function* handleGetDeviceReadingList(action) {
  try {
    const response = yield call(requestGetDeviceReadingList, action.payload);
    const { data } = response;
    if (data) {
      yield put(setDeviceReadingListGlobal([...data]));
    }
  } catch (error) {
    console.log("something went wrong!");
  }
}

export function* handlePatientProfileDetails(action) {
  try {
    const response = yield call(requestPatientProfileDetails, action.payload);
    const { data } = response;
    yield put(setPatientProfileDetails({ ...data }));
    yield put(setSelectedPatient({ ...data }));
  } catch (error) {
    console.log("something went wrong!");
    History.navigate("/patients");
  }
}
export function* handlePatientProfileDevices(action) {
  try {
    yield put(setPatientDevicesError({ error: false, loading: true }));
    const response = yield call(requestPatientProfileDevices, action.payload);
    const { entry } = response.data;
    yield put(setPatientDevices(entry));
  } catch (error) {
    console.log("something went wrong!");
    yield put(setPatientDevicesError({ error: error, loading: false }));
  }
}
export function* handlePatientDeviceDetailed(action) {
  try {
    yield put(setPatientDevicesDetails(""));
    yield put(setPatientDevicesDetailsError({ error: false, loading: true }));
    const response = yield call(requestPatientDeviceDetailed, action.payload);
    const { data } = response;
    var start = moment(`${action.payload.startDate}`, "YYYY-MM-DD");
    var end = moment(`${action.payload.endDate}`, "YYYY-MM--DD");
    let dateDiff = end.diff(start, "days");
    if (dateDiff === 0) {
      let ModifyDailyTime = data.allmetricsdata[0].daily[0].graphdata.map(
        (manufacturer) => {
          manufacturer.device.map((device) => {
            let gmtDateTime = moment.utc(device?.created_at_full);
            let local = gmtDateTime.local().format("h:mm A");
            device.period = local;
            return device;
          });
          return manufacturer;
        }
      );
      data.allmetricsdata[0].daily[0].graphdata = ModifyDailyTime;
    }
    yield put(setPatientDevicesDetails(data));
  } catch (error) {
    console.log("something went wrong!");
    yield put(setPatientDevicesDetailsError({ error: true, loading: false }));
  }
}
export function* handlePatientEnrollmentStatus(action) {
  try {
    yield put(setPatientEnrollmentStatusError({ error: false, loading: true }));
    const response = yield call(requestPatientEnrollmentStatus, action.payload);
    const {
      data: { entry },
    } = response;
    if (Array.isArray(entry)) {
      yield put(setPatientEnrollmentStatus({ entry }));
    }
  } catch (error) {
    // yield put(setPatientEnrollmentStatusError({ error: true, loading: false }));
    if (error?.response?.data?.errors && error?.response?.status == 400) {
      yield put(setPatientEnrollmentNotFound());
    } else {
      yield put(
        setPatientEnrollmentStatusError({ error: true, loading: false })
      );
      let message = error?.response?.data?.errors;
      if (message && Array.isArray(message) && message.length > 0) {
        yield put(
          openSnackbar({
            snackbarTitle: message[0].description,
            icon: "",
            severity: "error",
          })
        );
      }
    }
  }
}

export function* handleResendEmail(action) {
  try {
    yield call(requestResendEmail, action.payload);
    yield put(
      openSnackbar({
        snackbarTitle: "Notification sent",
        icon: "",
        severity: "success",
      })
    );
  } catch (error) {
    let data = error?.response?.data;
    if (data?.errors && Array.isArray(data?.errors) && message.length > 0) {
      yield put(
        openSnackbar({
          snackbarTitle: data?.errors[0].description,
          icon: "",
          severity: "error",
        })
      );
    } else if (data?.message && data?.message.length > 0) {
      yield put(
        openSnackbar({
          snackbarTitle:
            data?.message + ". Redirecting to start the care design",
          icon: "",
          severity: "success",
        })
      );
      History.navigate(
        `/patients/${data?.patientId}/package/${data?.cpIdentifier}`
      );
    }
  }
}

export function* handlePatientSummaryVitals(action) {
  try {
    yield put(setPatientSummaryVitalsError({ error: false, loading: true }));
    const response = yield call(requestPatientSummaryVitals, action.payload);
    const { data } = response;
    yield put(setPatientSummaryVitals({ ...data }));
  } catch (error) {
    console.log("something went wrong!");
    yield put(setPatientSummaryVitalsError({ error: true, loading: false }));
  }
}

export function* handlePatientDetailedVitals(action) {
  try {
    yield put(setPatientDetailedVitalsError({ error: false, loading: true }));
    const response = yield call(requestPatientDetailedVitals, action.payload);
    const { data } = response;
    yield put(setPatientDetailedVitals({ ...data }));
  } catch (error) {
    console.log("something went wrong!");
    yield put(setPatientDetailedVitalsError({ error: true, loading: false }));
  }
}

export function* handlePatientSummaryClinicalData(action) {
  try {
    yield put(
      setPatientSummaryClinicalDataError({ error: false, loading: true })
    );
    const response = yield call(
      requestPatientSummaryClinicalData,
      action.payload
    );
    const { data } = response;
    yield put(setPatientSummaryClinicalData({ ...data }));
  } catch (error) {
    console.log("something went wrong!");
    yield put(
      setPatientSummaryClinicalDataError({ error: true, loading: false })
    );
  }
}

export function* handlePatientDetailedClinicalData(action) {
  try {
    yield put(
      setPatientDetailedClinicalDataError({ error: false, loading: true })
    );
    const response = yield call(
      requestPatientDetailedClinicalData,
      action.payload
    );
    const { data } = response;
    yield put(setPatientDetailedClinicalData({ ...data }));
  } catch (error) {
    console.log("something went wrong!");
    yield put(
      setPatientDetailedClinicalDataError({ error: true, loading: false })
    );
  }
}

export function* handlePatientSummaryConditions(action) {
  try {
    yield put(
      setPatientSummaryConditionsError({ error: false, loading: true })
    );
    const response = yield call(
      requestPatientSummaryConditions,
      action.payload
    );
    const { data } = response;
    yield put(setPatientSummaryConditions({ ...data }));
  } catch (error) {
    console.log("something went wrong!");
    yield put(
      setPatientSummaryConditionsError({ error: true, loading: false })
    );
  }
}

export function* handlePatientDetailedConditions(action) {
  try {
    yield put(
      setPatientDetailedConditionsError({ error: false, loading: true })
    );
    const response = yield call(
      requestPatientDetailedConditions,
      action.payload
    );
    const { data } = response;
    yield put(setPatientDetailedConditions({ ...data }));
  } catch (error) {
    console.log("something went wrong!");
    yield put(
      setPatientDetailedConditionsError({ error: true, loading: false })
    );
  }
}

export function* handlePatientSummaryEncounters(action) {
  try {
    yield put(
      setPatientSummaryEncountersError({ error: false, loading: true })
    );
    const response = yield call(
      requestPatientSummaryEncounters,
      action.payload
    );
    const { data } = response;
    yield put(setPatientSummaryEncounters({ ...data }));
  } catch (error) {
    console.log("something went wrong!");
    yield put(
      setPatientSummaryEncountersError({ error: true, loading: false })
    );
  }
}

export function* handlePatientDetailedEncounters(action) {
  try {
    yield put(
      setPatientDetailedEncountersError({ error: false, loading: true })
    );
    const response = yield call(
      requestPatientDetailedEncounters,
      action.payload
    );
    const { data } = response;
    yield put(setPatientDetailedEncounters({ ...data }));
  } catch (error) {
    console.log("something went wrong!");
    yield put(
      setPatientDetailedEncountersError({ error: true, loading: false })
    );
  }
}

export function* handlePatientMedication(action) {
  try {
    yield put(setPatientMedicationError({ error: false, loading: true }));
    const response = yield call(requestPatientMedication, action.payload);
    const { data } = response;
    yield put(setPatientsMedication({ ...data }));
  } catch (error) {
    console.log("something went wrong!");
    yield put(setPatientMedicationError({ error: true, loading: false }));
  }
}

export function* handlePatientDocuments(action) {
  try {
    yield put(setPatientDocumentsError({ error: false, loading: true }));
    const response = yield call(requestPatientDocuments, action.payload);
    const { data } = response;
    yield put(setPatientsDocuments({ ...data }));
  } catch (error) {
    console.log("something went wrong!");
    yield put(setPatientDocumentsError({ error: true, loading: false }));
  }
}

export function* handlePatientAllergies(action) {
  try {
    yield put(setPatientAllergiesError({ error: false, loading: true }));
    const response = yield call(requestPatientAllergies, action.payload);
    const { data } = response;
    yield put(setPatientAllergies({ ...data }));
  } catch (error) {
    yield put(setPatientAllergiesError({ error: true, loading: false }));
  }
}

export function* handlePatientCoverage(action) {
  try {
    yield put(setPatientCoverageError({ error: false, loading: true }));
    const response = yield call(requestPatientCoverage, action.payload);
    const { data } = response;
    yield put(setPatientCoverage({ ...data }));
  } catch (error) {
    yield put(setPatientCoverageError({ error: true, loading: false }));
  }
}

export function* handlePackageInitiation(action) {
  try {
    const response = yield call(requestPackageInitiation, action.payload);
    if (response?.status === 200) {
      yield put(getPatientEnrollmentStatus(action.payload.id));
      let str = action.payload.cpId.substring(
        0,
        action.payload.cpId.length - 5
      );
      let newCpId = str + "ACTVE";
      History.navigate(`/patients/${action.payload.id}/package/${newCpId}`);
      if (action.payload.type === "now") {
        yield call(sendActivationNotification, {
          patientId: action.payload.id,
          category: action.payload.category,
          cpIdentifier: newCpId,
        });
      }
    }
  } catch (error) {
    let message = error?.response?.data?.errors;
    if (message && Array.isArray(message) && message.length > 0) {
      if (message[0].description === "No Records Found") {
        yield put(
          openSnackbar({
            snackbarTitle:
              "Care package activated. Patient has not logged in for notification.",
            icon: "",
            severity: "success",
          })
        );
      } else {
        yield put(
          openSnackbar({
            snackbarTitle: message[0].description,
            icon: "",
            severity: "error",
          })
        );
      }
    }
  }
}

export function* handleGetPatientSurveys(action) {
  try {
    yield put(setPatientSurveysError({ error: false, loading: true }));
    const response = yield call(requestGetPatientSurvey, action.payload);
    const { data } = response;
    yield put(setPatientSurveys({ ...data }));
  } catch (error) {
    yield put(setPatientSurveysError({ error: true, loading: false }));
  }
}

export function* handleGetAllPatientCarePackage(action) {
  try {
    const response = yield call(
      requestGetAllPatientCarePackage,
      action.payload
    );
    const { entry } = response.data;
    if (Array.isArray(entry)) {
      yield put(setAllPatientCarePackages(entry));
    }
  } catch (error) {}
}

export function* handleDeleteCarePackage(action) {
  try {
    const response = yield call(requestDeleteCarePackage, action.payload);
    History.navigate("/patients");
    yield put(
      openSnackbar({
        snackbarTitle: "Patient care package has been deleted successfully!",
        icon: "",
        severity: "success",
      })
    );
  } catch (error) {
    let message = error?.response?.data?.description;
    yield put(
      openSnackbar({
        snackbarTitle: message,
        icon: "",
        severity: "error",
      })
    );
  }
}

export function* handleDeletePatient(action) {
  try {
    const response = yield call(requestRemovePatient, action.payload);
    History.navigate("/patients");
    yield put(
      openSnackbar({
        snackbarTitle: "Package deleted",
        icon: "",
        severity: "success",
      })
    );
  } catch (error) {
    let message = error?.response?.data?.description;
    yield put(
      openSnackbar({
        snackbarTitle: message,
        icon: "",
        severity: "error",
      })
    );
  }
}

export function* handleSendPatientEnrollmentConsent(action) {
  try {
    const { patientUserId, ...reqBody } = action.payload;
    const response = yield call(requestSendPatientEnrollmentConsent, reqBody);
    const { data } = response;
    History.navigate(`/patients/${patientUserId}/package/${data.cpIdentifier}`);
  } catch (error) {
    let message = error?.response?.data?.errors;
    if (message && Array.isArray(message) && message.length > 0) {
      yield put(
        openSnackbar({
          snackbarTitle: message[0].description,
          icon: "",
          severity: "error",
        })
      );
    } else {
      yield put(
        openSnackbar({
          snackbarTitle: "Something went wrong",
          icon: "",
          severity: "error",
        })
      );
    }
  }
}

export function* handleInitiateCareDesign(action) {
  try {
    const { patientUserId, ...reqBody } = action.payload;
    const response = yield call(requestSendPatientEnrollmentConsent, reqBody);
    const { data } = response;
    History.navigate(
      `/patients/${patientUserId}/package/${data.cpIdentifier}/enrollment`
    );
  } catch (error) {
    let message = error?.response?.data?.errors;
    if (message && Array.isArray(message) && message.length > 0) {
      yield put(
        openSnackbar({
          snackbarTitle: message[0].description,
          icon: "",
          severity: "error",
        })
      );
    } else {
      yield put(
        openSnackbar({
          snackbarTitle: "Something went wrong",
          icon: "",
          severity: "error",
        })
      );
    }
  }
}
