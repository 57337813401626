import { createSlice } from "@reduxjs/toolkit";

const initState = {
  name: "",
  category: "",
  procedureCode: "",
  diagnosisCode: "",
  trigger: "",
  description: "",
  patientId: "",
  careTeam: "",
  patientFHIRId: "",
  cpIdentifier: "",
  state: "Pending Approval",
  reviewer: [
    {
      current_state: "Pending Approval",
      assigned_to: "",
      assigned_on: new Date().toISOString().slice(0, 10),
    },
  ],
  author: [],
  careArea: [],
  careGoal: [],
  action: [],
};

const patientCarePackageSlice = createSlice({
  name: "patientCarePackage",
  initialState: {
    notes: [],
    initialpatientcarePackageTemplates: initState,
    patientcarePackageTemplates: initState,
    carePackageProgramCategory: [],
    carePackageGoalandActivitiesobj: {},
    carePackagesavedActivityListInGoalandActivities: [],
    carePackageProgramCategories: [],
    patientCarePackagebyCategoryName: null,
    patientCarePackagebyCategoryNameforDraft: null,
    selectedCarePackagebyCategory: {},
    draftCarePackagebyCategory: {},
    carePackageAllActivityListfromApi: [],
    selectedActivityByType: [],
    devicePageActionarray: [],
    selectedCareTeam: null,
    reviewer: null,
    formErrors: {
      Caregive: "",
      HouseApartment: "",
      Structuralfunctional: "",
      transportation: "",
    },
    unSavedActivity: {},
    carePackageByCatLoaded: false,
    surveyList: [],
    patientSurveyByQuestions: {},
    patientSurveyByDate: {},
  },
  reducers: {
    setPatientSurveyByDate(state, action) {
      state.patientSurveyByDate = action.payload;
    },
    getPatientSurveyByDate() {},
    setPatientSurveyByQuestions(state, action) {
      state.patientSurveyByQuestions = action.payload;
    },
    getPatientSurveyByQuestions() {},
    setSurveyList(state, action) {
      state.surveyList = action.payload;
    },
    getSurveyList() {},
    setCarePackageByCatLoaded(state, action) {
      state.carePackageByCatLoaded = action.payload;
    },
    getCarePackageTemplates() {},
    getCarePackageProgram() {},
    postCarePackageTemplate(payload) {},

    setSelectedCarePackagebyCategory(state, action) {
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        action.payload = action.payload[0].patientPlanDefinitionRequest;
      }
      state.selectedCarePackagebyCategory = action.payload;
      let oldNotes = [];
      if (action.payload && action.payload.reviewer) {
        action.payload.reviewer.forEach((r) => {
          if (r.hasOwnProperty("notes")) {
            r.notes.forEach((n) => {
              oldNotes.push({ ...n, isNew: false });
            });
          }
        });
      }
      state.notes = oldNotes;
    },

    setCarePackageTemplateReviewerarray(state) {
      state.patientcarePackageTemplates.reviewer.unshift({
        current_state: "Pending Approval",
        assigned_to: state.reviewer,
        assigned_on: new Date().toISOString().slice(0, 10),
      });
    },
    setCarePackageProgram(state, action) {
      state.carePackageProgramCategory = action.payload;
    },
    setCarePackageGoalandActivityobj(state, action) {
      state.carePackageGoalandActivitiesobj = action.payload;
    },
    setcarePackagesavedActivityListInGoalandActivities(state, action) {
      let previousarray = state.carePackagesavedActivityListInGoalandActivities;
      let array = previousarray.concat(action.payload);
      state.carePackagesavedActivityListInGoalandActivities = array;
    },

    setcarePackageProgramCategory(state, action) {
      state.carePackageProgramCategories = action.payload;
    },

    //newe Function
    getcarePackageAllActivityListfromApi() {},
    postpatientCarePackageReviewSubmit(payload) {},
    saveandExitpatientCarePackage(payload) {},
    putpatientCarePackageReviewSubmitModifiedRequest(payload) {},
    updatepatientCarePackageTemplate(state, action) {
      state.patientcarePackageTemplates = action.payload;
    },
    getPatientCarePackagebyCategoryName(payload) {},
    getPatientCarePackagebyPatientIdforModifying(payload) {},
    getPatientCarePackagebyPatientIdforDraft(payload) {},
    getCarePackageActivityByName(name) {},
    getCarePackageUnSavedActivityByName(name) {},
    getCarePackageActivityByType(payload) {},
    setPatientCarePackagebyCategoryName(state, action) {
      state.patientCarePackagebyCategoryName = action.payload;
    },
    setPatientCarePackagebyCategoryNameforDraft(state, action) {
      state.patientCarePackagebyCategoryNameforDraft = action.payload;
    },
    setcarePackageAllActivityListfromApi(state, action) {
      state.carePackageAllActivityListfromApi = action.payload;
    },
    setCarePackageActivityByName(state, action) {
      state.selectedCarePackagebyCategory.action.push(action.payload);
    },
    setUnSavedCarePackageActivityByName(state, action) {
      state.unSavedActivity = action.payload;
    },
    setselectedPatientCarePackage(state, action) {
      state.selectedCarePackagebyCategory = action.payload;
    },
    setdraftPatientCarePackage(state, action) {
      state.draftCarePackagebyCategory = action.payload;
    },
    setPatientCarePackagereActivityByType(state, action) {
      let index = state.selectedActivityByType.findIndex(
        (item) => item.deviceType === action.payload.deviceType
      );
      if (index === -1) {
        state.selectedActivityByType.push(action.payload);
      }
    },
    setPatientCarePackagereDeviceArray(state, action) {
      state.devicePageActionarray = action.payload;
    },
    setSelectedPatientCareTeam(state, action) {
      state.selectedCareTeam = action.payload;
    },
    setReviewer(state, action) {
      state.reviewer = action.payload;
    },
    validatePatientCarePackagereDeviceArray(state, action) {
      state.devicePageActionarray.forEach((a) => {
        if (a.activityType === "Appointment") {
          if (!a.hasOwnProperty("speciality")) {
            a.speciality = "";
          }

          if (!a.hasOwnProperty("participant")) {
            a.participant = "";
          }
        }
      });
    },
    resetAssociationCarePackageTemplates(state, action) {
      state.patientcarePackageTemplates =
        state.initialpatientcarePackageTemplates;
    },
    setformErrors(state, action) {
      state.formErrors = action.payload;
    },
    setcompleteCarePackagecareGoal(state, action) {
      state.selectedCarePackagebyCategory.careGoal = action.payload;
    },
    setcompleteCarePackageAction(state, action) {
      state.selectedCarePackagebyCategory.action = action.payload;
    },
  },
});

export const {
  setformErrors,
  setdraftPatientCarePackage,
  getPatientCarePackagebyPatientIdforModifying,
  getPatientCarePackagebyPatientIdforDraft,
  setcompleteCarePackageAction,
  setcompleteCarePackagecareGoal,
  setUnSavedCarePackageActivityByName,
  getCarePackageUnSavedActivityByName,
  setCarePackageTemplateReviewerarray,
  resetAssociationCarePackageTemplates,
  setSelectedPatientCareTeam,
  validatePatientCarePackagereDeviceArray,
  updatepatientCarePackageTemplate,
  postpatientCarePackageReviewSubmit,
  saveandExitpatientCarePackage,
  putpatientCarePackageReviewSubmitModifiedRequest,
  setPatientCarePackagereDeviceArray,
  getCarePackageActivityByType,
  setPatientCarePackagereActivityByType,
  setselectedPatientCarePackage,
  setSelectedCarePackagebyCategory,
  getPatientCarePackagebyCategoryName,
  setPatientCarePackagebyCategoryName,
  setPatientCarePackagebyCategoryNameforDraft,
  postCarePackageTemplate,
  setcarePackageProgramCategory,
  getcarePackageAllActivityListfromApi,
  setcarePackageAllActivityListfromApi,
  getCarePackageTemplates,
  setcarePackagesavedActivityListInGoalandActivities,
  setCarePackageGoalandActivityobj,
  setCarePackageProgram,
  getCarePackageProgram,
  setCarePackageActivityByName,
  getCarePackageActivityByName,
  setReviewer,
  setCarePackageByCatLoaded,
  setSurveyList,
  getSurveyList,
  setPatientSurveyByDate,
  getPatientSurveyByDate,
  setPatientSurveyByQuestions,
  getPatientSurveyByQuestions,
} = patientCarePackageSlice.actions;

export default patientCarePackageSlice.reducer;
