import API from "../../lib/api";
// import urmAPI from "../../lib/urmapi";

import { REACT_APP_VACA_PDM_API, CARE_PACKAGE_BASE_URL } from './../../utils/constants';

export function requestGetUserProfile(email) {
  return API.get(`${CARE_PACKAGE_BASE_URL}/UserProfile?email=${email}`);
}

export function requestUpdateUserProfile(body) {
  return API.put(`${CARE_PACKAGE_BASE_URL}/PractitionerRole`, body);
}

export function requestUpdateAppointmentPreference(body) {
  return API.put(`${CARE_PACKAGE_BASE_URL}/AppointmentPreference`, body);
}

export function requestJoinAppointment(body) {
  return API.put(`${CARE_PACKAGE_BASE_URL}/join`, body);
}

export function requestGetAppointments({
  startDate,
  endDate,
  practitionerRoleId,
  practitionerName,
}) {
  return API.get(
    `${CARE_PACKAGE_BASE_URL}/fetchAppointments?startDate=${startDate}&endDate=${endDate}&practitionerName=${practitionerName}&practitionerRoleId=${practitionerRoleId}`
  );
}

export function requestEndAppointments(body) {
  return API.post(`${CARE_PACKAGE_BASE_URL}/EndAppointment`, body);
}

export function requestGetPermissions(payload) {
  try{
    // return urmAPI.get(`${REACT_APP_VACA_PDM_API}/api/urm/v2/permissions/${payload.email}`);
    return API.get(`${REACT_APP_VACA_PDM_API}/permissions/${payload.email}`);
  } catch(error){
    console.log('error', error);
  }
}
