import { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  setOnBoardPractitioners,
  setvalidatePractitioners,
  setResendvalidatePractitioners,
} from "../../store/shared/sharedSlice";
import "./Login.scss";
import OtpInput from "react-otp-input";
import {
  SSO_BASE_URL,
  SSO_IDENTITY_PROVIDER,
  SSO_REDIRECT_URI,
  SSO_RESPONSE_TYPE,
  SSO_CLIENT_ID,
  SSO_SCOPE,
} from "../../utils/constants";
import moment from "moment-timezone";
import { sendPatientTimeZone } from "../../store/shared/sharedRequest";

const Login = () => {
  const [email, setEmail] = useState("");
  const [otp, setotp] = useState();
  const [nextSection, setnextSection] = useState(false);
  const [disabledButton, setdisabledButton] = useState(true);
  const dispatch = useDispatch();
  const { onboardPractitionerError, onboardPractitionerResponse } = useSelector(
    (state) => state.shared
  );

  useEffect(() => {
    if (onboardPractitionerResponse && email) {
      setnextSection(true);
    }
  }, [onboardPractitionerResponse]);

  const handleUserInput = (event) => {
    const { name, value } = event.target;
    if (name.includes("email")) {
      setEmail(value);
    }
  };

  const validatepratitioners = async () => {
    let body = {
      emailId: email,
      invitationCode: otp,
    };
    await dispatch(
      setvalidatePractitioners({
        body: body,
        onSuccess,
        onFailure,
      })
    );
  };

  const onSuccess = async () => {
    let abbrs = {
      EST: "Eastern Standard Time",
      EDT: "Eastern Daylight Time",
      CST: "Central Standard Time",
      CDT: "Central Daylight Time",
      MST: "Mountain Standard Time",
      MDT: "Mountain Daylight Time",
      PST: "Pacific Standard Time",
      PDT: "Pacific Daylight Time",
      IST: "India Standard Time",
    };

    let timezone = new Date()
      .toLocaleDateString(undefined, { day: "2-digit", timeZoneName: "long" })
      .substring(4);
    let timeZoneAbbr = Object.keys(abbrs).find(
      (key) => abbrs[key] === timezone
    );

    let body = {
      user_type: "practitioner",
      user_info: email,
      timezone: timeZoneAbbr === undefined ? timezone : timeZoneAbbr,
      local_datetime: moment().format("YYYY-MM-DD hh:mm:ss.mmm"),
    };
    body["timezone_offset"] = "UTC" + moment().tz(body.timezone).format("Z");
    let response = await sendPatientTimeZone(body);
    if (response) {
      localStorage.setItem(
        "timezone_offset",
        response.data?.entry?.timezone_offset?.split("UTC")[1]
      );
      window.location.href = "/dashboard";
    }
  };

  const onFailure = () => {
    console.log("something went wrong");
  };

  const emailCheck = async (e) => {
    e.preventDefault();

    if (email !== "") {
      try {
        let body = {
          email_id: email,
        };
        await dispatch(setOnBoardPractitioners(body));
      } catch {
        setnextSection(false);
      }
    }
  };

  const otpCheck = async (e) => {
    e.preventDefault();

    if (otp !== "") {
      try {
        await validatepratitioners();
      } catch {
        setnextSection(false);
      }
    }
  };

  const resendOtp = () => {
    let body = {
      emailId: email,
    };
    dispatch(setResendvalidatePractitioners(body));
  };

  const handleChange = (otpchange) => {
    setotp(otpchange);
    if (otpchange.length > 5) {
      setdisabledButton(false);
    } else {
      setdisabledButton(true);
    }
  };

  return (
    <>
      <div className="row margin-zero">
        <div className="col-md-6 row container">
          <div className="col-md-2"></div>

          <div
            className="col-md-9 middleLoginMargin"
            style={{ marginTop: "80px" }}
          >
            <div className="row container dswtext">CareConductor</div>
            {nextSection === false ? (
              <>
                <div className="row container welcomeText">
                  {" "}
                  Welcome to CareConductor
                </div>
                <div className="row container signinText">
                  Sign In to continue
                </div>
                <div className="row container ssoShift">
                  {" "}
                  <a
                    className="ssoButton"
                    href={`${SSO_BASE_URL}/oauth2/authorize?identity_provider=${SSO_IDENTITY_PROVIDER}&redirect_uri=${SSO_REDIRECT_URI}&response_type=${SSO_RESPONSE_TYPE}&client_id=${SSO_CLIENT_ID}&scope=${SSO_SCOPE}`}
                    onC
                    target={"_self"}
                  >
                    Sign In with SSO
                  </a>
                </div>
                <div className="row container ssoShift">
                  <div className="col-md loginGutters">
                    <hr></hr>
                  </div>
                  <div className="col-md-5 loginGutters">
                    or sign in with email
                  </div>
                  <div className="col-md loginGutters">
                    <hr></hr>
                  </div>
                </div>

                <Form className="">
                  <Form.Group controlId="formBasicEmail" className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      autoComplete="autocomplete_off_randString"
                      className="emailTextSize"
                      name={`email-${Date()}`}
                      placeholder="Enter email"
                      required={true}
                      onChange={(e) => handleUserInput(e)}
                    />
                  </Form.Group>

                  <Button
                    className="signInButton"
                    variant="primary"
                    type="submit"
                    data-testid="email-check"
                    onClick={emailCheck}
                  >
                    Sign In
                  </Button>
                  {onboardPractitionerError && (
                    <div className="emailEror">No Result Found</div>
                  )}
                </Form>
              </>
            ) : (
              <>
                <div className="row container welcomeText"> Verify Email</div>
                <div className="row container signinText">
                  Enter the OTP sent to your registered email
                </div>

                <div className="row container">
                  {" "}
                  <OtpInput
                    inputStyle="otpInput"
                    value={otp}
                    onChange={handleChange}
                    numInputs={6}
                    data-testid="otp-input"
                    separator={<span></span>}
                  />
                </div>
                <Button
                  className="verifyButton"
                  variant="primary"
                  type="submit"
                  onClick={otpCheck}
                  disabled={disabledButton}
                >
                  Verify Account
                </Button>
                <div className="container mt-3 didnot-receive-code-wrap">
                  Didn’t receive code?
                  <a
                    href="#"
                    className="link-primary resend"
                    onClick={resendOtp}
                  >
                    Resend
                  </a>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="col-md-6 backgroundImageLogin "></div>
      </div>
    </>
  );
};

export default Login;
