export const sagaActions = {
  FETCH_DATA_SAGA: "FETCH_DATA_SAGA",
  FETCH_DATA_SAGAEnrollment: "FETCH_DATA_SAGAEnrollment",
  FETCH_DATA_SAGAEnrollmentStatus: "FETCH_DATA_SAGAEnrollmentStatus",
  FETCH_DATA_SAGAResendEmail: "FETCH_DATA_SAGAResendEmail",
  FETCH_DATA_SAGAEnvironmentAcessSafety: "FETCH_DATA_SAGAEnvironmentAcessSafety",
  FETCH_DATA_SAGAEnvironmentAcessSafetyPost: "FETCH_DATA_SAGAEnvironmentAcessSafetyPost",
  Fetch_Data_Saga_PatientPageLoad_CardApi: "Fetch_Data_Saga_PatientPageLoad_CardApi",
  saveReduxPatientPageLoad_CardApi: "saveReduxPatientPageLoad_CardApi"
};

