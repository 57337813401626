import { takeLatest, takeEvery  } from "redux-saga/effects";

import {
  handleGetCareTeamTemplates,
  handleGetCareTeamByName,
  handleGetCarePackageCategory,
  handleGetSpecialties,
  handleGetPractitioners,
  handleCreateNewCareTeam,
  handleUpdateExistingCareTeam
} from "./careTeamHandler";
import {
  getCareTeamTemplates,
  getCareTeamByName,
  getCarePackageCategory,
  getSpecialties,
  getPractitioners,
  createNewCareTeam,
  updateExistingCareTeam,
} from "./careTeamSlice";

export const careTeamSaga = [
  takeLatest(getCareTeamTemplates.type, handleGetCareTeamTemplates),
  takeLatest(getCareTeamByName.type, handleGetCareTeamByName),
  takeLatest(getCarePackageCategory.type, handleGetCarePackageCategory),
  takeLatest(getSpecialties.type, handleGetSpecialties),
  takeEvery(getPractitioners.type, handleGetPractitioners),
  takeEvery(createNewCareTeam.type, handleCreateNewCareTeam),
  takeLatest(updateExistingCareTeam.type, handleUpdateExistingCareTeam),
];
