import API from "../../../lib/api";

import {
  EMR_BASE_URL,
  ENROLLMENT_BASE_URL,
  DEVICES_BASE_URL,
  CARE_PACKAGE_BASE_URL,
  CARE_PACKAGE_PATIENT_BASE_URL,
  EMR_PATIENT_BASE_URL,
  PATIENT_DEVICES_BASE_URL,
  REACT_APP_WEB_BASE_URL,
} from "./../../../utils/constants";

export function requestCreateGeneralComment(body) {
  return API.post(`${REACT_APP_WEB_BASE_URL}/Alerts/Comment`, body);
}
export function requestgetAllComments(obj) {
  let  commentType =``
  if(obj?.commentType === 'All'){
    commentType =``
  }else{
     commentType =`&commentType=${obj.commentType}`
  }
    
  return API.get(
    `${REACT_APP_WEB_BASE_URL}/Alerts/Comment?patientId=${obj.patientId}&cpIdentifier=${obj.cpIdentifier}${commentType}`
  );
}
export function requestReviewedAlert(body) {
  return API.put(`${REACT_APP_WEB_BASE_URL}/Alerts/Alert`, body);
}

export function requestGetAllReviewAlert(body) {
  return API.get(`${REACT_APP_WEB_BASE_URL}/Alerts/Alert?patientId=${body.patientId}&cpIdentifier=${body.cpIdentifier}&status=in-progress&alertType=device`);
}
