import {
  requestCreateGeneralComment,
  requestgetAllComments,
  requestReviewedAlert,
  requestGetAllReviewAlert,
} from "./commentsRequest";
import {
  setAllComments,
  setCommentsSuccess,
  setErrorFlagAlerts,
  setAllReviewAlert,
} from "./commentsSlice";

import { call, put } from "redux-saga/effects";

export function* handleCreateGeneralComment(action) {
  try {
    const response = yield call(requestCreateGeneralComment, action.payload);
    const { data } = response;
    yield put(setCommentsSuccess({ ...data }));
  } catch (error) {
    console.log("something went wrong!");
  }
}

export function* handleReviewedAlert(action) {
  try {
    const response = yield call(requestReviewedAlert, action.payload);
    if (
      response.data?.entry[0]?.code === 400 ||
      response.data?.entry[0]?.code === 409
    ) {
      yield put(
        setErrorFlagAlerts({
          ...{
            error: true,
            needReload: true,
            errorMessage:
              response.data?.entry[0]?.code === 409
                ? "This alert has already been reviewed by another user"
                : "400 error",
          },
        })
      );
    } else {
      yield put(
        setErrorFlagAlerts({
          ...{
            error: false,
            needReload: true,
            errorMessage: "You don't have the permission to review this alert",
          },
        })
      );
    }
  } catch (error) {
    console.log("something went wrong!");
  }
}

export function* handlegetAllComments(action) {
  try {
    const response = yield call(requestgetAllComments, action.payload);
    const { data } = response;
    yield put(setAllComments(data));
  } catch (error) {
    console.log("something went wrong!");
  }
}

export function* handleGetAllReviewAlert(action) {
  try {
    const response = yield call(requestGetAllReviewAlert, action.payload);
    const { data } = response;
    yield put(setAllReviewAlert(data));
  } catch (error) {
    console.log("something went wrong!");
  }
}
