import { call, put } from "redux-saga/effects";

import {
  requestGetPatientCarePackage,
  requestGetAssociationSurveyAnswers,
  requestGetCareTeamById,
  requestUpdateAssociation,
  requestUpdateExistingCareTeam,
  requestTerminateOrCloseCarePlan,
} from "./associationRequest";
import {
  setPatientCarePackage,
  setAssociationSurveyAnswers,
  setAssociationCareTeam,
  setPatientCarePackageId,
  setPatientCareTeamId,
} from "./associationSlice";

import { openSnackbar } from "./../../toast/toastSlice";

import { setSpinner } from "../../shared/sharedSlice";

import { PATIENT_ASSOCIATION } from "./../../../utils/constants";
import { History } from "./../../../history";

export function* handleGetPatientCarePackage(action) {
  try {
    const response = yield call(requestGetPatientCarePackage, action.payload);
    if (response && Object.keys(response.data.entry).length > 0) {
      if (response.data.entry[0].id)
        yield put(setPatientCarePackageId(response.data.entry[0].id));
      if (response.data.entry[0].patientPlanDefinitionRequest)
        yield put(
          setPatientCarePackage(
            response.data.entry[0].patientPlanDefinitionRequest
          )
        );
    }
  } catch (error) {
    console.log("something went wrong!");
  }
}

export function* handleGetSurveyAnswers(action) {
  try {
    const response = yield call(
      requestGetAssociationSurveyAnswers,
      action.payload
    );
    const surveyData = response.data;
    yield put(
      setAssociationSurveyAnswers(JSON.parse(JSON.stringify(surveyData)))
    );
  } catch (error) {
    console.log("something went wrong!");
  }
}

export function* handleGetCareTeamById(action) {
  try {
    const response = yield call(requestGetCareTeamById, action.payload);
    const { entry } = response.data;
    const careTeam = Array.isArray(entry) && entry.length > 0 ? entry[0] : null;
    const careTeamId =
      Array.isArray(entry) && entry.length > 0 && entry[0].id
        ? entry[0].id
        : null;
    yield put(setAssociationCareTeam(careTeam));
    yield put(setPatientCareTeamId(careTeamId));
  } catch (error) {
    yield put(setAssociationCareTeam(null));
    yield put(setPatientCareTeamId(null));
    console.log("something went wrong!");
  }
}

export function* handleUpdateAssociation(action) {
  try {
    if (
      action.payload.carepackage.state === PATIENT_ASSOCIATION.STATUS.APPROVED
    ) {
      let str = action.payload.careteam.cpIdentifier.substring(
        0,
        action.payload.careteam.cpIdentifier.length - 5
      );
      let newCpId = str + "DRAFT";

      action.payload.careteam.cpIdentifier = newCpId;

      let response = yield call(
        requestUpdateExistingCareTeam,
        action.payload.careteam
      );
      if (response) {
        yield call(requestUpdateAssociation, action.payload.carepackage);
      }
      yield put(setSpinner(false));

      yield put(
        openSnackbar({
          snackbarTitle: "The care package was successfully approved.",
          icon: "",
          severity: "success",
        })
      );
    }

    if (
      action.payload.carepackage.state ===
      PATIENT_ASSOCIATION.STATUS.PENDING_APPROVAL
    ) {
      let response = yield call(
        requestUpdateExistingCareTeam,
        action.payload.careteam
      );
      if (response) {
        yield call(requestUpdateAssociation, action.payload.carepackage);
      }
      yield put(setSpinner(false));

      yield put(
        openSnackbar({
          snackbarTitle:
            "The care package is successfully submitted for approval.",
          icon: "",
          severity: "success",
        })
      );
    }

    if (
      action.payload.carepackage.state ===
      PATIENT_ASSOCIATION.STATUS.MODIFICATION_REQUESTED
    ) {
      yield call(requestUpdateAssociation, action.payload.carepackage);
      yield put(setSpinner(false));
      yield put(
        openSnackbar({
          snackbarTitle:
            "The care package was successfully sent back for modification",
          icon: "",
          severity: "success",
        })
      );
    }
    History.navigate("/patients");
  } catch (error) {
    console.log("something went wrong!");
    yield put(setSpinner(false));
    yield put(
      openSnackbar({
        snackbarTitle: "Something went wrong",
        icon: "",
        severity: "error",
      })
    );
  }
}

export function* handleUpdateExistingCareTeam(action) {
  try {
    yield call(requestUpdateExistingCareTeam, action.payload);
  } catch (error) {
    console.log("something went wrong!");
  }
}

export function* handleTerminateOrCloseCarePlan(action) {
  try {
    yield call(requestTerminateOrCloseCarePlan, action.payload);
    yield put(
      openSnackbar({
        snackbarTitle: `Care plan ${
          action.payload.type === "close" ? "closed" : "terminated"
        } successfully`,
        icon: "",
        severity: "success",
      })
    );
    setTimeout(() => {
      let str = action.payload.cpIdentifier.substring(
        0,
        action.payload.cpIdentifier.length - 5
      );
      let newCpId = "";
      if (action.payload.type === "close") {
        newCpId = str + "CMPLT";
      } else {
        newCpId = str + "TRMND";
      }
      History.navigate(
        `/patients/${action.payload.patientId}/package/${newCpId}`
      );
    }, 100);
  } catch (error) {
    console.log("something went wrong!");
  }
}
