import { call, put } from "redux-saga/effects";
import {
  setPatientCarePackagebyCategoryName,
  setPatientCarePackagebyCategoryNameforDraft,
  setcarePackageAllActivityListfromApi,
  setCarePackageActivityByName,
  setPatientCarePackagereActivityByType,
  setUnSavedCarePackageActivityByName,
  setCarePackageByCatLoaded,
  setSurveyList,
  setPatientSurveyByDate,
  setPatientSurveyByQuestions,
} from "./patientCarePackageSlice";
import {
  requestGetpatientCarePackageCategoryByName,
  requestpostCarePackageActivityList,
  requestGetCarePackageActivityByName,
  requestGetpatientCarePackageActivityByType,
  requestpostpatientCarePackageReviewSubmit,
  requestpostModifiedRequestpatientCarePackageReviewSubmit,
  requestgetPatientCarePackagebyPatientIdforModifying,
  requestGetSurveyList,
  requestGetPatientSurveyByDate,
  requestGetPatientSurveyByQuestions,
} from "./patientCarePackageRequest";
import { History } from "../../history";
import { openSnackbar } from "../toast/toastSlice";
import { setSpinner } from "../shared/sharedSlice";

export function* handleGetpatientCarePackageCategoryByName(action) {
  try {
    const response = yield call(
      requestGetpatientCarePackageCategoryByName,
      action.payload
    );
    const { entry } = response.data;

    if (response) {
      yield put(setPatientCarePackagebyCategoryName(entry));
      yield put(setCarePackageByCatLoaded(true));
    }
  } catch (error) {
    console.log("something went wrong!");
  }
}
export function* handlegetPatientCarePackagebyPatientIdforModifying(action) {
  try {
    const response = yield call(
      requestgetPatientCarePackagebyPatientIdforModifying,
      action.payload
    );

    if (response) {
      yield put(setPatientCarePackagebyCategoryName([response.data.entry[0]]));
      yield put(setCarePackageByCatLoaded(true));
    }
  } catch (error) {
    console.log("something went wrong!");
  }
}
export function* handlegetPatientCarePackagebyPatientIdforDraft(action) {
  try {
    const response = yield call(
      requestgetPatientCarePackagebyPatientIdforModifying,
      action.payload
    );

    if (response) {
      yield put(
        setPatientCarePackagebyCategoryNameforDraft([response.data.entry])
      );
      yield put(setCarePackageByCatLoaded(true));
    }
  } catch (error) {
    console.log("something went wrong!");
  }
}
export function* handleGetpatientCarePackageActivityByType(action) {
  try {
    const response = yield call(
      requestGetpatientCarePackageActivityByType,
      action.payload
    );
    const { entry } = response.data;
    if (response) {
      let obj = {
        deviceType: {},
        value: "",
      };

      for (var key of action.payload.params.values()) {
        obj.deviceType = key;
      }
      obj.value = entry;
      yield put(setPatientCarePackagereActivityByType(obj));
    }
  } catch (error) {
    console.log("something went wrong!");
  }
}
export function* handleGetCarePackageActivityList() {
  try {
    const response = yield call(requestpostCarePackageActivityList);
    const { entry } = response.data;
    yield put(setcarePackageAllActivityListfromApi(entry));
  } catch (error) {
    console.log("something went wrong!");
  }
}

export function* handleGetCarePackageActivityByName(action) {
  try {
    const response = yield call(
      requestGetCarePackageActivityByName,
      action.payload
    );
    const { entry } = response.data;
    if (response) {
      yield put(setCarePackageActivityByName({ ...entry }));
    }
  } catch (error) {
    console.log("something went wrong!");
  }
}
export function* handleGetCarePackageUnSavedActivityByName(action) {
  try {
    const response = yield call(
      requestGetCarePackageActivityByName,
      action.payload
    );
    const { entry } = response.data;
    if (response) {
      yield put(setUnSavedCarePackageActivityByName({ ...entry }));
    }
  } catch (error) {
    console.log("something went wrong!");
  }
}
export function* handlepostpatientCarePackageReviewSubmit(action) {
  try {
    yield call(requestpostpatientCarePackageReviewSubmit, action.payload);
    yield put(
      openSnackbar({
        snackbarTitle: "Your patient Care package have been sent for Approval",
        icon: "",
        severity: "success",
      })
    );
    yield put(setSpinner(false))
    History.navigate("/patients");
  } catch (error) {
    console.log("something went wrong!");
    yield put(setSpinner(false))
    yield put(
      openSnackbar({
        snackbarTitle: "Request Failed",
        icon: "",
        severity: "error",
      })
    );
  }
}
export function* handlesaveandExitpatientCarePackage(action) {
  try {
    yield call(requestpostpatientCarePackageReviewSubmit, action.payload);
    yield put(
      openSnackbar({
        snackbarTitle: "Your patient Care package have been saved Successfully",
        icon: "",
        severity: "success",
      })
    );
    History.navigate("/patients");
  } catch (error) {
    console.log("something went wrong!");
    yield put(
      openSnackbar({
        snackbarTitle: "Request Failed",
        icon: "",
        severity: "error",
      })
    );
  }
}

export function* handlepostpatientCarePackageReviewSubmitModifiedRequest(
  action
) {
  try {
    yield call(
      requestpostModifiedRequestpatientCarePackageReviewSubmit,
      action.payload
    );
    yield put(
      openSnackbar({
        snackbarTitle:
          "Your patient care package has been submitted for Approval successfully ",
        icon: "",
        severity: "success",
      })
    );
    yield put(setSpinner(false))
    History.navigate("/patients");
  } catch (error) {
    console.log("something went wrong!");
    yield put(setSpinner(false))
    yield put(
      openSnackbar({
        snackbarTitle: "Request Failed",
        icon: "",
        severity: "error",
      })
    );
  }
}

export function* handleGetSurveyList(action) {
  try {
    const response = yield call(requestGetSurveyList, action.payload);
    const { entry } = response.data;
    if (response) {
      yield put(setSurveyList([...entry]));
    }
  } catch (error) {
    console.log("something went wrong!");
  }
}

export function* handleGetPatientSurveyByDate(action) {
  try {
    const response = yield call(requestGetPatientSurveyByDate, action.payload);
    if (response) {
      yield put(setPatientSurveyByDate({ ...response.data }));
    }
  } catch (error) {
    console.log("something went wrong!");
  }
}

export function* handleGetPatientSurveyByQuestions(action) {
  try {
    const response = yield call(
      requestGetPatientSurveyByQuestions,
      action.payload
    );
    if (response) {
      yield put(setPatientSurveyByQuestions({ ...response.data }));
    }
  } catch (error) {
    console.log("something went wrong!");
  }
}
