import React, { useContext, useState, useEffect } from "react";
import "../amplify";
import { Hub } from "aws-amplify";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [isToken, setIsToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState("");

  useEffect(async () => {
    let token = await localStorage.getItem("token");
    let email = await localStorage.getItem("email");
    if (token && email) {
      setIsToken(true);
      setEmail(email);
    } else {
      setIsToken(false);
    }
  }, []);

  function login() {}

  function logout() {
    setIsToken(false);
  }

  function ssoSignIn(email) {
    setIsToken(true);
    setEmail(email);
  }

  function ssoSignout() {
    setIsToken(false);
  }

  const getCurrentUser = async () => {
    try {
      const email = await localStorage.getItem("userEmail");
      const userData = { email };

      if (email) {
        setCurrentUser(userData);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setCurrentUser(null);
      setLoading(true);
    }
  };

  const getCurrentUserTokenDetails = async () => {
    const userData = await localStorage.getItem("token");
    if (userData) {
      return userData;
    }
    return null;
  };

  useEffect(() => {
    getCurrentUser();
    Hub.listen("auth", getCurrentUser);
    return () => Hub.remove("auth", getCurrentUser);
  }, []);

  const value = {
    currentUser,
    login,
    logout,
    getCurrentUserTokenDetails,
    isToken,
    ssoSignIn,
    ssoSignout,
    email,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
