import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  patientCarePackage: null,
  surveyAnswers: null,
  patientCareTeam: null,
  editTemplate: null,
  notes: [],
  editPatientCarePackage: false,
  patientCarePackageId: null,
  patientCareTeamId: null
};

const patientAssociationSlice = createSlice({
  name: "patientAssociation",
  initialState: initialState,
  reducers: {
    getPatientCarePackage(id) { },
    setPatientCarePackage(state, action) {
      state.patientCarePackage = action.payload;
      let oldNotes = [];
      if (action.payload && action.payload.reviewer) {
        action.payload.reviewer.forEach((r) => {
          if (r.hasOwnProperty("notes")) {
            r.notes.forEach((n) => {
              oldNotes.push({ ...n, isNew: false });
            });
          }
        });
      }
      state.notes = oldNotes;
    },

    setEditPatientCarePackage(state, action) {
      state.editPatientCarePackage = action.payload
    },
    
    setPatientCarePackageId(state, action) {
      state.patientCarePackageId = action.payload
    },

    setPatientCareTeamId(state, action) {
      state.patientCareTeamId = action.payload
    },

    getAssociationSurveyAnswers(id) { },
    setAssociationSurveyAnswers(state, action) {
      state.surveyAnswers = action.payload;
    },

    getAssociationCareTeam(name) { },
    setAssociationCareTeam(state, action) {
      state.patientCareTeam = action.payload;
    },
    updateAssociationCareTeam(careTeam) { },

    addNote(state, action) {
      state.notes.push(action.payload);
    },
    updateAssociation(state, action) { },

    terminateOrCloseCarePlan() { },

    updateEditTemplate(state, action) {
      state.editTemplate = action.payload;
    },
  },
});

export const {
  getPatientCarePackage,
  setPatientCarePackage,
  setPatientCarePackageId,
  setPatientCareTeamId,
  getAssociationSurveyAnswers,
  setAssociationSurveyAnswers,
  getAssociationCareTeam,
  setAssociationCareTeam,
  updateAssociationCareTeam,
  addNote,
  updateAssociation,
  terminateOrCloseCarePlan,
  setEditPatientCarePackage,
  updateEditTemplate
} = patientAssociationSlice.actions;

export default patientAssociationSlice.reducer;
