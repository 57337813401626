import { fork, all } from "redux-saga/effects";

import { careTeamSaga } from "./careteam/careTeamSaga";
import { environmentalAssesmentSaga } from "./saga";
import { carePackageSaga } from "./carepackage/carePackageSaga";
import { patientCarePackageSaga } from "./patientCarePackage/patientCarePackageSaga";
import { patientsSaga } from "./patients/patientsSaga";
import { patientCareTeamSaga } from "./patients/enrollment/careTeam/careTeamSaga";
import { patientAssociationSaga } from './patients/association/associationSaga'
import { sharedSaga } from "./shared/sharedSaga";
import { appointmentsSaga } from "./appointments/appointmentsSaga";
import { dashboardSaga } from "./dashboard/dashboardSaga";
import { commentSaga } from "./patients/comments/commentsSaga";
import updatePageEveryFiveMinutes from './pagerefresh/updatePageEveryFiveMinutesHandlerSaga';

export default function* rootSaga() {
  yield all([
    ...careTeamSaga,
    ...carePackageSaga,
    ...environmentalAssesmentSaga,
    ...patientCarePackageSaga,
    ...patientCareTeamSaga,
    ...patientsSaga,
    ...sharedSaga,
    ...patientAssociationSaga,
    ...appointmentsSaga,
    ...dashboardSaga,
    ...commentSaga,
    fork(updatePageEveryFiveMinutes)

  ]);
}
