import { takeLatest, takeEvery } from "redux-saga/effects";
import {
  handleGetpatientCarePackageCategoryByName,
  handleGetCarePackageActivityList,
  handleGetCarePackageActivityByName,
  handleGetpatientCarePackageActivityByType,
  handlepostpatientCarePackageReviewSubmit,
  handlepostpatientCarePackageReviewSubmitModifiedRequest,
  handleGetCarePackageUnSavedActivityByName,
  handlegetPatientCarePackagebyPatientIdforModifying,
  handlegetPatientCarePackagebyPatientIdforDraft,
  handlesaveandExitpatientCarePackage,
  handleGetSurveyList,
  handleGetPatientSurveyByDate,
  handleGetPatientSurveyByQuestions
} from "./patientCarePackageHandler";
import {
  getCarePackageActivityByName,
  getcarePackageAllActivityListfromApi,
  getCarePackageUnSavedActivityByName,
  getCarePackageActivityByType,
  getPatientCarePackagebyCategoryName,
  postpatientCarePackageReviewSubmit,
  saveandExitpatientCarePackage,
  getPatientCarePackagebyPatientIdforModifying,
  getPatientCarePackagebyPatientIdforDraft,
  putpatientCarePackageReviewSubmitModifiedRequest,
  getSurveyList,
  getPatientSurveyByQuestions,
  getPatientSurveyByDate
} from "./patientCarePackageSlice";

export const patientCarePackageSaga = [
  takeEvery(
    getPatientSurveyByDate.type,
    handleGetPatientSurveyByDate
  ),
  takeEvery(
    getPatientSurveyByQuestions.type,
    handleGetPatientSurveyByQuestions
  ),
  takeEvery(
    getSurveyList.type,
    handleGetSurveyList
  ),
  takeEvery(
    getCarePackageActivityByName.type,
    handleGetCarePackageActivityByName
  ),
  takeEvery(
    getCarePackageActivityByType.type,
    handleGetpatientCarePackageActivityByType
  ),
  takeLatest(
    getcarePackageAllActivityListfromApi.type,
    handleGetCarePackageActivityList
  ),
  takeLatest(
    getPatientCarePackagebyCategoryName.type,
    handleGetpatientCarePackageCategoryByName
  ),
  takeLatest(
    getPatientCarePackagebyPatientIdforModifying.type,
    handlegetPatientCarePackagebyPatientIdforModifying
  ),
  takeLatest(
    getPatientCarePackagebyPatientIdforDraft.type,
    handlegetPatientCarePackagebyPatientIdforDraft
  ),

  takeLatest(
    getCarePackageUnSavedActivityByName.type,
    handleGetCarePackageUnSavedActivityByName
  ),
  takeLatest(
    postpatientCarePackageReviewSubmit.type,
    handlepostpatientCarePackageReviewSubmit
  ),
  takeLatest(
    saveandExitpatientCarePackage.type,
    handlesaveandExitpatientCarePackage
  ),
  takeLatest(
    putpatientCarePackageReviewSubmitModifiedRequest.type,
    handlepostpatientCarePackageReviewSubmitModifiedRequest
  ),
];
