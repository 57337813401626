import { createSlice } from "@reduxjs/toolkit";

const sharedSlice = createSlice({
  name: "shared",
  initialState: {
    carePackageCategory: [],
    allSpecialties: [],
    allPractitioners: [],
    loadedPractitionersSpecialty: [],
    onboardPractitionerError: false,
    onboardPractitionerResponse: null,
    spinner: false,
  },
  reducers: {
    getCarePackageCategory() {},
    setOnBoardPractitioners(state, payload) {
      state.onboardPractitionerError = false;
      state.onboardPractitionerResponse = null;
    },
    setOnboardPractionerResponse(state, action) {
      state.onboardPractitionerResponse = action.payload;
    },
    setOnboardPractitionerError(state, action) {
      state.onboardPractitionerError = action.payload;
    },
    setvalidatePractitioners(payload) {},
    setResendvalidatePractitioners(payload) {},
    CallAlertRenderAPIsOnLoad(payload) {},
    setCarePackageCategory(state, action) {
      state.carePackageCategory = action.payload;
    },
    getSpecialties() {},
    CallLoginWithSSO(payload) {},
    setSpecialtiesShared(state, action) {
      state.allSpecialties = action.payload;
    },
    getPractitioners(specialty) {},
    setPractitionersShared(state, action) {
      let temp = [...state.allPractitioners, ...action.payload.entry];
      state.allPractitioners = _.uniq(temp, "practitionerId");
      state.loadedPractitionersSpecialty = [
        ...state.loadedPractitionersSpecialty,
        action.payload.specialty,
      ];
    },
    setSpinner(state, action) {
      state.spinner = action.payload;
    },
  },
});

export const {
  setResendvalidatePractitioners,
  setvalidatePractitioners,
  setOnBoardPractitioners,
  getCarePackageCategory,
  setCarePackageCategory,
  getSpecialties,
  setSpecialtiesShared,
  getPractitioners,
  setPractitionersShared,
  CallLoginWithSSO,
  setOnboardPractitionerError,
  setOnboardPractionerResponse,
  setSpinner,
  CallAlertRenderAPIsOnLoad,
} = sharedSlice.actions;

export default sharedSlice.reducer;
