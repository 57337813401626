import { takeLatest } from "redux-saga/effects";

import {
  getPatientCarePackage,
  getAssociationSurveyAnswers,
  getAssociationCareTeam,
  updateAssociation,
  updateAssociationCareTeam,
  terminateOrCloseCarePlan,
} from "./associationSlice";

import {
  handleGetCareTeamById,
  handleGetPatientCarePackage,
  handleGetSurveyAnswers,
  handleUpdateAssociation,
  handleUpdateExistingCareTeam,
  handleTerminateOrCloseCarePlan,
} from "./associationHandler";

export const patientAssociationSaga = [
  takeLatest(getPatientCarePackage.type, handleGetPatientCarePackage),
  takeLatest(getAssociationSurveyAnswers.type, handleGetSurveyAnswers),
  takeLatest(getAssociationCareTeam.type, handleGetCareTeamById),
  takeLatest(updateAssociation.type, handleUpdateAssociation),
  takeLatest(updateAssociationCareTeam.type, handleUpdateExistingCareTeam),
  takeLatest(terminateOrCloseCarePlan.type, handleTerminateOrCloseCarePlan),
];
