import { useState } from "react";
import { alertHigh } from "../../assets/icons/index";
import Modal from "react-bootstrap/Modal";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

const PermissionModal = ({showModal}) => {
  const [showPermissionModal, handlePermissionModal] = useState(showModal);
  const onClose = () => {
    localStorage.setItem('insufficientPermissions', '0');
    handlePermissionModal(false);
  }

  return (
    <div className="PermissionModal">
      <Modal show={showPermissionModal} centered size="md">
        <Modal.Header className="border-0 pb-0">
          <Modal.Title  className="modal-header border-0 p-3  pb-2">
            
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4 pt-2">
          <Container>
          <Row>
            <Col sm={2} style={{'text-align': 'center'}}>
              {" "}
              <img style={{'height': '50px'}} src={alertHigh} alt="group" />
            </Col>
            <Col sm={10}>
              <Row>
                <Col sm={12}>
                  <span style={{'font-weight': 'solid', 'font-size': '32px'}}>Insufficient Permission</span>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col sm={12}>
                  <span>
                    <p style={{'padding':'10px','font-size': '16px','font-weight': '800'}}>
                      It seems like you do not have permissions to access few items on some pages.
                    </p>
                </span>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <span className="p-2" style={{'font-size': '16px','font-weight': '800'}}>
                    Please contact admin to make it resolved.
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end">
          <>
          <div>
            <button
              variant="secondary"
              className="btn gh-btn-primary"
              onClick={() => {
                onClose();
              }}
            >
              Close
            </button>
          </div>
          </>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PermissionModal;
