import API from "../lib/api";
import {
  EMR_BASE_URL,
  ENROLLMENT_BASE_URL,
  SURVEYS_BASE_URL,
  PATIENT_DEVICES_BASE_URL,
  CARE_PACKAGE_BASE_URL,
  EMR_PATIENT_BASE_URL,
} from "./../utils/constants";
import { contructURLParamsIfActualAPI } from "./../utils/utils";
const searchPatientURL = `${EMR_PATIENT_BASE_URL}/SearchPatientFromEmr`;
const searchPatientMonitoringURL = `${EMR_PATIENT_BASE_URL}/PatientMonitoring`;
const sendconsentURL = `${ENROLLMENT_BASE_URL}/PatientEnrollment`;
const getEnrollmentStatusUrl = `${ENROLLMENT_BASE_URL}/EnrollmentStatus`;
const searchPatientInTheSystemURL = `${CARE_PACKAGE_BASE_URL}/searchPatient`;
const getEnrollmentResendEmailUrl = `${ENROLLMENT_BASE_URL}/ResendEmail`;
const getEnrollmentAcessSafetyQuestionUrl = `${SURVEYS_BASE_URL}/Questionnaire?name=EnvironmentalAccessAndSafety`;

const postEnrollmentAcessSafetyURl = `${SURVEYS_BASE_URL}/QuestionnaireResponse`;
const getAnswerEnrollmentAcessSafetyUrl = `${SURVEYS_BASE_URL}/QuestionnaireResponse`;

const getPatientProfileVitalUrl = `${EMR_BASE_URL}/fetchPatientVitals`;
const getPatientLabObservationUrl = `${EMR_BASE_URL}/fetchPatientClinicalData`;
const ConditionsBaseURL = `${EMR_BASE_URL}/fetchPatientConditions`;
const AllergiesBaseURL = `${EMR_PATIENT_BASE_URL}/allergies`;
const getPatientFHIRpatientIdURL = `${EMR_PATIENT_BASE_URL}/SearchPatientById`;
const getDeviceReadingUnderCarePackageURL = `${PATIENT_DEVICES_BASE_URL}/availableMetrics`;
const getDeviceReadingURL = `${PATIENT_DEVICES_BASE_URL}/deviceReadings`;

export const PatientService = {
  searchPatient: async function (params) {
    return API.get(searchPatientURL, {
      params: params,
      headers: {
        "Emr-Format": "R4",
      },
    })
      .then((response) => {
        if (response.status != 200) {
          this.handleError(response);
        }
        return response;
      })

      .catch((error) => {
        this.handleError(error);
      });
  },
  searchPatientMonitoring: async function (params) {
    return API.get(searchPatientMonitoringURL, {
      params: params,
      headers: {
        "Emr-Format": "R4",
      },
    })
      .then((response) => {
        if (response.status != 200) {
          this.handleError(response);
        }
        return response;
      })

      .catch((error) => {
        this.handleError(error);
      });
  },
  sendconsent: function (data) {
    return API.post(sendconsentURL, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },
  getEnrollmentStatus: async function (params) {
    return API.get(getEnrollmentStatusUrl, params)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        this.handleError(error);
      });
  },
  resendEmail: function (patientIdvalue) {
    let data = {
      patientId: patientIdvalue,
    };
    return API.post(getEnrollmentResendEmailUrl, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        this.handleError(error);
      });
  },
  searchPatientInTheSystem: async function (params) {
    console.debug(params);
    return API.get(searchPatientInTheSystemURL, {
      headers: {
        "Emr-Format": "R4",
      },
    })
      .then((response) => {
        if (response.status != 200) {
          this.handleError(response);
        }
        return response.data;
      })

      .catch((error) => {
        this.handleError(error);
      });
  },
  getEnrollmentAcessSafetyQuestion: async function () {
    return API.get(getEnrollmentAcessSafetyQuestionUrl)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        this.handleError(error);
      });
  },
  getEnrollmentAcessSafetyAnswer: async function (params) {
    return API.get(getAnswerEnrollmentAcessSafetyUrl, params)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        this.handleError(error);
      });
  },
  postEnrollmentAcessSafety: function (data, cpId) {
    let str = cpId.substring(0, cpId.length - 5);
    let newCpId = str + "DRAFT";
    let surveyData = JSON.parse(JSON.stringify(data));
    surveyData.cpIdentifier = newCpId;
    return API.post(postEnrollmentAcessSafetyURl, surveyData)
      .then((response) => {
        return response.data;
      })
      .catch(() => {});
  },
  getPatientProfileVital: function (datavalue) {
    let data = {
      patientId: datavalue.patientId,
      viewType: datavalue.viewType,
    };
    return API.get(getPatientProfileVitalUrl, { params: data })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        this.handleError(error);
      });
  },
  getPatientProfileLabOservation: function (datavalue) {
    let data = {
      patientId: datavalue.patientId,
      viewType: datavalue.viewType,
    };
    return API.get(getPatientLabObservationUrl, { params: data })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        this.handleError(error);
      });
  },
  getPatientCondition: function (datavalue) {
    let data = {
      patientId: datavalue.patientId,
      viewType: datavalue.viewType,
    };
    return API.get(ConditionsBaseURL, { params: data })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        this.handleError(error);
      });
  },
  getAllergiesCondition: function (datavalue) {
    let data = {
      patientId: datavalue,
    };
    return API.get(AllergiesBaseURL, { params: data })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        this.handleError(error);
      });
  },
  getPatientFHIRpatientId: function (PatientId) {
    let data = {
      patientId: PatientId,
    };
    return API.get(getPatientFHIRpatientIdURL, { params: data })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        this.handleError(error);
      });
  },
  getDeviceReadingUnderCarePackage: async function (params) {
    return API.get(
      getDeviceReadingUnderCarePackageURL,
      contructURLParamsIfActualAPI(params, false)
    )
      .then((response) => {
        if (response.status != 200) {
          this.handleError(response);
        }

        return response.data;
      })
      .catch((error) => {
        console.log(
          "Please start the JSON server by the command npm run start:json-server or check the API"
        );
        this.handleError(error);
      });
  },
  getDeviceReading: async function (params) {
    return API.get(
      getDeviceReadingURL,
      contructURLParamsIfActualAPI(params, false)
    )
      .then((response) => {
        if (response.status != 200) {
          this.handleError(response);
        }
        return response.data;
      })
      .catch((error) => {
        console.log(
          "Please start the JSON server by the command npm run start:json-server or check the API"
        );
        this.handleError(error);
      });
  },
  handleError: function (error) {
    return "Something Went Wrong . Please Reload the Page";
  },
};

export default PatientService;
