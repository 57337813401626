import { MessageInput, useChatContext } from "stream-chat-react";
import "stream-chat-react/dist/css/index.css";
import "./CustomMessageInput.scss";
import { optionalImage } from "../../../assets/icons/index";

const CustomMessageInput = () => {
  const { channel } = useChatContext();

  return (
    <div>
      {!channel?.data?.frozen && <MessageInput />}
      {channel?.data?.frozen && (
        <p className="comp">
          This chat has been disabled because this care package has been
          completed
        </p>
      )}
      {!channel?.data?.frozen && (
        <span className="att-size">
          <img
            width="70%"
            className="mr-2 opt-image"
            src={optionalImage}
            alt="group"
          />
          The maximum size of the attachment should be less than 100MB
        </span>
      )}
    </div>
  );
};

export default CustomMessageInput;
