import { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { Click } from "../../assets/icons/index";
import {
  ChannelHeader,
  Chat,
  MessageList,
  Window,
  Channel,
  ChannelList,
} from "stream-chat-react";
import "stream-chat-react/dist/css/index.css";
import "./ChatWindow.scss";
import CustomFileUploadIcon from "../../components/Chat/FileUploadIcon/CustomFileUploadIcon";
import CustomMessageInput from "../../components/Chat/CustomMessageInput/CustomMessageInput";
import CustomSearchResult from "../../components/Chat/CustomSearchResult/CustomSearchResult";
import { urmService } from "../../services/urmService";
import { APP_SERVICES, PERMISSIONS_SET } from "../../utils/constants";
import { Permissions } from "../../components/Common/Permissions";
import { getUserPermissions } from "./../../store/appointments/appointmentsSlice";
import { useAuth } from "../../contexts/AuthContext";

const ChatWindow = ({
  perms,
  showModal = false,
  chatClient,
  OnClose,
  userId,
}) => {
  const myPermissions = perms ? perms : {};

  const filters = { members: { $in: [userId] } };
  const sort = { last_message_at: -1 };

  const { currentUser, email } = useAuth();
  const dispatch = useDispatch();
  useEffect(() => {
    const bringPermsissions = localStorage.getItem("UrmServiceProvider");
    if (currentUser && bringPermsissions === "1") {
      const userEmail = currentUser?.email ? currentUser.email : email;
      dispatch(getUserPermissions({ email: userEmail }));
      localStorage.setItem("UrmServiceProvider", "0");
    }
  }, [currentUser]);

  const customSearchFunction = async (props, event, client) => {
    const { setResults, setSearching, setQuery } = props;
    const value = event.target.value;

    const filters = {
      name: { $autocomplete: value ? value : "" },
      members: { $in: [client.user.id] },
    };

    setSearching(true);
    setQuery(value);
    if (value) {
      const channels = await client.queryChannels(filters);
      setResults(channels);
    }
    setSearching(false);
  };

  return (
    <Modal
      dialogClassName="modal-chat-window"
      centered
      contentClassName="modal-chat-content"
      show={showModal}
      size="sm"
      onHide={() => OnClose()}
    >
      <Modal.Header className="border-0 pb-0" closeButton>
        <Modal.Title className="fw-bold">Patient Chat</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Chat client={chatClient} theme={"messaging light"}>
          <ChannelList
            additionalChannelSearchProps={{
              searchFunction: (params, event) => {
                return customSearchFunction(params, event, chatClient);
              },
              SearchResultItem: CustomSearchResult,
              placeholder: "Search Patients",
            }}
            filters={filters}
            sort={sort}
            setActiveChannelOnMount={false}
            showChannelSearch
          />
          <div>
            <span>
              <img src={Click} className="click-icon me-2" alt="click-icon" />
            </span>
            <p className="default-text">
              Click on recepient to view thier chat
            </p>
          </div>
          <Channel FileUploadIcon={CustomFileUploadIcon}>
            <Window>
              <ChannelHeader />
              <MessageList />
              <Permissions
                id="chat"
                allow={
                  myPermissions &&
                  urmService.can(
                    PERMISSIONS_SET.EDIT,
                    APP_SERVICES.MESSAGING,
                    myPermissions
                  )
                }
              >
                <CustomMessageInput />
              </Permissions>
            </Window>
          </Channel>
        </Chat>
      </Modal.Body>
    </Modal>
  );
};

export default ChatWindow;
