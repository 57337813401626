import API from "../../lib/api";
import {
  ENROLLMENT_BASE_URL,
  CARE_PACKAGE_BASE_URL,
  ONBOARDING_BASE_URL,
  AUTH_URL,
  EMR_PATIENT_BASE_URL,
} from "./../../utils/constants";

const OnBoardPractitioners = `${ONBOARDING_BASE_URL}/OnboardPractitioner`;
const validatePractitioners = `${CARE_PACKAGE_BASE_URL}/ValidatePractitionerInvitationCode`;
const resendvalidatePractitioners = `${CARE_PACKAGE_BASE_URL}/ResendPractitionerInvitationCode`;
const patientTimeZone = `${EMR_PATIENT_BASE_URL}/timeZone`;

export function requestGetCarePrograms() {
  return API.get(`${ENROLLMENT_BASE_URL}/CarePrograms`);
}

export function requestGetSpecialtiesShared() {
  return API.get(`${CARE_PACKAGE_BASE_URL}/Specialities`);
}

export function requestOnBoardPractitioners(body) {
  return API.post(OnBoardPractitioners, body);
}

export function sendPatientTimeZone(body) {
  return API.post(patientTimeZone, body);
}

export function requestvalidatePractitioners(body) {
  return API.post(validatePractitioners, body);
}

export function requestresendvalidatePractitioners(body) {
  return API.post(resendvalidatePractitioners, body);
}

export function requestGetPractitionersShared(specialty) {
  return API.get(
    `${CARE_PACKAGE_BASE_URL}/Practitioners?specialty=${specialty}`
  );
}

export function loginWithSSO(cred) {
  return API.post(`${AUTH_URL}/Login`, cred);
}
