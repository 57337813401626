import { createSlice } from "@reduxjs/toolkit";

const commentsSlice = createSlice({
  name: "comments",
  initialState: {
    AllComments: [],
    FlagComments: {},
    ErrorFlagAlerts: { error: false, errorMessage: "", needReload: false },
    AllReviewAlert: [],
  },
  reducers: {
    ReviewedAlert(body) {},
    createGeneralComment(body) {},
    getAllComments(params) {},
    setAllComments(state, action) {
      state.AllComments = action.payload;
    },
    setAllReviewAlert(state, action) {
      state.AllReviewAlert = action.payload;
    },
    GetAllReviewAlert(params) {},
    setErrorFlagAlerts(state, action) {
      state.ErrorFlagAlerts = action.payload;
    },
    setCommentsSuccess(state, action) {
      state.FlagComments = action.payload;
    },
  },
});

export const {
  createGeneralComment,
  ReviewedAlert,
  getAllComments,
  setAllComments,
  setCommentsSuccess,
  setErrorFlagAlerts,
  GetAllReviewAlert,
  setAllReviewAlert,
} = commentsSlice.actions;

export default commentsSlice.reducer;
