import { delay, put, call } from "redux-saga/effects";
import store from "../../store";
import {
  setAllPatients,
  setAllPatientList,
  setallAlertCount,
} from "./../patients/patientsSlice";
import { requestGetSurveyList } from "./../patientCarePackage/patientCarePackageRequest";
import { setSurveyList } from "./../patientCarePackage/patientCarePackageSlice";
import {
  requestGetDeviceReadingList,
  requestGetAllPatients,
  requestALlAlert,
  requestGetAllPatientsWithParams,
} from "./../patients/patientsRequest";
import { requestGetPatientCarePackage } from "./../patients/association/associationRequest";
import { setDeviceReadingListGlobal } from "./../patients/patientsSlice";
import { REFRESH_INTERVAL } from "./../../utils/constants";
import { handleSetNonClinicalAlertIndicator } from "../shared/sharedHandler";
import { decideWarningKey } from "./../../utils/utils";

const checkIfPackageNowOrPrevActive = async (cpId) => {
  const response = await requestGetPatientCarePackage(cpId);

  let patientCarePackage;
  if (response && Object.keys(response.data.entry).length > 0) {
    if (response.data.entry[0].patientPlanDefinitionRequest) {
      patientCarePackage = response.data.entry[0].patientPlanDefinitionRequest;
    }
  }
  const actValue = patientCarePackage?.reviewer.some(
    (e) => e.current_state === "Active"
  );

  return actValue;
};

export function* updatePageEveryFiveMinutes() {
  while (true) {
    yield delay(REFRESH_INTERVAL);
    const rpmdashboardUrl = localStorage.getItem("patientUrl");
    if (window.location.pathname.includes("patients/")) {
      // inside patient details page
      const params = {
        patientId: window.location.pathname.split("/")[2],
        cpId: window.location.pathname.split("/")[4],
      };

      let hasSurveyData = false;
      let hasDeviceData = false;

      const packageNowOrPrevActive = yield call(
        checkIfPackageNowOrPrevActive,
        window.location.pathname.split("/")[4]
      );
      if (packageNowOrPrevActive) {
        hasSurveyData = true;
        hasDeviceData = true;
      }

      if (hasSurveyData && hasDeviceData) {
        // survey response
        try {
          const surveyResponse = yield call(requestGetSurveyList, params);
          const { entry } = surveyResponse.data;
          if (entry) {
            yield put(setSurveyList([...entry]));
          }
        } catch (error) {
          yield put(setSurveyList([]));
          console.log("something went wrong!");
        }

        //device readings

        try {
          const deviceReadingList = yield call(
            requestGetDeviceReadingList,
            params
          );
          const { data } = deviceReadingList;
          if (data) {
            yield put(setDeviceReadingListGlobal([...data]));
          }
        } catch (error) {
          console.log("something went wrong!");
          yield put(setDeviceReadingListGlobal([]));
        }
      }
    }

    if (window.location.pathname.includes("dashboard")) {
      // inside rpm dashboard
      let response;
      try {
        response = yield call(requestGetAllPatientsWithParams, rpmdashboardUrl);
        const { data } = response;
        yield put(setAllPatients({ ...data }));
      } catch (error) {
        console.log("something went wrong!");
        yield put(setAllPatients(null));
      }

      try {
        const res = yield call(
          requestALlAlert,
          `patientId=${response.data.entry[0].id}&cpIdentifier=${response.data.entry[0].cpIdentifier}&status=in-progress`
        );
        yield put(setallAlertCount(res.data.totalActiveAlertsInSystem));
      } catch (error) {
        console.log("something went wrong!");
        yield put(setallAlertCount(null));
      }

      try {
        const currentState = store.getState();
        const practitioner_role_id =
          currentState.appointments.currentUserProfile.practitionerDetail
            .practitioner_role_id;

        const payl = {
          id: practitioner_role_id,
          isNonClinicalAlert: true,
        };

        const responsePatientList = yield call(requestGetAllPatients, payl);
        const { data: entries } = responsePatientList;
        const obj = { ...entries };
        yield put(setAllPatientList(obj));

        handleSetNonClinicalAlertIndicator(obj, decideWarningKey);
      } catch (error) {
        console.log("something went wrong!");
      }
    }
  }
}
