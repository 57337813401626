/* eslint-disable no-unused-vars */
import { call, takeLatest, put, fork, all } from "redux-saga/effects";
import { openSnackbar } from "../store/toast/toastSlice";
// import Axios from "axios";
import {
  fetchData,
  savePatientCondition,
  savePatientAllergies,
  savePatientVital,
  fetchDataEnrollmentstatus,
  savePatientLabObservation,
  getSucess,
  getError,
  saveEnrollmentAcessSafetyQuestion,
  getEnrollmentAssesmentQuestion,
  searchFhirPatientId,
  getpatientFHIRId,
} from "./reducer";
import { sagaActions } from "./sagaactions";
// import axios from "axios";
import { PatientService } from "../services/patients-service";
import PatientsUtil from "../utils/patientsUtils";
import { History } from "./../history";

export function* fetchDataSaga(parameters) {
  try {
    let result = yield call(() =>
      PatientService.searchPatient(parameters.payload)
    );
    yield put(fetchData(result.data));
    if (parameters.test) {
      parameters.test();
    }
  } catch (e) {
    yield put({ type: "TODO_FETCH_FAILED" });
  }
}
export function* fetchDataSagaEnrollmentConsent(parameters) {
  try {
    let result = yield call(() =>
      PatientService.sendconsent(parameters.payload)
    );
    if (
      result &&
      result?.entry &&
      Array.isArray(result.entry) &&
      result.entry.length > 0
    ) {
      if (result.entry[0]?.patientId) {
        History.navigate(`/patients/${result.entry[0]?.patientId}/package/0`);
      }
    }
  } catch (e) {
    let errMsg = "Error:: Please check input details";
    //email already exists
    if (e.toString().includes("409")) {
      errMsg =
        "Error:: Email already exists, please try with different email id";
    }
    yield put(
      openSnackbar({
        snackbarTitle: errMsg,
        icon: "",
        severity: "error",
      })
    );
  }
}
export function* fetchDataSagaEnrollmentstatus(parameters) {
  try {
    let result = yield call(() =>
      PatientService.getEnrollmentStatus(parameters.payload)
    );
    yield put(fetchDataEnrollmentstatus(result));
    if (parameters.callback) {
      parameters.callback();
    }
  } catch (e) {
    yield put({ type: "TODO_FETCH_FAILED" });
  }
}
export function* FETCH_DATA_SAGAResendEmail(parameters) {
  try {
    let result = yield call(() =>
      PatientService.resendEmail(parameters.patientsResendmailFhirId)
    );
  } catch (e) {
    yield put({ type: "TODO_FETCH_FAILED" });
  }
}
export function* FETCH_DATA_SAGAEnvironmentAcessSafety(parameters) {
  try {
    let resultQuestion = yield call(() =>
      PatientService.getEnrollmentAcessSafetyQuestion()
    );
    let resultAnswer = yield call(() =>
      PatientService.getEnrollmentAcessSafetyAnswer(parameters.payload)
    );
    let {
      objAccessSafety,
      objServiceSupport,
      objAnswerAcessSafety,
      objAnswerServiceSupport,
    } = PatientsUtil.storeEnvironmentAcessSafety(
      resultQuestion.data,
      resultAnswer.data
    );
    yield put(
      saveEnrollmentAcessSafetyQuestion({
        objAccessSafety: objAccessSafety,
        objServiceSupport: objServiceSupport,
        objAnswerServiceSupport: objAnswerServiceSupport,
        objAnswerAcessSafety: objAnswerAcessSafety,
      })
    );
    if (resultAnswer) {
      yield put(getSucess(false));
    }
  } catch (e) {
    yield put(getError(true));
  }
}

export function* FETCH_DATA_SAGAEnvironmentAcessSafetyPost(parameters) {
  try {
    let result = yield call(() =>
      PatientService.postEnrollmentAcessSafety(
        parameters.payload,
        parameters.payload.cpIdentifier
      )
    );
    if (result) {
      yield call(parameters.onSuccess);
    }
  } catch (e) {
    yield put({ type: "TODO_FETCH_FAILED" });
  }
}
export function* fetchDataForPatientProfile(paramas) {
  try {
    yield all([
      fork(
        requestAndPut,
        [PatientService.getPatientProfileVital, paramas.payload],
        "firstRequestActionCreator"
      ),
      fork(
        requestAndPut,
        [PatientService.getPatientProfileLabOservation, paramas.payload],
        "secondRequestActionCreator"
      ),
      fork(
        requestAndPut,
        [PatientService.getPatientCondition, paramas.payload],
        "thirdRequestActionCreator"
      ),
      fork(
        requestAndPut,
        [PatientService.getAllergiesCondition, paramas.payload.patientId],
        "SeventhRequestActionCreator"
      ),
    ]);
  } catch (e) {
    // error handling
    // yield put({ type: ON_PAGE_MOUNT_ERROR, payload: e });
  }
}
function* requestAndPut(parameter, actionCreator1) {
  yield put(savePatientVital({}));
  yield put(savePatientCondition({}));
  yield put(savePatientAllergies({}));
  let result;
  if (parameter[1]) {
    result = yield call(() => parameter[0](parameter[1]));
  } else {
    result = yield call(() => parameter[0]());
  }
  if (actionCreator1 === "firstRequestActionCreator") {
    let vitalobj = PatientsUtil.storeVital(result);
    yield put(savePatientVital(vitalobj));
  }
  if (actionCreator1 === "secondRequestActionCreator") {
    let clinicaldatanobj = PatientsUtil.storeClinicalData(result);
    yield put(savePatientLabObservation(clinicaldatanobj));
  }
  if (actionCreator1 === "thirdRequestActionCreator") {
    let Conditionobj = PatientsUtil.storeCondition(result);
    yield put(savePatientCondition(Conditionobj));
  }
  if (actionCreator1 === "SeventhRequestActionCreator") {
    let patientAllergiesobj = PatientsUtil.allergiesCondition(result);
    yield put(savePatientAllergies(patientAllergiesobj));
  }
}
function* savePatientVitalCard(result) {
  yield put(savePatientVital(result));
}
const firstRequest = (callback) => {
  callback();
};
export function* fetchsearchFhirPatientId(action) {
  try {
    let result = yield call(() =>
      PatientService.getPatientFHIRpatientId(action.payload)
    );
    if (result) {
      yield put(searchFhirPatientId(result.data.fhirId));
    }
  } catch (e) {
    yield put({ type: "TODO_FETCH_FAILED" });
  }
}

export const environmentalAssesmentSaga = [
  takeLatest(sagaActions.FETCH_DATA_SAGA, fetchDataSaga),
  takeLatest(
    sagaActions.FETCH_DATA_SAGAEnrollment,
    fetchDataSagaEnrollmentConsent
  ),
  takeLatest(
    sagaActions.FETCH_DATA_SAGAEnrollmentStatus,
    fetchDataSagaEnrollmentstatus
  ),
  takeLatest(
    sagaActions.FETCH_DATA_SAGAResendEmail,
    FETCH_DATA_SAGAResendEmail
  ),
  takeLatest(
    sagaActions.FETCH_DATA_SAGAEnvironmentAcessSafety,
    FETCH_DATA_SAGAEnvironmentAcessSafety
  ),
  takeLatest(
    sagaActions.FETCH_DATA_SAGAEnvironmentAcessSafetyPost,
    FETCH_DATA_SAGAEnvironmentAcessSafetyPost
  ),
  takeLatest(
    sagaActions.Fetch_Data_Saga_PatientPageLoad_CardApi,
    fetchDataForPatientProfile
  ),
  takeLatest(
    sagaActions.saveReduxPatientPageLoad_CardApi,
    savePatientVitalCard
  ),
  takeLatest(
    getEnrollmentAssesmentQuestion.type,
    FETCH_DATA_SAGAEnvironmentAcessSafety
  ),
  takeLatest(getpatientFHIRId.type, fetchsearchFhirPatientId),
];
