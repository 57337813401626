const mockJSON = require("../db.json");
import { AccessSafetyobjService } from "../pages/Patients/PatientDetails/Enrollment/Tabs/CarePackage/PatientEnrollment/AcessSafetyParse";
import { sagaActions } from "../store/sagaactions";
import _ from "lodash";
import {
  modifyActionList,
  modifyCareAreaList,
  modifyCareGoalsList,
  doRemoveSpacesFromObj,
} from "./transformers/patientCarePackage";
import { saveandExitpatientCarePackage } from "../store/patientCarePackage/patientCarePackageSlice";
import moment from "moment";

export function getName(name) {
  if (Array.isArray(name) && name.length > 0) {
    return name[0].text;
  }
  return "";
}

export const createDynamicStyles = (val) => {
  val = val.toString();

  return {
    pl: {
      paddingLeft: val,
    },
    pt: {
      paddingTop: val,
    },
    pr: {
      paddingRight: val,
    },
    pb: {
      paddingBottom: val,
    },
    ml: {
      marginLeft: val,
    },
    mt: {
      marginTop: val,
    },
    mr: {
      marginRight: val,
    },
    mb: {
      marginBottom: val,
    },
  };
};

export const contructURLParamsIfActualAPI = (params, USE_MOCKDATA) => {
  let parameters;
  if (!USE_MOCKDATA) {
    // for the actual api call
    let urlParams = new URLSearchParams();
    for (let key in params) {
      urlParams.append(key, params[key]);
    }
    parameters = {
      params: urlParams,
    };
    // for the actual api call
  } else {
    // for mock api call
    parameters = params;
    // for mock api call
  }

  return parameters;
};

const getMockDataFromParams = (endPoint, params) => {
  // simulate the actual API response rules here
  if (params.questionnaireId) {
    const res = mockJSON[endPoint].filter(
      (e) => e.questionnaireId === params.questionnaireId
    );
    return res.length > 0 ? res[0] : res;
  } else return mockJSON[endPoint];
};

export const mockAPI = () => {
  const obj = {
    get: function (url, params) {
      return new Promise((resolve) => {
        const endPoint = url.split("/").slice(-1)[0];

        setTimeout(() => {
          switch (endPoint) {
            case endPoint:
              const data = getMockDataFromParams(endPoint, params);
              if (data) {
                resolve({ status: 200, data: [data] });
              } else {
                resolve({ status: 404, message: "Not Found" });
              }
              break;
            default:
              resolve({ status: 400, message: "Bad Request" });
          }
        }, 100);
      });
    },
  };

  return obj;
};

export const checkIfValidURL = (url) => {
  const hasStringAfterFinalDot = (url) => {
    // Get the index of the last dot in the URL.
    const lastDotIndex = url.lastIndexOf(".");

    // If there is no dot in the URL, return false.
    if (lastDotIndex === -1) {
      return false;
    }

    // Check if the character after the last dot is a letter or a number.
    const nextCharacter = url[lastDotIndex + 1];
    return nextCharacter?.match(/[a-zA-Z0-9]/) !== null;
  };

  var expression =
    /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]+)?/gi;

  // regex to check valid url
  const regex = new RegExp(expression);
  // regex to check starting
  const regex2 = /^(?:(http|https):\/\/|ftp:\/\/|www\.)/i;

  if (url === "") {
    return true;
  }

  if (regex.test(url) && regex2.test(url) && hasStringAfterFinalDot(url)) {
    return true;
  }
  return false;
};

export const doMutateFinalObj = (postTemplateobj) => {
  let patientCarePackageT = { ...postTemplateobj };
  const newActionArr = postTemplateobj.action
    .filter((e) => {
      if (Object.keys(e).length === 0) {
        return false;
      }
      return true;
    })
    .map((e) => {
      return {
        ...e,
        additionalPatientResource: e.additionalPatientResource?.map((e1) => {
          if (typeof e1 !== "string") {
            return {
              title: e1?.title,
              url: e1.url?.trim(),
            };
          }

          return {
            title: "",
            url: e1.trim(),
          };
        }),
      };
    });
  patientCarePackageT.action = newActionArr;

  return patientCarePackageT;
};

export const doChangesToSurvey = (updateapprovedCarePackageaction) => {
  let updateapprovedCarePackageactionTemp = updateapprovedCarePackageaction;
  for (let j = 0; j < updateapprovedCarePackageactionTemp.length; j++) {
    updateapprovedCarePackageactionTemp[j].activity_description =
      updateapprovedCarePackageactionTemp[j].reason;
    if (updateapprovedCarePackageactionTemp[j].activityType === "Survey") {
      for (
        let i = 0;
        i < updateapprovedCarePackageactionTemp[j].survey.item.length;
        i++
      ) {
        if (
          updateapprovedCarePackageactionTemp[j].survey.item[i].code[0]
            .display === "imageQuestion"
        ) {
          updateapprovedCarePackageactionTemp[j].survey.item[i].type =
            "attachment";
          updateapprovedCarePackageactionTemp[j].survey.item[i]["repeats"] =
            updateapprovedCarePackageactionTemp[j].survey.item[i].initial
              .length > 1
              ? true
              : false;
        }
      }
    }
  }

  return updateapprovedCarePackageactionTemp;
};

export const doChangesToSurveyObj = (obj1, j) => {
  let obj = obj1;
  for (let i = 0; i < obj.action[j].survey.item.length; i++) {
    if (obj.action[j].survey.item[i].code[0].display === "imageQuestion") {
      obj.action[j].survey.item[i].type = "attachment";
      obj.action[j].survey.item[i]["repeats"] =
        obj.action[j].survey.item[i].initial.length > 1 ? true : false;
    }
  }

  return obj;
};

export const decideWarningKey = (e) => {
  let alertToConsider = "";

  if (
    e.carepackageAlert &&
    e.carepackageAlert !== "" &&
    e.consentAlert &&
    e.consentAlert !== ""
  ) {
    alertToConsider = e.carepackageAlert;
  }

  // Check if either of the keys have non-empty strings
  else if (e.carepackageAlert && e.carepackageAlert !== "") {
    alertToConsider = e.carepackageAlert;
  } else if (e.consentAlert && e.consentAlert !== "") {
    alertToConsider = e.consentAlert;
  }

  // Check if both keys have empty strings
  else if (e.carepackageAlert === "" && e.consentAlert === "") {
    alertToConsider = "";
  }

  // Check if one key is undefined and the other one is not
  else if (e.carepackageAlert && !e.consentAlert) {
    alertToConsider = e.carepackageAlert;
  } else if (!e.carepackageAlert && e.consentAlert) {
    alertToConsider = e.consentAlert;
  } else {
    // Both keys are undefined
    alertToConsider = "";
  }

  if (alertToConsider === "red") {
    return "red";
  } else if (alertToConsider === "yellow") {
    return "yellow";
  }
  return "";
};

export const createActivityCardData = (neededArr, activity, cm, patient) => {
  let data = neededArr.filter((e) => e.name === activity);
  if (data.length === 1) {
    data = data[0];
  } else {
    data = {};
  }
  let startDate = "";
  let duration = "";
  let frequency = "";
  let status = "";
  let taskOwner = "";
  let MeasurementMetrics = "";
  let MeasurementUnit = "";
  let lower = "";
  let upper = "";
  const createFrequencyStr = (obj) => {
    return "Repeat Every " + obj.repeatEvery + ", End After " + obj.endAfter;
  };
  const createLowerLimit = (data) => {
    let str = "";
    if (data.length === 2) {
      str = str + "Systolic: " + data[0].min + ", ";
      str = str + "Dystolic: " + data[1].min;
    } else {
      str = data[0].min;
    }
    return str;
  };
  const createUpperLimit = (data) => {
    let str = "";
    if (data.length === 2) {
      str = str + "Systolic: " + data[0].max + ", ";
      str = str + "Dystolic: " + data[1].max;
    } else {
      str = data[0].max;
    }
    return str;
  };
  if (Object.keys(data).length !== 0) {
    startDate = "TBD";
    duration = data.duration ? data.duration : "";
    frequency = data.frequency ? createFrequencyStr(data.frequency) : "";
    status = "";
    taskOwner =
      cm && patient
        ? `${cm?.member?.name ? cm?.member?.name : ""}, ${
            patient ? patient : ""
          }`
        : "Case Manager, Patient";
    if (data.activityType && data.activityType === "Device") {
      MeasurementMetrics =
        data.measureDetails && data.measureDetails.length > 0
          ? data.measureDetails[0].metric === "Systolic" ||
            data.measureDetails[0].metric === "Diastolic"
            ? "Blood Pressure"
            : data.measureDetails[0].metric
          : "";
      MeasurementUnit = data.measureUnit ? data.measureUnit : "";
      lower = data.measureDetails ? createLowerLimit(data.measureDetails) : "";
      upper = data.measureDetails ? createUpperLimit(data.measureDetails) : "";
    }
  }

  return {
    startDate,
    duration,
    frequency,
    status,
    taskOwner,
    MeasurementMetrics,
    MeasurementUnit,
    lower,
    upper,
    data,
  };
};

export const hardUpdateEnvAsessmentInformation = (
  dispatch,
  AcessSafetyForm,
  ServiceSupportForm,
  searchFhirPatientId,
  cpId,
  EnrollmentAcessSafetyQuestion,
  EnrollmentServiceSupportQuestion
) => {
  AccessSafetyobjService.SendObj(
    AcessSafetyForm,
    ServiceSupportForm,
    searchFhirPatientId,
    cpId,
    EnrollmentAcessSafetyQuestion,
    EnrollmentServiceSupportQuestion
  ).then((user) => {
    dispatch({
      type: sagaActions.FETCH_DATA_SAGAEnvironmentAcessSafetyPost,
      payload: user,
    });
  });
};

export const saveandExitDispaatch = (
  AcessSafetyForm,
  ServiceSupportForm,
  EnrollmentAcessSafetyQuestion,
  EnrollmentServiceSupportQuestion,
  dispatch,
  updatedselectedCarePackagebyCategory,
  currentEnrollmentStatus,
  id,
  searchFhirPatientId,
  cpId,
  patientCarePackageId,
  hardUpdate,
  dropchip
) => {
  let flag;
  if (hardUpdate === "hardUpdate") {
    flag = dropchip;
  } else {
    flag = true;
  }

  if (flag) {
    let selectedCarePackagebyCategory1 = Object.assign(
      {},
      updatedselectedCarePackagebyCategory
    );
    let reviewerArray = _.cloneDeep(selectedCarePackagebyCategory1.reviewer);
    let approver = selectedCarePackagebyCategory1.reviewer[0].assigned_to;
    reviewerArray.unshift({
      assigned_on: new Date().toISOString().slice(0, 10),
      assigned_to: approver,
      current_state:
        currentEnrollmentStatus === "Modification Requested"
          ? currentEnrollmentStatus
          : "Draft",
    });

    selectedCarePackagebyCategory1.reviewer = reviewerArray;
    selectedCarePackagebyCategory1.patientId = id;
    if (currentEnrollmentStatus !== "Modification Requested") {
      selectedCarePackagebyCategory1.careTeam = "";
    }
    selectedCarePackagebyCategory1.patientFHIRId = searchFhirPatientId;
    selectedCarePackagebyCategory1.state =
      currentEnrollmentStatus === "Modification Requested"
        ? currentEnrollmentStatus
        : "Draft";
    selectedCarePackagebyCategory1.cpIdentifier = cpId;
    selectedCarePackagebyCategory1.id = patientCarePackageId;
    selectedCarePackagebyCategory1.careArea = modifyCareAreaList(
      selectedCarePackagebyCategory1.careArea
    );
    selectedCarePackagebyCategory1.careGoal = modifyCareGoalsList(
      selectedCarePackagebyCategory1.careGoal
    );
    selectedCarePackagebyCategory1.action = modifyActionList(
      selectedCarePackagebyCategory1.action
    );
    const cpNew = doRemoveSpacesFromObj({ ...selectedCarePackagebyCategory1 });

    dispatch(saveandExitpatientCarePackage(cpNew));
  }

  hardUpdateEnvAsessmentInformation(
    dispatch,
    AcessSafetyForm,
    ServiceSupportForm,
    searchFhirPatientId,
    cpId,
    EnrollmentAcessSafetyQuestion,
    EnrollmentServiceSupportQuestion
  );
};

export const dateConvert = (date) => {
  const mydate = new Date(date);
  let dateconverted;
  if (
    moment(mydate.toISOString()).format("DD/MM/YYYY") ===
    moment(new Date().toISOString()).format("DD/MM/YYYY")
  ) {
    dateconverted = "Today";
  } else {
    dateconverted =
      `0${mydate.getUTCMonth() + 1}`.slice(-2) +
      "/" +
      `0${mydate.getUTCDate()}`.slice(-2) +
      "/" +
      mydate.getUTCFullYear();
  }
  return dateconverted;
};

export const createCommonObj = (obj) => {
  const commonObj = {
    ...obj,
    status: "Draft",
    additionalPatientResource: obj.additionalPatientResource
      ? typeof obj.additionalPatientResource === "string"
        ? [obj.additionalPatientResource].map((e) => {
            return {
              title: "",
              url: e,
            };
          })
        : obj.additionalPatientResource.map((e) => {
            if (typeof e === "object" && !Array.isArray(e) && e !== null) {
              return e;
            }

            return {
              title: "",
              url: e,
            };
          })
      : [],
    frequency: {
      endAfter: obj.frequency?.endAfter ? obj.frequency?.endAfter : "",
      repeatEvery: obj.frequency?.repeatEvery ? obj.frequency?.repeatEvery : "",
    },
  };
  return commonObj;
};

export const doSetCarePopupVals = (
  data,
  type,
  dispatch,
  pkg,
  setPatientCarePackage,
  deleteError,
  setDeleteError,
  handleModalSubmit
) => {
  const findCareAreaIndex = (updatedCarePackage) => {
    if (type === 1 || type === 3) {
      return updatedCarePackage.careArea.findIndex(
        (item) => item.name == data.careArea[0].name
      );
    }

    if (type === 2) {
      return updatedCarePackage.careArea.findIndex((item) => {
        const areaName =
          typeof data.careArea[0] === "string"
            ? data.careArea[0]
            : data.careArea[0].name;
        const itemToCompare = typeof item === "string" ? item : item.name;
        return itemToCompare == areaName;
      });
    }
  };

  let updatedCarePackage = JSON.parse(JSON.stringify(pkg));
  let foundGoalIndex = updatedCarePackage.careGoal.findIndex(
    (item) =>
      item.careAreaName == data.careGoal[0].careAreaName &&
      item.goalName === data.careGoal[0].goalName
  );
  if (foundGoalIndex !== -1) {
    if (type === 2) {
      const existingCareGoalObj = updatedCarePackage.careGoal[foundGoalIndex];
      updatedCarePackage.careGoal[foundGoalIndex] = {
        ...data.careGoal[0],
      };
      if (existingCareGoalObj.goalCancellationDate) {
        updatedCarePackage.careGoal[foundGoalIndex] = {
          ...updatedCarePackage.careGoal[foundGoalIndex],
          goalCancellationDate: existingCareGoalObj.goalCancellationDate,
        };
      }
      if (existingCareGoalObj.status) {
        updatedCarePackage.careGoal[foundGoalIndex] = {
          ...updatedCarePackage.careGoal[foundGoalIndex],
          status: existingCareGoalObj.status,
        };
      }
    } else {
      updatedCarePackage.careGoal[foundGoalIndex] = data.careGoal[0];
    }
  } else {
    if (type === 2) {
      const newObjToPush = {
        ...data.careGoal[0],
        goalCancellationDate: { dateOfInitiation: "", laterDate: "" },
        status: "Draft",
      };
      updatedCarePackage.careGoal.push(newObjToPush);
    } else {
      updatedCarePackage.careGoal.push(data.careGoal[0]);
    }
  }
  let careAreaIndex = findCareAreaIndex(updatedCarePackage);
  if (careAreaIndex !== -1) {
    if (type === 2) {
      const existingCareAreaObj = updatedCarePackage.careArea[careAreaIndex];
      const convertedCareAreaToObj =
        typeof data.careArea[0] === "string"
          ? { name: data.careArea[0] }
          : data.careArea[0];
      updatedCarePackage.careArea[careAreaIndex] = {
        ...convertedCareAreaToObj,
      };
      if (existingCareAreaObj.areaCancellationDate) {
        updatedCarePackage.careArea[careAreaIndex] = {
          ...updatedCarePackage.careArea[careAreaIndex],
          areaCancellationDate: existingCareAreaObj.areaCancellationDate,
        };
      }
      if (existingCareAreaObj.status) {
        updatedCarePackage.careArea[careAreaIndex] = {
          ...updatedCarePackage.careArea[careAreaIndex],
          status: existingCareAreaObj.status,
        };
      }
    } else {
      updatedCarePackage.careArea[careAreaIndex] = data.careArea[0];
    }
  } else {
    if (type === 2) {
      const convertedCareAreaToObj =
        typeof data.careArea[0] === "string"
          ? { name: data.careArea[0] }
          : data.careArea[0];
      const newObjToPush = {
        ...convertedCareAreaToObj,
        areaCancellationDate: { dateOfInitiation: "", laterDate: "" },
        status: "Draft",
      };
      updatedCarePackage.careArea.push(newObjToPush);
    } else {
      updatedCarePackage.careArea.push(data.careArea[0]);
    }
  }
  for (let i = 0; i < data.action.length; i++) {
    updatedCarePackage.action.push(data.action[i]);
  }

  if (type === 1) {
    dispatch(setPatientCarePackage(updatedCarePackage));
    if (deleteError) setDeleteError(false);
  }

  if (type === 2 || type === 3) {
    if (type === 2) {
      updatedCarePackage.careArea = updatedCarePackage.careArea.map((item) => {
        if (typeof item === "string") {
          return { name: item };
        }
        return item;
      });
    }
    handleModalSubmit(updatedCarePackage);
  }
};

export const arraysEqual = (a1, a2) =>
  a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]));

export const objectsEqual = (o1, o2) => {
  if (typeof o1 === "undefined" || typeof o2 === "undefined") {
    return false;
  }
  return typeof o1 === "object" && Object.keys(o1).length > 0
    ? Object.keys(o1).length === Object.keys(o2).length &&
        Object.keys(o1).every((p) => objectsEqual(o1[p], o2[p]))
    : o1 === o2;
};

export const doBackWardCompatibility = async (
  cpNew,
  getMetricsOfADevice,
  selectedCareTeam
) => {
  // code to add backward compatibility for device metric name and metric type + appointment role id
  const cpNewsNewActionArr = await Promise.all(
    cpNew.action.map(async (e) => {
      if (e.activityType === "Device") {
        let metricsObj = {};
        if (
          !e.hasOwnProperty("metricType") ||
          !e.hasOwnProperty("metricName") ||
          e?.metricType === "" ||
          e?.metricName === ""
        ) {
          metricsObj = await getMetricsOfADevice(
            e.deviceType,
            e.measureDetails ? e.measureDetails[0].metric : ""
          );
        }

        if (
          (!e.hasOwnProperty("metricType") &&
            !e.hasOwnProperty("metricName")) ||
          (!e.hasOwnProperty("metricType") && e?.metricName === "") ||
          (!e.hasOwnProperty("metricName") && e?.metricType === "") ||
          (e?.metricType === "" && e?.metricName === "")
        ) {
          return {
            ...e,
            ...metricsObj,
          };
        }

        if (!e.hasOwnProperty("metricName") || e?.metricName === "") {
          return {
            ...e,
            metricName: metricsObj.metricName,
          };
        }

        if (!e.hasOwnProperty("metricType") || e?.metricType === "") {
          return {
            ...e,
            metricType: metricsObj.metricType,
          };
        }
      }

      if (e.activityType === "Appointment") {
        const matched = selectedCareTeam?.care_role_specialists.filter(
          (e1) => e1.member.name === e.participant
        );
        if (
          matched.length > 0 &&
          e.participant &&
          e.participant !== "" &&
          (!e.practitionerRoleId || e.practitionerRoleId === "")
        ) {
          const matchedPractionerReference = matched[0].member.reference;
          return {
            ...e,
            practitionerRoleId: matchedPractionerReference,
          };
        }
      }

      return {
        ...e,
      };
    })
  );

  return cpNewsNewActionArr;
  // code to add backward compatibility for device metric name and metric type + appointment role id
};

export const constructCPIdWithRequiredEnding = (cpId) => {
  const cpIDSplitted = cpId.split("-");

  let str = "DRAFT";
  if (cpIDSplitted[cpIDSplitted.length - 1] === "ACTVE") {
    str = "ACTVE";
  }
  cpIDSplitted.pop();
  cpIDSplitted.push(str);
  const cpIdNew = cpIDSplitted.join("-");

  return cpIdNew;
};

export const updateCareTeamAPIPayload = (
  editTemplate,
  patientCareTeamId,
  cpId,
  CARE_TEAM
) => {
  let careTeamT = editTemplate;

  let data = {
    ...careTeamT,
    status: "Draft",
    id: patientCareTeamId,
    cpIdentifier: constructCPIdWithRequiredEnding(cpId),
  };

  const attendingPhysician = data.care_role_specialists.filter(
    (e) => e.role === CARE_TEAM.ROLES.ATTENDING_PHYSICIAN
  )[0];
  const caseManager = data.care_role_specialists.filter(
    (e) => e.role === CARE_TEAM.ROLES.CASE_MANAGER
  )[0];
  const clinicalRole = data.care_role_specialists.filter(
    (e) => e.role === CARE_TEAM.ROLES.CLINICAL_GROUP
  )[0];
  const nonClinicalRole = data.care_role_specialists.filter(
    (e) => e.role === CARE_TEAM.ROLES.NON_CLINICAL_GROUP
  )[0];

  // for attending phy
  const phyArr = [];
  let objOutOfAttendingPhy = {
    isClinicalRole: attendingPhysician.isClinicalRole,
    role: CARE_TEAM.ROLES.ATTENDING_PHYSICIAN,
  };

  attendingPhysician.specialties[0].members.forEach((eachMem) => {
    const objToSet = {
      isApproved: eachMem.isDeleted ? false : eachMem.isApproved,
      isDeleted: eachMem.isDeleted,
      removeDate: "",
      member: {
        name: eachMem.practitionerName,
        reference: eachMem.practitionerRoleId,
      },
      specialty: attendingPhysician.specialties[0].specialty,
    };
    phyArr.push({ ...objToSet, ...objOutOfAttendingPhy });
  });

  // for case manager
  const cmArr = [];
  let objOutOfCM = {
    isClinicalRole: caseManager.isClinicalRole,
    role: CARE_TEAM.ROLES.CASE_MANAGER,
  };

  caseManager.specialties[0].members.forEach((eachMem) => {
    const objToSet = {
      isApproved: eachMem.isDeleted ? false : eachMem.isApproved,
      isDeleted: eachMem.isDeleted,
      removeDate: "",
      member: {
        name: eachMem.practitionerName,
        reference: eachMem.practitionerRoleId,
      },
      specialty: caseManager.specialties[0].specialty,
    };
    cmArr.push({ ...objToSet, ...objOutOfCM });
  });

  // for clinical roles
  const crArr = [];
  let objOutOfCR = {
    isClinicalRole: caseManager.isClinicalRole,
    role: CARE_TEAM.ROLES.CLINICAL_GROUP,
  };

  clinicalRole?.specialties.forEach((eachSpeciality) => {
    const specName = eachSpeciality.specialty;
    const specMembers = eachSpeciality.members;

    specMembers.forEach((eachMem) => {
      const objToSet = {
        isApproved: eachMem.isDeleted ? false : eachMem.isApproved,
        isDeleted: eachMem.isDeleted,
        removeDate: "",
        member: {
          name: eachMem.practitionerName,
          reference: eachMem.practitionerRoleId,
        },
        specialty: specName,
      };
      crArr.push({ ...objToSet, ...objOutOfCR });
    });
  });

  // for non clinical roles
  const ncrArr = [];
  let objOutOfNCR = {
    isClinicalRole: caseManager.isClinicalRole,
    role: CARE_TEAM.ROLES.NON_CLINICAL_GROUP,
  };
  if (nonClinicalRole) {
    nonClinicalRole?.specialties.forEach((eachSpeciality) => {
      const specName = eachSpeciality.specialty;
      const specMembers = eachSpeciality.members;

      specMembers.forEach((eachMem) => {
        const objToSet = {
          isApproved: eachMem.isDeleted ? false : eachMem.isApproved,
          isDeleted: eachMem.isDeleted,
          removeDate: "",
          member: {
            name: eachMem.practitionerName,
            reference: eachMem.practitionerRoleId,
          },
          specialty: specName,
        };
        ncrArr.push({ ...objToSet, ...objOutOfNCR });
      });
    });
  }

  const final_care_role_specialists = [
    ...phyArr,
    ...cmArr,
    ...crArr,
    ...ncrArr,
  ];
  data.care_role_specialists = final_care_role_specialists;

  return data;
};

export const isRequiredPresent = (data) => {
  for (let key in data) {
    if (typeof data[key] === "string" && data[key] === "Required") {
      return true;
    } else if (Array.isArray(data[key]) && data[key].includes("Required")) {
      return true;
    }
  }
  return false;
};
