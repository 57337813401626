import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";

import rootReducer from "./rootReducer";
import rootSaga from "./rootSaga";

export const configureStoreWithMiddlewares = (initialState = {}) => {
  const sagaMiddleware = createSagaMiddleware();

  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ thunk: false, serializableCheck: false }).concat(
        sagaMiddleware
      ),
    preloadedState: initialState,
  });

  sagaMiddleware.run(rootSaga);

  return store;
};

const store = configureStoreWithMiddlewares();
export default store;
