import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
const LoginWithSSO = () => {
    const navigate = useNavigate();
    const { ssoSignIn } = useAuth();

    useEffect(() => {
        var access_token = new URLSearchParams(window.location.hash).get('#access_token');
        var id_token = new URLSearchParams(window.location.hash).get('id_token');
        var base64Url = id_token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        let payload = JSON.parse(jsonPayload)
        localStorage.setItem("token", id_token);
        localStorage.setItem("email", payload.email);
        ssoSignIn(payload.email)
        navigate("/dashboard");
    }, [])

    return (
        <>
            <div className="d-flex align-items-center justify-content-center h-100">
                <p className="align-self-center">Please wait...</p>
            </div>
        </>
    );
};

export default LoginWithSSO;