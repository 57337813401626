import { createSlice } from "@reduxjs/toolkit";

const patientsCareTeamSlice = createSlice({
  name: "patientsCareTeam",
  initialState: {
    allCareTeamTemplates: [],
    uniqueCareTeamName: [],
    selectedCareTeam: null,
    allPractitioners: [],
    loadedPractitionersSpecialty: [],

    newTemplate: {
      name: "",
      description: "",
      care_category: [],
      isGoldStandard: false,
      patient: {
        name: "",
        reference: "",
        patientId: "",
      },
      care_role_specialists: [],
      author: "",
      status: "proposed",
      reviewer: [
        {
          reviewer_name: "",
          comments: "",
          reviewed_at: "",
        },
      ],
    },
    editTemplate: null,
    selectedPatient: null,
    patientCareTeam: null,
    isCareTeamHardSet: false,
  },
  reducers: {
    getCareTeamTemplates() {},
    setCareTeamTemplatesEnrollment(state, action) {
      const { entry } = action.payload;
      state.uniqueCareTeamName = [...new Set(entry.map((item) => item.name))];
      state.allCareTeamTemplates = entry;
    },
    getCareTeamByName(name) {},
    setSelectedCareTeamEnrollment(state, action) {
      state.selectedCareTeam = action.payload;
    },
    getPractitioners(specialty) {},
    setPractitioners(state, action) {
      let temp = [...state.allPractitioners, ...action.payload.entry];
      state.allPractitioners = _.uniq(temp, "practitionerId");
      state.loadedPractitionersSpecialty = [
        ...state.loadedPractitionersSpecialty,
        action.payload.specialty,
      ];
    },

    // create new template
    updateNewTemplate(state, action) {
      state.newTemplate = action.payload;
    },
    resetNewTemplate(state) {
      state.newTemplate = {
        name: "",
        description: "",
        care_category: [],
        isGoldStandard: false,
        patient: {
          name: "",
          reference: "",
          patientId: "",
        },
        care_role_specialists: [],
        author: "",
        status: "proposed",
        reviewer: [
          {
            reviewer_name: "",
            comments: "",
            reviewed_at: "",
          },
        ],
      };
    },
    createNewCareTeam() {},
    hardCreateNewCareTeam() {},
    updateExistingCareTeam(template) {},
    hardUpdateExistingCareTeam(template) {},
    //Edit template
    updateEditTemplate(state, action) {
      state.editTemplate = action.payload;
    },
    getSelectedPatient(id) {},
    setSelectedPatient(state, action) {
      state.selectedPatient = action.payload;
    },

    getAssociationCareTeam(name) {},
    setAssociationCareTeam(state, action) {
      state.patientCareTeam = action.payload;
    },
    setIsCareTeamHardSet(state, action) {
      state.isCareTeamHardSet = action.payload;
    },
    resetAllPatientsCareTeam(state) {
      state.selectedCareTeam = null;

      state.newTemplate = {
        name: "",
        description: "",
        care_category: [],
        isGoldStandard: false,
        patient: {
          name: "",
          reference: "",
          patientId: "",
        },
        care_role_specialists: [],
        author: "",
        status: "proposed",
        reviewer: [
          {
            reviewer_name: "",
            comments: "",
            reviewed_at: "",
          },
        ],
      };
      state.editTemplate = null;
      state.patientCareTeam = null;
      state.isCareTeamHardSet = false;
    },
  },
});

export const {
  getCareTeamTemplates,
  setCareTeamTemplatesEnrollment,
  getCareTeamByName,
  setSelectedCareTeamEnrollment,
  getPractitioners,
  setPractitioners,
  updateNewTemplate,
  createNewCareTeam,
  hardCreateNewCareTeam,
  updateExistingCareTeam,
  hardUpdateExistingCareTeam,
  resetNewTemplate,
  setSnackbarTitle,
  setShowSnackbar,
  updateEditTemplate,
  getSelectedPatient,
  setSelectedPatient,
  getAssociationCareTeam,
  setAssociationCareTeam,
  setIsCareTeamHardSet,
  resetAllPatientsCareTeam,
} = patientsCareTeamSlice.actions;

export default patientsCareTeamSlice.reducer;
