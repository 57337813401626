import { createSlice } from "@reduxjs/toolkit";

import { APPOINTMENT } from "./../../utils/constants";

let initialState = {
  currentUserProfile: null,
  userPermissions: null,
  insuffPermissions: false,
  completedAppointments: [],
  upcomingAppointments: [],
  joinAppointment: {
    status: APPOINTMENT.ONLINE_STATUS.NOT_STARTED,
    appointmentInfo: null,
  },
};

const appointments = createSlice({
  name: "appointments",
  initialState: initialState,
  reducers: {
    getUserProfile(email) { },
    setUserProfile(state, action) {
      state.currentUserProfile = action.payload;
    },
    getInsuffPermissions() { },
    setInsuffPermissions(state, action) {
      state.insuffPermissions = action.payload;
    },
    getUserPermissions(email) { },
    setUserPermissions(state, action) {
      state.userPermissions = action.payload;
    },
    updateUserProfile(body) { },
    updateAppointmentPreference(body) { },
    getAppointments(req) { },
    setAppointments(state, action) {
      state.completedAppointments = action.payload.completed;
      state.upcomingAppointments = action.payload.booked;
    },
    setJoinAppointment(state, action) {
      state.joinAppointment.appointmentInfo = action.payload;
    },
    updateJoinAppointmentStatus(state, action) {
      state.joinAppointment.status = action.payload;
    },
    resetAppointments(state, action) {
      // state = {...initialState};
      state.currentUserProfile = null;
    },
    resetPermissions(state, action) {
      // state = {...initialState};
      state.userPermissions = null;
    },
    endAppointments(state, action) {
    },
    joinAppoinment(state, action) {
    }
  },
});

export const {
  getUserProfile,
  setUserProfile,
  getInsuffPermissions,
  setInsuffPermissions,
  getUserPermissions,
  setUserPermissions,
  updateUserProfile,
  getAppointments,
  setAppointments,
  setJoinAppointment,
  updateJoinAppointmentStatus,
  resetAppointments,
  resetPermissions,
  updateAppointmentPreference,
  endAppointments,
  joinAppoinment
} = appointments.actions;

export default appointments.reducer;
