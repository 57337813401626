import API from "../../lib/api";
import {
  CARE_PACKAGE_BASE_URL,
  ENROLLMENT_BASE_URL,
  PATIENT_DEVICES_BASE_URL,
  CARE_PACKAGE_PATIENT_BASE_URL,
} from "../../utils/constants";

const searchCarePackageActivityByNameURL = `${CARE_PACKAGE_PATIENT_BASE_URL}/Activity`;
const getCareProgramsURL = `${ENROLLMENT_BASE_URL}/CarePrograms`;
const getActivityListURL = `${CARE_PACKAGE_PATIENT_BASE_URL}/Activity?status=Approved`;
const postcarePackageURL = `${CARE_PACKAGE_PATIENT_BASE_URL}/CarePackage`;
const getAllCarePackagesURL = `${CARE_PACKAGE_PATIENT_BASE_URL}/CarePackages`;
const postCarePackageApprover = `${CARE_PACKAGE_PATIENT_BASE_URL}/CarePackage`;
const getDeviceType = `${CARE_PACKAGE_BASE_URL}/Device`;
const getApproverListURL = `${CARE_PACKAGE_BASE_URL}/Approvers`;
const searchCarePackageGoalURL = `${CARE_PACKAGE_BASE_URL}/CareGoals`;
const getmeasurementsMetricURL = `${PATIENT_DEVICES_BASE_URL}/DeviceMetrics`;
const getActivitiesListURL = `${CARE_PACKAGE_BASE_URL}/Activities`;
const postCareActivity = `${CARE_PACKAGE_BASE_URL}/Activities`;
const putCareActivity = `${CARE_PACKAGE_BASE_URL}/Activities`;
const deleteCareActivity = `${CARE_PACKAGE_BASE_URL}/Activities`;
const deleteGoldStandardCarePackage = `${CARE_PACKAGE_PATIENT_BASE_URL}/CarePackage`;

export function requestGetCarePackageActivityByName(params) {
  return API.get(searchCarePackageActivityByNameURL, params);
}

export function requestGetCarePackageActivityDeviceType() {
  return API.get(getDeviceType);
}

export function requestGetApproverList() {
  return API.get(getApproverListURL);
}

export function requestGetCarePackageCarePackageProgram() {
  return API.get(getCareProgramsURL);
}

export function requestpostCarePackageCarePackageTemplate(body) {
  return API.post(postcarePackageURL, body);
}

export function requestpostCarePackageActivityList() {
  return API.get(getActivityListURL);
}

export function requestGetAllMyCarePackagesTemplates(params) {
  return API.get(getAllCarePackagesURL, params);
}

export function requestGetAllCarePackagesTemplates() {
  return API.get(getAllCarePackagesURL);
}

export function requestpostCarePackageApprover(body) {
  return API.put(postCarePackageApprover, body);
}

export function requestGetCarePackageGoalList() {
  return API.get(searchCarePackageGoalURL);
}

export function requestGetmeasurementsMetricArray(params) {
  return API.get(getmeasurementsMetricURL, params);
}

export function requestGetActivitiesList(params) {
  return API.get(getActivitiesListURL, params);
}

export function requestpostCareActivity(body) {
  return API.post(postCareActivity, body);
}

export function requestputCareActivity(body) {
  return API.put(`${putCareActivity}`, body.data);
}

export function requestDeleteCareActivity(body) {
  return API.delete(deleteCareActivity, body);
}

export function requestDeleteGoldStandardCarePackage(body) {
  return API.delete(`${deleteGoldStandardCarePackage}/${body.id}`);
}
