import API from "../lib/api";

import {
    CHAT_MESSAGING_URL,
} from "../utils/constants";

const ChatProfile = `${CHAT_MESSAGING_URL}/ChatProfile`;

export function getChatProfile(email) {
    return API.get(
        `${ChatProfile}?emailId=${email}`
    );
}

