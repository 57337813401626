import API from "../../lib/api";

import { CARE_PACKAGE_BASE_URL, EMR_BASE_URL, EMR_PATIENT_BASE_URL } from "./../../utils/constants";

export function requestGetTotalPatients(category) {
  return API.get(`${CARE_PACKAGE_BASE_URL}/Metrics?category=${category}`);
}

export function requestGetTopCarePlan(category) {
  return API.get(
    `${CARE_PACKAGE_BASE_URL}/TopCarePlansByCategory?category=${category}`
  );
}

export function requestGetCareTeamStatistics() {
  return API.get(`${CARE_PACKAGE_BASE_URL}/CareTeamStatistics`);
}

export function requestGetActivePatientsByCategory() {
  return API.get(
    `${CARE_PACKAGE_BASE_URL}/ActivePatientsByCarePackageCategory`
  );
}

export function requestGetAppOnboardStatus() {
  return API.get(`${CARE_PACKAGE_BASE_URL}/AppOnboardingStatus`);
}

export function requestSearchPatients(queryParam) {
  return API.get(`${EMR_PATIENT_BASE_URL}/SearchPatientFromEmr`, {
    params: queryParam,
    headers: {
      "Emr-Format": "R4",
    },
  });
}
