import styled, { css, keyframes } from "styled-components";

export const rotateAnimation = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

export const IconAnimation = {
  rotate: css`
    animation: ${rotateAnimation} 2s linear infinite;
  `,
};

export const StyledIcon = styled.svg`
  display: inline-block;
  vertical-align: middle;
  fill: ${(props) => props.color};
  transform: rotate(${(props) => props.rotate}deg);
  ${({ animate }) => IconAnimation[animate]}
`;

export const GhIcon = ({
  className = null,
  color,
  size,
  icon,
  rotate = 0,
  viewbox = "0 0 20 20",
  animate,
}) => {
  return (
    <StyledIcon
      animate={animate}
      color={color}
      width={`${size}px`}
      height={`${size}px`}
      viewBox={viewbox}
      className={className}
      rotate={rotate}
    >
      <path d={icon}></path>
    </StyledIcon>
  );
};
