import API from "../../../../lib/api";

import {
  CARE_PACKAGE_BASE_URL,
  CARE_PACKAGE_PATIENT_BASE_URL,
} from "./../../../../utils/constants";

export function requestGetCareTeamTemplatesEnrollment() {
  return API.get(`${CARE_PACKAGE_BASE_URL}/CareTeams`);
}

export function requestGetCareTeamByNameEnrollment(name) {
  return API.get(`${CARE_PACKAGE_PATIENT_BASE_URL}/CareTeam`, {
    params: { name: name },
  });
}

export function requestGetPractitioners(specialty) {
  return API.get(
    `${CARE_PACKAGE_BASE_URL}/Practitioners?specialty=${specialty}`
  );
}

export function requestCreateNewCareTeam(template) {
  return API.post(`${CARE_PACKAGE_PATIENT_BASE_URL}/PatientCareTeam`, template);
}

export function requestGetPatientById(id) {
  return API.get(`${EMR_PATIENT_BASE_URL}/SearchPatientById?patientId=${id}`);
}

export function requestGetCareTeamById({ id, cpId }) {
  let status = cpId.slice(cpId.length - 5);
  let str = cpId.substring(0, cpId.length - 5);
  let newCpId = "";
  if (status == "ACTVE") {
    newCpId = cpId;
  } else if (status == "APPRV") {
    newCpId = cpId;
  } else if (status == "CMPLT") {
    newCpId = cpId;
  } else if (status == "TRMND") {
    newCpId = cpId;
  } else {
    newCpId = str + "DRAFT";
  }
  return API.get(
    `${CARE_PACKAGE_BASE_URL}/PatientCareTeam?patientId=${id}&cpIdentifier=${newCpId}`
  );
}

export function requestUpdateExistingCareTeam(template) {
  return API.put(`${CARE_PACKAGE_PATIENT_BASE_URL}/PatientCareTeam`, template);
}
