import { takeLatest, takeEvery } from "redux-saga/effects";
import {
  handleGetPatientById,
  handleGetCareTeamById,
  handleGetCareTeamTemplatesEnrollment,
  handleGetCareTeamByNameEnrollment,
  handleGetPractitioners,
  handleCreateNewCareTeam,
  handleHardCreateNewCareTeam,
  handleUpdateExistingCareTeam,
  handleHardUpdateExistingCareTeam,
} from "./careTeamHandler";
import { getSelectedPatient, getAssociationCareTeam } from "./careTeamSlice";
import {
  getCareTeamTemplates,
  getCareTeamByName,
  getPractitioners,
  createNewCareTeam,
  updateExistingCareTeam,
  hardCreateNewCareTeam,
  hardUpdateExistingCareTeam,
} from "./careTeamSlice";

export const patientCareTeamSaga = [
  takeLatest(getCareTeamTemplates.type, handleGetCareTeamTemplatesEnrollment),
  takeLatest(getCareTeamByName.type, handleGetCareTeamByNameEnrollment),
  takeEvery(getPractitioners.type, handleGetPractitioners),
  takeEvery(createNewCareTeam.type, handleCreateNewCareTeam),
  takeEvery(hardCreateNewCareTeam.type, handleHardCreateNewCareTeam),
  takeLatest(getAssociationCareTeam.type, handleGetCareTeamById),
  takeLatest(getSelectedPatient.type, handleGetPatientById),
  takeLatest(updateExistingCareTeam.type, handleUpdateExistingCareTeam),
  takeLatest(hardUpdateExistingCareTeam.type, handleHardUpdateExistingCareTeam),
];
