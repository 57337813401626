import { call, put } from "redux-saga/effects";

import {
  setCarePackageActivityByName,
  setCarePackageProgram,
  setCarePackageAction,
  setcarePackageAllActivityListfromApi,
  setGetAllCarePackagesTemplates,
  setGetAllMyCarePackagesTemplates,
  setcareGoalModalActivity,
  setcarePackageApproverStatus,
  setDeviceType,
  setCarePackagesTemplatesSuccess,
  setApproverList,
  setGoalList,
  setmeasurementsMetricArray,
  setCPFetchLoaderAll,
  setCPFetchLoaderMy,
  setActivitiesList,
  setActivitiesListLoaded,
  setActivityChanged,
} from "./carePackageSlice";
import {
  requestGetCarePackageActivityByName,
  requestpostCarePackageActivityList,
  requestGetCarePackageCarePackageProgram,
  requestpostCarePackageCarePackageTemplate,
  requestGetAllCarePackagesTemplates,
  requestGetAllMyCarePackagesTemplates,
  requestpostCarePackageApprover,
  requestGetCarePackageActivityDeviceType,
  requestGetApproverList,
  requestGetCarePackageGoalList,
  requestGetmeasurementsMetricArray,
  requestGetActivitiesList,
  requestpostCareActivity,
  requestputCareActivity,
  requestDeleteCareActivity,
} from "./carePackageRequest";
import { History } from "../../history";
import { openSnackbar } from "../toast/toastSlice";
import { setSpinner } from "../shared/sharedSlice";

export function* handleGetCarePackageActivityByName(action) {
  try {
    if (action.payload.isSaveAndExit) {
      const response = yield call(
        requestGetCarePackageActivityByName,
        action.payload.parameters
      );
      const { entry } = response.data;
      yield put(setCarePackageActivityByName({ ...entry }));
      if (response) {
        yield put(
          setCarePackageAction({
            ...entry,
            isSaveAndExit: action.payload.numberOFEntriedExpectedInActionArr,
          })
        );
      }
    } else {
      const response = yield call(
        requestGetCarePackageActivityByName,
        action.payload
      );
      const { entry } = response.data;
      yield put(setCarePackageActivityByName({ ...entry }));
      if (response) {
        yield put(setCarePackageAction({ ...entry }));
      }
    }
  } catch (error) {
    console.log("something went wrong!");
  }
}

export function* handleGetCarePackageActivityDeviceType() {
  try {
    const response = yield call(requestGetCarePackageActivityDeviceType);
    const { entry } = response.data;
    yield put(setDeviceType(entry));
    if (response) {
      yield put(setDeviceType(entry));
    }
  } catch (error) {
    console.log("something went wrong!");
  }
}

export function* handleGetApproverList() {
  try {
    const response = yield call(requestGetApproverList);

    if (response) {
      const { entry } = response.data;
      let mappedEntry = entry.map((string) => {
        string.practitionerName = string.practitionerName.replace(",", " ");
        return string;
      });
      yield put(setApproverList(mappedEntry));
    }
  } catch (error) {
    console.log("something went wrong!");
  }
}

export function* handleGetCarePackageProgram(action) {
  try {
    yield put(setCarePackageProgram([]));
    const response = yield call(requestGetCarePackageCarePackageProgram);
    const entry = response.data.recCarePrograms;
    yield put(setCarePackageProgram(entry));
  } catch (error) {
    console.log("something went wrong!");
  }
}

export function* handleGetCarePackageTemplate(action) {
  let reqPayload;
  if (action.payload.isDuplication) {
    reqPayload = action.payload.data;
  } else if (action.payload.mssg) {
    reqPayload = action.payload.carepackagePayload;
  } else {
    reqPayload = action.payload;
  }

  try {
    yield call(requestpostCarePackageCarePackageTemplate, reqPayload);
    yield put(setCarePackagesTemplatesSuccess(true));
    yield put(
      openSnackbar({
        snackbarTitle: action.payload.isDuplication
          ? "Care Package Duplicated Successfully"
          : action.payload.mssg
          ? "Care Package Saved Successfully"
          : "Your care package has been submitted for approval",
        icon: "",
        severity: "success",
      })
    );
    History.navigate("/packages");
  } catch (error) {
    console.log("something went wrong!");
    yield put(setCarePackagesTemplatesSuccess(false));
    yield put(
      openSnackbar({
        snackbarTitle: "Request Failed",
        icon: "",
        severity: "error",
      })
    );
  }
}

export function* handleGetCarePackageActivityList() {
  try {
    const response = yield call(requestpostCarePackageActivityList);
    const { entry } = response.data;
    yield put(setcarePackageAllActivityListfromApi(entry));
  } catch (error) {
    console.log("something went wrong!");
  }
}

export function* handleGetAllMyCarePackagesTemplates(action) {
  try {
    const response = yield call(
      requestGetAllMyCarePackagesTemplates,
      action.payload
    );
    const { entry } = response.data;
    yield put(setGetAllMyCarePackagesTemplates(entry));
    yield put(setCPFetchLoaderMy(true));
  } catch (error) {
    console.log("something went wrong!");
  }
}

export function* handleGetAllCarePackagesTemplates() {
  try {
    const response = yield call(requestGetAllCarePackagesTemplates);
    const { entry } = response.data;
    yield put(setGetAllCarePackagesTemplates(entry));
    yield put(setCPFetchLoaderAll(true));
  } catch (error) {
    console.log("something went wrong!");
  }
}

export function* handlegetCareGoalModalActivityDetails(action) {
  try {
    const response = yield call(
      requestGetCarePackageActivityByName,
      action.payload
    );
    const { entry } = response.data;
    yield put(setcareGoalModalActivity({ ...entry }));
  } catch (error) {
    console.log("something went wrong!");
  }
}

export function* handlepostcarePackageApprover(action) {
  try {
    yield put(setcarePackageApproverStatus(""));
    const response = yield call(
      requestpostCarePackageApprover,
      action.payload.carepackagePayload
    );
    if (response) {
      yield put(setSpinner(false));
      yield put(
        openSnackbar({
          snackbarTitle: action.payload.mssg,
          icon: "",
          severity: "success",
        })
      );
      History.navigate("/packages");
    }
  } catch (error) {
    yield put(setSpinner(false));
    yield put(
      openSnackbar({
        snackbarTitle: "Request Failed",
        icon: "",
        severity: "error",
      })
    );
    console.log("something went wrong!");
  }
}

export function* handlegetCareGoallist() {
  try {
    const response = yield call(requestGetCarePackageGoalList);
    const { entry } = response.data;
    let searchResults = [];
    if (entry && Array.isArray(entry)) {
      if (!_.isEmpty(entry)) {
        searchResults = entry.map((i) => ({
          name: i.GoalName,
        }));
      }
    }
    yield put(setGoalList(searchResults));
  } catch (error) {
    console.log("something went wrong!");
  }
}

export function* handlegetmeasurementsMetricArray(action) {
  try {
    const response = yield call(
      requestGetmeasurementsMetricArray,
      action.payload
    );
    const { entry } = response.data;
    yield put(setmeasurementsMetricArray(entry));
  } catch (error) {
    console.log("something went wrong!");
  }
}

export function* handleGetActivitiesList(action) {
  try {
    const response = yield call(requestGetActivitiesList, action.payload);
    const { data } = response.data;
    yield put(setActivitiesList(data));
    yield put(setActivitiesListLoaded(true));
  } catch (error) {
    console.log("something went wrong!");
  }
}

export function* handlePostCareActivity(action) {
  try {
    const response = yield call(requestpostCareActivity, action.payload);
    if (response) {
      yield put(
        openSnackbar({
          snackbarTitle: "New activity added successfully",
          icon: "",
          severity: "success",
        })
      );
      yield put(setActivityChanged(true));
    }
  } catch (error) {
    yield put(
      openSnackbar({
        snackbarTitle: "Request Failed",
        icon: "",
        severity: "error",
      })
    );
    yield put(setActivityChanged(false));

    console.log("something went wrong!");
  }
}

export function* handlePutCareActivity(action) {
  try {
    const response = yield call(requestputCareActivity, action.payload);
    if (response) {
      yield put(
        openSnackbar({
          snackbarTitle: "Task updated successfully",
          icon: "",
          severity: "success",
        })
      );
      yield put(setActivityChanged(true));
    }
  } catch (error) {
    yield put(
      openSnackbar({
        snackbarTitle: "Request Failed",
        icon: "",
        severity: "error",
      })
    );
    yield put(setActivityChanged(false));

    console.log("something went wrong!");
  }
}

export function* handleRequestDeleteCareActivity(action) {
  try {
    const response = yield call(requestDeleteCareActivity, action.payload);
    if (response) {
      yield put(
        openSnackbar({
          snackbarTitle: "Activity deleted successfully",
          icon: "",
          severity: "success",
        })
      );
      yield put(setActivityChanged(true));
    }
  } catch (error) {
    yield put(
      openSnackbar({
        snackbarTitle: "Request Failed",
        icon: "",
        severity: "error",
      })
    );
    yield put(setActivityChanged(false));

    console.log("something went wrong!");
  }
}
