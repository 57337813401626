import API from "./../../../lib/api";

import {
  CARE_PACKAGE_BASE_URL,
  SURVEYS_BASE_URL,
  CARE_PACKAGE_PATIENT_BASE_URL
} from "./../../../utils/constants";

export function requestGetPatientCarePackage(id) {
  return API.get(
    `${CARE_PACKAGE_PATIENT_BASE_URL}/PatientCarePackage/cpIdentifier/${id}`
  );
}

export function requestGetAssociationSurveyAnswers({ id, cpId }) {
  let str = cpId.substring(0, cpId.length - 5);
  let newCpId = str + 'DRAFT'
  return API.get(
    `${SURVEYS_BASE_URL}/QuestionnaireResponse?subject=Patient/${id}&cpIdentifier=${newCpId}`
  );
}

export function requestGetCareTeamById({ id, cpId }) {
  let status = cpId.slice(cpId.length - 5)
  let str = cpId.substring(0, cpId.length - 5);
  let newCpId = ""
  if (status == 'ACTVE') {
    newCpId = cpId
  } else if (status == 'APPRV') {
    newCpId = cpId
  } else if (status == 'CMPLT') {
    newCpId = cpId
  } else if (status == 'TRMND') {
    newCpId = cpId
  } else {
    newCpId = str + 'DRAFT'
  }
  return API.get(
    `${CARE_PACKAGE_BASE_URL}/PatientCareTeam?patientId=${id}&cpIdentifier=${newCpId}`
  );
}

export function requestUpdateAssociation(association) {
  return API.put(`${CARE_PACKAGE_PATIENT_BASE_URL}/PatientCarePackage`, association);
}

export function requestUpdateExistingCareTeam(template) {
  return API.put(`${CARE_PACKAGE_PATIENT_BASE_URL}/PatientCareTeam`, template);
}

export function requestTerminateOrCloseCarePlan(body) {
  const { type, ...request } = body;
  return API.put(
    `${CARE_PACKAGE_BASE_URL}/CarePackageOp?operation=${type}`,
    request
  );
}
