import {
  createGeneralComment,
  getAllComments,
  ReviewedAlert,
  GetAllReviewAlert

} from "./commentsSlice";
import {
  handleCreateGeneralComment ,
  handlegetAllComments,
  handleReviewedAlert,
  handleGetAllReviewAlert

} from "./commentsHandler"
import { takeEvery, takeLatest } from "redux-saga/effects";

export const commentSaga = [
  takeLatest(createGeneralComment.type, handleCreateGeneralComment),
  takeLatest(getAllComments.type, handlegetAllComments),
  takeLatest(ReviewedAlert.type, handleReviewedAlert),
  takeLatest(GetAllReviewAlert.type, handleGetAllReviewAlert),
];
