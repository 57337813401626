import API from "../../lib/api";
import { CARE_PACKAGE_BASE_URL, CARE_PACKAGE_PATIENT_BASE_URL } from "./../../utils/constants";

const searchCarePackageActivityByNameURL = `${CARE_PACKAGE_PATIENT_BASE_URL}/Activity`;
const searchCarePackageCategoryByNameURL = `${CARE_PACKAGE_PATIENT_BASE_URL}/CarePackage`;
const getActivityListURL = `${CARE_PACKAGE_PATIENT_BASE_URL}/Activity?status=Approved`;
const getActivityByTypeURL = `${CARE_PACKAGE_BASE_URL}/Device`;
const patientCarePackageReviewSubmitURL = `${CARE_PACKAGE_PATIENT_BASE_URL}/PatientCarePackage`;
const patientCarePackageReviewSubmitModifiedRequestURL = `${CARE_PACKAGE_PATIENT_BASE_URL}/PatientCarePackage`;
const getSurveysUnderCarePackageURL = `${CARE_PACKAGE_PATIENT_BASE_URL}/SurveyNames`;
const getSurveysByDateURL = `${CARE_PACKAGE_PATIENT_BASE_URL}/SurveyByDate`;
const getSurveysByQuestionURL = `${CARE_PACKAGE_PATIENT_BASE_URL}/SurveyByQuestion`;

export function requestGetpatientCarePackageCategoryByName(params) {
  return API.get(searchCarePackageCategoryByNameURL, params);
}

export function requestGetpatientCarePackageActivityByType(params) {
  return API.get(getActivityByTypeURL, params);
}

export function requestpostCarePackageActivityList() {
  return API.get(getActivityListURL);
}

export function requestGetCarePackageActivityByName(params) {
  return API.get(searchCarePackageActivityByNameURL, params);
}

export function requestgetPatientCarePackagebyPatientIdforModifying(params) {
  let newCarePackagebyPatientIdforModifyingRequestUrl = `${CARE_PACKAGE_PATIENT_BASE_URL}/PatientCarePackage/cpIdentifier/${params}`;
  return API.get(newCarePackagebyPatientIdforModifyingRequestUrl);
}

export function requestpostpatientCarePackageReviewSubmit(body) {
  return API.put(patientCarePackageReviewSubmitURL, body);
}

export function requestpostModifiedRequestpatientCarePackageReviewSubmit(body) {
  return API.put(patientCarePackageReviewSubmitModifiedRequestURL, body);
}

export function requestGetSurveyList(params) {
  let getSurveysUnderCarePackageURLWithParams = `${getSurveysUnderCarePackageURL}?patientId=${params.patientId}&cpId=${params.cpId}`;
  return API.get(getSurveysUnderCarePackageURLWithParams);
}

export function requestGetPatientSurveyByDate(params) {
  let getSurveysByDateURLWithParams = `${getSurveysByDateURL}?patientId=${params.patientId}&cpId=${params.cpId}&questionnaireId=${params.questionnaireId}&surveyName=${params.surveyName}`;
  return API.get(getSurveysByDateURLWithParams);
}

export function requestGetPatientSurveyByQuestions(params) {
  let getSurveysByQuestionURLWithParams = `${getSurveysByQuestionURL}?patientId=${params.patientId}&cpId=${params.cpId}&questionnaireId=${params.questionnaireId}&surveyName=${params.surveyName}`;
  return API.get(getSurveysByQuestionURLWithParams);
}



