import { attachment } from "../../../assets/icons/index";
import { useTranslationContext } from "stream-chat-react";
import "./CustomFileUploadIcon.scss";

const CustomFileUploadIcon = () => {
  const { t } = useTranslationContext();

  return (
    <div>
      <img
        src={attachment}
        alt={t("file upload")}
        className="file-upload-icon"
      />
    </div>
  );
};

export default CustomFileUploadIcon;
