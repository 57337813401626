import { takeLatest } from "redux-saga/effects";

import {
  handleGetTotalPatients,
  handleGetTopCarePlan,
  handleGetCareTeamStatistics,
  handleGetActivePatientsByCategory,
  handleGetAppOnboardStatus,
  handleGetSearchPatientsEMR,
} from "./dashboardHandler";
import {
  getTotalPatients,
  getTopCarePlan,
  getCareTeamStatistics,
  getActivePatientsByCategory,
  getAppOnboardStatus,
  getSearchPatientsEMR,
} from "./dashboardSlice";

export const dashboardSaga = [
  takeLatest(getTotalPatients.type, handleGetTotalPatients),
  takeLatest(getTopCarePlan.type, handleGetTopCarePlan),
  takeLatest(getCareTeamStatistics.type, handleGetCareTeamStatistics),
  takeLatest(
    getActivePatientsByCategory.type,
    handleGetActivePatientsByCategory
  ),
  takeLatest(getAppOnboardStatus.type, handleGetAppOnboardStatus),
  takeLatest(getSearchPatientsEMR.type, handleGetSearchPatientsEMR),
];
