import { call, put } from "redux-saga/effects";
import {
  setCarePackageCategory,
  setSpecialtiesShared,
  setPractitionersShared,
  setOnboardPractitionerError,
  setOnboardPractionerResponse,
} from "./sharedSlice";
import { requestGetActivePatientsByCategory } from "../../store/dashboard/dashboardRequest";
import {
  setallAlertCount,
  setAllPatientList,
  setNonClinicalAlertIndicator,
} from "../patients/patientsSlice";
import {
  requestGetSpecialtiesShared,
  requestGetCarePrograms,
  requestOnBoardPractitioners,
  requestvalidatePractitioners,
  requestresendvalidatePractitioners,
  requestGetPractitionersShared,
  loginWithSSO,
} from "./sharedRequest";
import {
  requestGetAllPatientsWithParams,
  requestGetAllPatients,
  requestALlAlert,
} from "../patients/patientsRequest";
import { openSnackbar } from "../toast/toastSlice";
import { History } from "../../history";
import { decideWarningKey } from "../../utils/utils";

export function* handleGetCarePackageCategory(action) {
  try {
    const response = yield call(requestGetCarePrograms);
    const { recCarePrograms } = response.data;
    try {
      const res = yield call(requestGetActivePatientsByCategory);
      const { entry } = res.data;
      if (recCarePrograms.length > 0 && entry !== null) {
        let results = entry.sort(function (cat1, cat2) {
          return cat2.activeCarePlanCount - cat1.activeCarePlanCount;
        });
        let result = results.map((r) => r.category);
        let carePgms = JSON.parse(JSON.stringify(recCarePrograms));
        let initialArr = result.concat(carePgms);
        let finalList = initialArr.filter(
          (item, pos) => initialArr.indexOf(item) === pos
        );
        yield put(setCarePackageCategory(finalList));
      } else {
        yield put(setCarePackageCategory([...recCarePrograms]));
      }
    } catch (error) {
      const response = yield call(requestGetCarePrograms);
      const { recCarePrograms } = response.data;
      yield put(setCarePackageCategory([...recCarePrograms]));
    }
  } catch (error) {
    console.log("something went wrong!");
  }
}

export function* handleOnBoardPractitioners(action) {
  try {
    const response = yield call(requestOnBoardPractitioners, action.payload);
    if (
      typeof response.data === "object" &&
      response.data.hasOwnProperty("invitationStatus")
    ) {
      yield put(setOnboardPractionerResponse(response.data));
    } else {
      yield put(setOnboardPractitionerError(true));
    }
  } catch (error) {
    yield put(setOnboardPractitionerError(true));
    console.log("something went wrong!");
  }
}

export function* handlevalidatePractitioners(action) {
  try {
    const response = yield call(
      requestvalidatePractitioners,
      action.payload.body
    );

    if (response) {
      // store token from response and email from action
      localStorage.setItem("token", response.data["ujdVWg0nR2oPva"]);
      localStorage.setItem("userEmail", action.payload.body.emailId);
      yield call(action.payload.onSuccess());
    }
  } catch (error) {
    if (error?.response?.status === 400) {
      yield put(
        openSnackbar({
          snackbarTitle: "Invalid Code",
          icon: "",
          severity: "error",
        })
      );
    }
    console.log("something went wrong!");
  }
}

export function* handleResendvalidatePractitioners(action) {
  try {
    yield call(requestresendvalidatePractitioners, action.payload);
  } catch (error) {
    console.log("something went wrong!");
  }
}

export function* handleGetSpecialtiesShared() {
  try {
    const response = yield call(requestGetSpecialtiesShared);
    const { entry } = response.data;
    yield put(setSpecialtiesShared(entry));
  } catch (error) {
    console.log("something went wrong!");
  }
}

export function* handleGetPractitionersShared(action) {
  try {
    const response = yield call(requestGetPractitionersShared, action.payload);
    const { entry } = response.data;
    yield put(setPractitionersShared({ entry, specialty: action.payload }));
  } catch (error) {
    console.log("something went wrong!");
  }
}

export function* handleLoginWithSSO(action) {
  try {
    const response = yield call(loginWithSSO, action.payload.cred);
    if (response.data.access_token) {
      localStorage.setItem("token", response.data.access_token);
      localStorage.setItem("email", action.payload.cred.username);
      History.navigate("/dashboard");
      let email = action.payload.cred.username;
      yield call(action.payload.onSuccess(email));
    }
  } catch (error) {
    if (error?.response?.status === 422) {
      yield put(
        openSnackbar({
          snackbarTitle: "Invalid credentials",
          icon: "",
          severity: "error",
        })
      );
    }
  }
}

export function* handleCallAlertRenderAPIsOnLoad(action) {
  let response;

  const rpmdashboardUrl = "limit=10&offset=0";

  try {
    response = yield call(requestGetAllPatientsWithParams, rpmdashboardUrl);
  } catch (error) {
    console.log("something went wrong!");
  }

  try {
    const res = yield call(
      requestALlAlert,
      `patientId=${response.data.entry[0].id}&cpIdentifier=${response.data.entry[0].cpIdentifier}&status=in-progress`
    );
    yield put(setallAlertCount(res.data.totalActiveAlertsInSystem));
  } catch (error) {
    console.log("something went wrong!");
    yield put(setallAlertCount(null));
  }

  try {
    const payl = {
      id: action.payload,
      isNonClinicalAlert: true,
    };

    const responsePatientList = yield call(requestGetAllPatients, payl);
    const { data: entries } = responsePatientList;
    const obj = { ...entries };
    handleSetNonClinicalAlertIndicator(obj, decideWarningKey);
  } catch (error) {
    console.log("something went wrong!");
  }
}

export function* handleSetNonClinicalAlertIndicator(obj, decideWarningKey) {
  try {
    const patientList = obj.entry.map((e, i) => {
      return {
        ...e,
        uniqueKey: e.cpIdentifier + i + "",
        warningKey: decideWarningKey(e),
      };
    });

    let indicator;
    if (patientList.some((e) => e.warningKey === "red")) {
      indicator = "red";
    } else if (patientList.some((e) => e.warningKey === "yellow")) {
      indicator = "yellow";
    } else {
      indicator = "";
    }
    yield put(setNonClinicalAlertIndicator(indicator));
  } catch (error) {
    console.log("something went wrong!");
    yield put(setAllPatientList(null));
  }
}
