export const CARE_TEAM = {
  ROLES: {
    ATTENDING_PHYSICIAN: "attending physician",
    CASE_MANAGER: "case manager",
    CLINICAL_GROUP: "clinical group",
    NON_CLINICAL_GROUP: "non clinical group",
  },
};

export const PATIENT_ASSOCIATION = {
  STATUS: {
    APPROVED: "Approved",
    PENDING_APPROVAL: "Pending Approval",
    MODIFICATION_REQUESTED: "Modification Requested",
    DRAFT: "Draft",
    ACTIVE: "Active",
    COMPLETED: "Completed",
    TERMINATED: "Terminated",
  },
};

export const APPOINTMENT = {
  ONLINE_STATUS: {
    NOT_STARTED: "NOT_STARTED",
    ONGOING: "ON_GOING",
    ENDED: "ENDED",
  },
};

export const ENROLLMENT_STATUS = {
  AWAITING_CONSENT: "Awaiting Consent",
  ENROLLED: "Enrolled",
  CONSENT_INACTIVE: "Consent Inactive",
  CONSENT_EXPIRED: "Consent Expired",
  MODIFICATION_REQUESTED: "Modification Requested",
};

export const CARE_PACKAGE_STATUS = {
  APPROVED: "Approved",
  PENDING_APPROVAL: "Pending Approval",
  ACTIVE: "Active",
  COMPLETED: "Completed",
  TERMINATED: "Terminated",
  MODIFICATION_REQUESTED: "Modification Requested",
  DRAFT: "Draft",
};

export const CATEGORY_TYPES = {
  HOME: "At Home",
  FACILITY: "At Facility",
  ONLINE: "Online consultation",
};

export const APPOINTMENT_JOIN = { START_MINS: 15, END_MINS: 0 };

export const patientDetailstabNames = {
  0: "Patient Details",
  1: "Device Readings",
  2: "Survey Responses",
  3: "Comments",
};

export const CALENDER_CLASSES = [
  "MuiPickersCalendarHeader-root",
  "calender-in-enroll",
  "calender-btn-wrap",
  "MuiDateCalendar-root",
  "MuiPickersCalendarHeader-label",
  "MuiSvgIcon-root",
  "MuiSvgIcon-root",
  "MuiTypography-root",
  "MuiDayCalendar-weekContainer",
  "MuiButtonBase-root",
  "MuiPickersSlideTransition-root",
  "calender-apply",
  "MuiPickersYear-yearButton",
  "MuiPickersYear-root",
  "MuiYearCalendar-root",
  "MuiDayCalendar-monthContainer",
  "MuiPickersArrowSwitcher-root",
  "MuiPickersArrowSwitcher-spacer",
  "MuiPickersFadeTransitionGroup-root",
  "MuiDayCalendar-header",
  "text",
  "line",
  "MuiPickersCalendarHeader-labelContainer",
  "calender-cancel",
];

export const commonMakeStyles = {
  root: {
    color: "black",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#137cbd",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  },
  formControlLabel: {
    fontWeight: "600",
  },
};

export const DASHBOARD_ENROLLMENT_ONBOARDING = {
  CARE_PLAN_ENROLLMENT_ACTIVATION: "Care Plan Enrollment & Activation",
  APP_ONBOARDING_ACTIVITY: "App Onboarding Activity",
};

export const SPECCHAR_TOAVOID_CPCREATION = ['"', "'", "&"];

const env = process.env;

export const CARE_PACKAGE_BASE_URL = env.REACT_APP_CARE_PACKAGE_BASE_URL;
export const CARE_PACKAGE_PATIENT_BASE_URL =
  env.REACT_APP_CARE_PACKAGE_PATIENT_BASE_URL;
export const ENROLLMENT_BASE_URL = env.REACT_APP_ENROLLMENT_BASE_URL;
export const EMR_BASE_URL = env.REACT_APP_EPIC_BASE_URL;
export const EMR_PATIENT_BASE_URL = env.REACT_APP_EPIC_PATIENT_BASE_URL;
export const SURVEYS_BASE_URL = env.REACT_APP_SURVEYS_BASE_URL;
export const DEVICES_BASE_URL = env.REACT_APP_DEVICES_BASE_URL;
export const SEARCH_BASE_URL = env.REACT_APP_SEARCH_BASE_URL;
export const PATIENT_DEVICES_BASE_URL = env.REACT_APP_PATIENT_DEVICES_BASE_URL;
export const ONBOARDING_BASE_URL = env.REACT_APP_ONBOARDING_BASE_URL;
export const AUTH_URL = env.REACT_APP_AUTH_URL;
export const SSO_BASE_URL = env.REACT_APP_SSO_BASE_URL;
export const SSO_IDENTITY_PROVIDER = env.REACT_APP_SSO_IDENTITY_PROVIDER;
export const SSO_REDIRECT_URI = env.REACT_APP_SSO_REDIRECT_URI;
export const SSO_RESPONSE_TYPE = env.REACT_APP_SSO_RESPONSE_TYPE;
export const SSO_CLIENT_ID = env.REACT_APP_SSO_CLIENT_ID;
export const SSO_SCOPE = env.REACT_APP_SSO_SCOPE;
export const SSO_LOGOUT_URI = env.REACT_APP_SSO_LOGOUT_URI;
export const ALERT_BASE_URL = env.REACT_APP_ALERT_BASE_URL;
export const CHAT_MESSAGING_URL = env.REACT_APP_CHAT_MESSAGING_URL;
export const REACT_APP_WEB_BASE_URL = env.REACT_APP_WEB_BASE_URL;
export const CHAT_API_KEY = env.REACT_APP_STREAM_API_KEY;
export const MOCK_JSON_SERVER_BASE_URL = "http://localhost:3005";
export const USER_ROLE_MANAGEMENT_URL = env.REACT_APP_USER_ROLE_MANAGEMENT_URL;

// URM constants
export const REACT_APP_VACA_LOGIN_API = env.REACT_APP_VACA_LOGIN_API;
export const REACT_APP_VACA_PDM_API = env.REACT_APP_VACA_PDM_API;
export const REACT_APP_AUTH_USERNAME = env.REACT_APP_AUTH_USERNAME;
export const REACT_APP_AUTH_PASSWORD = env.REACT_APP_AUTH_PASSWORD;

const APIRunRules = {
  isRealAPICall: {
    USE_MOCKDATA: false,
    USE_JSON_SERVER: false,
    USE_REAL_SERVER: true,
  },
  // you should start the json server using 'json-server --watch src/db.json --port 3005' if you are using this
  isRealAPICallWithJSONServer: {
    USE_MOCKDATA: false,
    USE_JSON_SERVER: true,
    USE_REAL_SERVER: false,
  },
  isMockAPICall: {
    USE_MOCKDATA: true,
    USE_JSON_SERVER: false,
    USE_REAL_SERVER: false,
  },
};

//Permissions
export const APP_SERVICES = {
  ACTIVITIES: "Activities",
  APPOINTMENTS: "Appointment",
  COMMENTS: "General Comments",
  CAREPACKAGE_M: "Care Package (M)",
  CARETEAM: "Care Team",
  DASHBOARD: "Dashboard",
  CAREPACKAGE: "Care Package",
  GROWTH_METRICS: "growth_metrics",
  MESSAGING: "In-App Messaging",
  ONBOARDING: "Onboarding",
  URM: "urm",
  SCENARIO: "scenario",
  CONSENT: "Consent",
  CARETEAM_TEMPLATE: "CareTeam Template",
  ENROLLMENT: "Enrollment",
  MODEL: "model",
  ALERTS: "Alerts",
  ACTIVITY_LIBRARY: "Activity Library",
};

export const PERMISSIONS_SET = {
  CREATE: "create",
  VIEW: "view",
  EDIT: "edit",
  COPY: "copy",
  DELETE: "delete",
  DOWNLOAD: "download",
  APPROVE: "Approve",
  URM_MANAGEMENT: "urm_management",
  ACT: "Approve-Complete-Terminate",
  RESOLVE: "Resolve",
};

export const VALID_PERMISSIONS = [
  "create",
  "view",
  "edit",
  "copy",
  "delete",
  "Resolve",
  "Download",
  "Approve",
  "urm_management",
  "Approve-Complete-Terminate",
];

// change [key] according to your needs
export const API_COONFIG_KEY = APIRunRules["isRealAPICall"];

export const DEVICE_READINGS_FILTER = {
  Alert: "AAT",
  "No alerts": "NOA",
  "Unreviewed alerts": "UNR",
  All: "ALL",
};

// export const REFRESH_INTERVAL = 10000; // 10 seconds
// export const REFRESH_INTERVAL = 86400000; // 24 hours
export const REFRESH_INTERVAL = 300000; // 5 minutes
