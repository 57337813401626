import API from "../../lib/api";

import {
  EMR_BASE_URL,
  ENROLLMENT_BASE_URL,
  DEVICES_BASE_URL,
  CARE_PACKAGE_BASE_URL,
  CARE_PACKAGE_PATIENT_BASE_URL,
  EMR_PATIENT_BASE_URL,
  PATIENT_DEVICES_BASE_URL,
  ALERT_BASE_URL,
} from "./../../utils/constants";
import { getDeviceReadingListGlobal } from "./patientsSlice";

const getPatientProfileVitalUrl = `${EMR_PATIENT_BASE_URL}/vitals`;

const getPatientclinicalDataUrl = `${EMR_PATIENT_BASE_URL}/ClinicalData`;

const getPatientConditionsURL = `${EMR_PATIENT_BASE_URL}/Conditions`;

const getPatientEncountersURL = `${EMR_PATIENT_BASE_URL}/encounters`;

const getEnrollmentStatusUrl = `${ENROLLMENT_BASE_URL}/EnrollmentStatus`;

const deviceDetailUrl = `${PATIENT_DEVICES_BASE_URL}/DevicesHistoricReadings`;

const getCarePackageAuthorURL = `${CARE_PACKAGE_PATIENT_BASE_URL}/CarePackages`;

const getDeviceReadingUnderCarePackageURL = `${PATIENT_DEVICES_BASE_URL}/availableMetrics`;

const getDeviceReadingURL = `${PATIENT_DEVICES_BASE_URL}/deviceReadings`;
const sendInitiationNotification = `${EMR_PATIENT_BASE_URL}/notification`;

export function requestPatientProfileDetails(id) {
  return API.get(`${EMR_PATIENT_BASE_URL}/SearchPatientById?patientId=${id}`);
}
export function requestPatientProfileDevices({ id, cpId }) {
  return API.get(
    `${PATIENT_DEVICES_BASE_URL}/DeviceMatrixDetails?patientId=${id}&cpIdentifier=${cpId}`
  );
}
export function requestPatientDeviceDetailed(params) {
  let data = {
    patientId: params.id,
    startDate: params.startDate,
    endDate: params.endDate,
    metricType: params.metricType,
    cpIdentifier: params.cpIdentifier,
  };
  return API.get(deviceDetailUrl, { params: data });
}

export function requestPatientEnrollmentStatus(id) {
  return API.get(`${getEnrollmentStatusUrl}?patientId=${id}`);
}

export function requestResendEmail(id) {
  return API.post(`${ENROLLMENT_BASE_URL}/ResendEmail`, { patientId: id });
}

export function sendActivationNotification(body) {
  return API.post(sendInitiationNotification, body);
}

export function requestPatientSummaryVitals(params) {
  let data = {
    patientId: params.id,
    viewType: params.viewType,
  };
  return API.get(getPatientProfileVitalUrl, { params: data });
}

export function requestPatientDetailedVitals(params) {
  let data = {
    patientId: params.id,
    startDate: params.startDate,
    endDate: params.endDate,
    viewType: params.viewType,
  };
  return API.get(getPatientProfileVitalUrl, { params: data });
}

export function requestPatientSummaryClinicalData(params) {
  let data = {
    patientId: params.id,
    viewType: params.viewType,
  };
  return API.get(getPatientclinicalDataUrl, { params: data });
}

export function requestPatientDetailedClinicalData(params) {
  let data = {
    patientId: params.id,
    startDate: params.startDate,
    endDate: params.endDate,
    viewType: params.viewType,
  };
  return API.get(getPatientclinicalDataUrl, { params: data });
}

export function requestPatientSummaryConditions(params) {
  let data = {
    patientId: params.id,
    viewType: params.viewType,
  };
  return API.get(getPatientConditionsURL, { params: data });
}

export function requestPatientDetailedConditions(params) {
  let data = {
    patientId: params.id,
    startDate: params.startDate,
    endDate: params.endDate,
    viewType: params.viewType,
  };
  return API.get(getPatientConditionsURL, { params: data });
}

export function requestPatientSummaryEncounters(params) {
  let data = {
    patientId: params.id,
    viewType: params.viewType,
  };
  return API.get(getPatientEncountersURL, { params: data });
}

export function requestPatientDetailedEncounters(params) {
  let data = {
    patientId: params.id,
    startDate: params.startDate,
    endDate: params.endDate,
    viewType: params.viewType,
  };
  return API.get(getPatientEncountersURL, { params: data });
}

export function requestPatientMedication(params) {
  let data = {
    patientId: params.id,
    startDate: params.startDate,
    endDate: params.endDate,
    limit: params.limit,
  };
  return API.get(`${EMR_PATIENT_BASE_URL}/medicationRequest`, { params: data });
}

export function requestPatientAllergies(id) {
  return API.get(`${EMR_PATIENT_BASE_URL}/allergies?patientId=${id}`);
}

export function requestPatientCoverage(id) {
  return API.get(`${EMR_PATIENT_BASE_URL}/coverage?patientId=${id}`);
}

export function requestPatientDocuments(params) {
  let data = {
    patientId: params.id,
    startDate: params.startDate,
    endDate: params.endDate,
    limit: params.limit,
  };
  return API.get(`${EMR_PATIENT_BASE_URL}/documentReference`, { params: data });
}

export function requestPackageInitiation(params) {
  return API.get(
    `${CARE_PACKAGE_BASE_URL}/applyPlanDefinition?patientId=${params.id}&associationDate=${params.initiationDateValue}&cpIdentifier=${params.cpId}`
  );
}

export function requestGetAllPatients(payload) {
  if (payload.status) {
    const res = API.get(
      `${CARE_PACKAGE_PATIENT_BASE_URL}/Patient?practitionerId=${payload.id}&status=${payload.status}`
    );
    return res;
  }
  if (payload.isNonClinicalAlert) {
    const res = API.get(
      `${CARE_PACKAGE_PATIENT_BASE_URL}/Patient?practitionerId=${payload.id}&isNonClinicalAlert=${payload.isNonClinicalAlert}`
    );
    return res;
  }
  return API.get(
    `${CARE_PACKAGE_PATIENT_BASE_URL}/Patient?practitionerId=${payload.id}`
  );
}

export function requestGetAllPatientsWithParams(paramStr) {
  if (paramStr) {
    const res = API.get(
      `${CARE_PACKAGE_PATIENT_BASE_URL}/PatientMonitoring?${paramStr}`
    );
    return res;
  }
  return API.get(`${CARE_PACKAGE_PATIENT_BASE_URL}/PatientMonitoring`);
}

export function requestALlAlert(params) {
  // if(params){
  const res = API.get(`${ALERT_BASE_URL}/Alert?${params}`);
  return res;
  // }
  // return API.get();
}

export function requestGetPatientMonitoringFilter() {
  return API.get(`${CARE_PACKAGE_PATIENT_BASE_URL}/PatientFilter`);
}

export function requestGetMyPatients(obj) {
  if (obj.status) {
    return API.get(
      `${CARE_PACKAGE_PATIENT_BASE_URL}/Patient?practitionerId=${obj.id}&status=${obj.status}`
    );
  }
  return API.get(
    `${CARE_PACKAGE_PATIENT_BASE_URL}/Patient?practitionerId=${obj.id}`
  );
}

export function requestGetMyPatientsWithParams(obj) {
  return API.get(
    `${CARE_PACKAGE_PATIENT_BASE_URL}/PatientMonitoring?id=${obj}`
  );
}

export function requestGetPatientSurvey({
  patientId,
  startDate,
  endDate,
  cpIdentifier,
  status,
}) {
  return API.get(
    `${CARE_PACKAGE_BASE_URL}/SurveyResponse?patientId=${patientId}&start=${startDate}&end=${endDate}&cpIdentifier=${cpIdentifier}`
  );
}

export function requestgetCarePackageAuthorbyName(value) {
  return API.get(`${getCarePackageAuthorURL}`, {
    params: { packageName: value },
  });
}

export function requestGetAllPatientCarePackage({
  patientId,
  startDate,
  endDate,
}) {
  return API.get(
    `${CARE_PACKAGE_PATIENT_BASE_URL}/PatientCarePackages?startDate=${startDate}&endDate=${endDate}&patientId=${patientId}`
  );
}

export function requestDeleteCarePackage(cpId) {
  return API.delete(
    `${CARE_PACKAGE_PATIENT_BASE_URL}/PatientCarePackage/cpIdentifier/${cpId}`
  );
}

export function requestSendPatientEnrollmentConsent(body) {
  return API.put(`${ENROLLMENT_BASE_URL}/PatientEnrollment`, body);
}

export function requestRemovePatient(patientID) {
  return API.delete(
    `${EMR_PATIENT_BASE_URL}/removePatient?patientId=${patientID}`
  );
}

export function requestGetDeviceReadingList(params) {
  let getDeviceReadingListGlobal = `${getDeviceReadingUnderCarePackageURL}?patientId=${params.patientId}&cpIdentifier=${params.cpId}`;
  return API.get(getDeviceReadingListGlobal);
}

// export function requestGetDeviceReadingListWithDate(params) {
//   let getDeviceReadingListWithDate = `${getDeviceReadingURL}?patientId=${params.patientId}&cpId=${params.cpId}&questionnaireId=${params.questionnaireId}&surveyName=${params.surveyName}`;
//   return API.get(getDeviceReadingListWithDate);
// }
