import {
  handlePatientProfileDetails,
  handlePatientSummaryVitals,
  handlePatientSummaryClinicalData,
  handlePatientSummaryConditions,
  handlePatientSummaryEncounters,
  handlePatientAllergies,
  handlePatientCoverage,
  handlePatientMedication,
  handlePatientDocuments,
  handlePatientDetailedVitals,
  handlePatientDetailedClinicalData,
  handlePatientDetailedConditions,
  handlePatientDetailedEncounters,
  handlePatientEnrollmentStatus,
  handleResendEmail,
  handlePackageInitiation,
  handleGetMyPatients,
  handleGetMyPatientsWithParams,
  handleGetAllPatients,
  handleGetAllPatientsWithParams,
  handleGetrequestALlAlert,
  handleGetPatientMonitoringFilter,
  handlePatientProfileDevices,
  handlePatientDeviceDetailed,
  handleGetPatientSurveys,
  handlegetCarePackageAuthorName,
  handleGetAllPatientCarePackage,
  handleDeleteCarePackage,
  handleDeletePatient,
  handleSendPatientEnrollmentConsent,
  handleInitiateCareDesign,
  handleGetDeviceReadingList,

  // handleGetDeviceReadingListWithDate
} from "./patientsHandler";
import {
  getAllPatients,
  getAllPatientsWithParams,
  getallAlertCount,
  getMyPatients,
  getMyPatientsWithParams,
  getPatientMonitoringFilter,
  getPatientProfileDetails,
  getPatientSummaryVitals,
  getPatientSummaryClinicalData,
  getPatientSummaryConditions,
  getPatientSummaryEncounters,
  getPatientAllergies,
  getPatientCoverage,
  getPatientsMedication,
  getPatientsDocuments,
  getPatientDetailedVitals,
  getPatientDetailedClinicalData,
  getPatientDetailedConditions,
  getPatientDetailedEncounters,
  getPatientEnrollmentStatus,
  resendEmail,
  patientPackageInitiation,
  getPatientDevices,
  getPatientDevicesDetails,
  getPatientSurveys,
  getCarePackageAuthorName,
  getAllPatientCarePackages,
  deleteCarePackage,
  deletePatient,
  sendEnrollmentConsent,
  initiateCareDesign,
  getDeviceReadingListGlobal
  // getDeviceReadingListWithDate
} from "./patientsSlice";
import { takeEvery, takeLatest } from "redux-saga/effects";

export const patientsSaga = [
  takeLatest(getAllPatients.type, handleGetAllPatients),
  takeLatest(getAllPatientsWithParams.type, handleGetAllPatientsWithParams),
  takeLatest(getallAlertCount.type, handleGetrequestALlAlert),
  takeLatest(getMyPatients.type, handleGetMyPatients),
  takeLatest(getMyPatientsWithParams.type, handleGetMyPatientsWithParams),
  takeLatest(getPatientMonitoringFilter.type, handleGetPatientMonitoringFilter),
  takeEvery(getDeviceReadingListGlobal.type, handleGetDeviceReadingList),
  // takeEvery(getDeviceReadingListWithDate.type, handleGetDeviceReadingListWithDate),

  takeLatest(getPatientProfileDetails.type, handlePatientProfileDetails),
  takeLatest(getPatientEnrollmentStatus.type, handlePatientEnrollmentStatus),
  takeLatest(resendEmail.type, handleResendEmail),
  takeLatest(getPatientSummaryVitals.type, handlePatientSummaryVitals),
  takeLatest(getPatientDetailedVitals.type, handlePatientDetailedVitals),
  takeLatest(
    getPatientSummaryClinicalData.type,
    handlePatientSummaryClinicalData
  ),
  takeLatest(
    getPatientDetailedClinicalData.type,
    handlePatientDetailedClinicalData
  ),

  takeLatest(getPatientSummaryConditions.type, handlePatientSummaryConditions),
  takeLatest(
    getPatientDetailedConditions.type,
    handlePatientDetailedConditions
  ),
  takeLatest(getPatientSummaryEncounters.type, handlePatientSummaryEncounters),
  takeLatest(
    getPatientDetailedEncounters.type,
    handlePatientDetailedEncounters
  ),
  takeLatest(getPatientAllergies.type, handlePatientAllergies),
  takeLatest(getPatientCoverage.type, handlePatientCoverage),
  takeLatest(getPatientsMedication.type, handlePatientMedication),
  takeLatest(getPatientsDocuments.type, handlePatientDocuments),
  takeLatest(patientPackageInitiation.type, handlePackageInitiation),
  takeLatest(getPatientDevices.type, handlePatientProfileDevices),
  takeLatest(getPatientDevicesDetails.type, handlePatientDeviceDetailed),
  takeLatest(getPatientSurveys.type, handleGetPatientSurveys),
  takeLatest(getCarePackageAuthorName.type, handlegetCarePackageAuthorName),
  takeLatest(getAllPatientCarePackages.type, handleGetAllPatientCarePackage),
  takeLatest(deleteCarePackage.type, handleDeleteCarePackage),
  takeLatest(deletePatient.type, handleDeletePatient),
  takeLatest(sendEnrollmentConsent.type, handleSendPatientEnrollmentConsent),
  takeLatest(initiateCareDesign.type, handleInitiateCareDesign),
];
