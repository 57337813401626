import { createSlice } from "@reduxjs/toolkit";

const careTeamSlice = createSlice({
  name: "careTeam",
  initialState: {
    allCareTeamTemplates: [],
    uniqueCareTeamName: [],
    selectedCareTeam: null,
    carePackageCategory: [],
    allSpecialties: [],
    allPractitioners: [],
    loadedPractitionersSpecialty: [],
    snackbarTitle: "",
    showSnackbar: false,

    newTemplate: {
      name: "",
      description: "",
      care_category: [],
      isGoldStandard: true,
      care_role_specialists: [],
      author: "",
      status: "proposed",
      reviewer: [
        {
          reviewer_name: "",
          comments: "",
          reviewed_at: "",
        },
      ],
    },
    editTemplate: {}
  },
  reducers: {
    getCareTeamTemplates() {},
    setCareTeamTemplates(state, action) {
      const { entry } = action.payload;
      state.uniqueCareTeamName = [...new Set(entry.map((item) => item.name))];
      state.allCareTeamTemplates = entry;
    },
    updateExistingCareTeam(template) {},
    getCareTeamByName(state, action) {},
    setSelectedCareTeam(state, action) {
      state.selectedCareTeam = action.payload;
    },
    getCarePackageCategory() {},
    setCarePackageCategory(state, action) {
      state.carePackageCategory = action.payload;
    },
    getSpecialties() {},
    setSpecialties(state, action) {
      state.allSpecialties = action.payload;
    },

    getPractitioners(specialty) {},
    setPractitioners(state, action) {
      let temp = [...state.allPractitioners, ...action.payload.entry];
      state.allPractitioners = _.uniq(temp, "practitionerId");
      state.loadedPractitionersSpecialty = [
        ...state.loadedPractitionersSpecialty,
        action.payload.specialty,
      ];
    },

    // create new template
    updateNewTemplate(state, action) {
      state.newTemplate = action.payload;
    },
    updateEditTemplate(state, action) {
      state.editTemplate = action.payload;
    },
    resetNewTemplate(state) {
      state.newTemplate = {
        name: "",
        description: "",
        care_category: [],
        isGoldStandard: true,
        care_role_specialists: [],
        author: "",
        status: "proposed",
        reviewer: [
          {
            reviewer_name: "",
            comments: "",
            reviewed_at: "",
          },
        ],
      };
      state.editTemplate = null;
    },
    createNewCareTeam() {},

    setSnackbarTitle(state, action) {
      state.snackbarTitle = action.payload;
    },
    setShowSnackbar(state, action) {
      state.showSnackbar = action.payload;
    },
  },
});

export const {
  getCareTeamTemplates,
  setCareTeamTemplates,
  getCareTeamByName,
  setSelectedCareTeam,
  getCarePackageCategory,
  setCarePackageCategory,
  getSpecialties,
  setSpecialties,
  getPractitioners,
  setPractitioners,
  updateNewTemplate,
  updateEditTemplate,
  createNewCareTeam,
  resetNewTemplate,
  setSnackbarTitle,
  setShowSnackbar,
  updateExistingCareTeam
} = careTeamSlice.actions;

export default careTeamSlice.reducer;
