export const AccessSafetyobjService = {
  item: function (itemvalue, serviceSupport, EnrollmentAcessSafetyQuestion, EnrollmentServiceSupportQuestion,) {
    if (itemvalue) {
      const { Caregive, HouseApartment, Structuralfunctional, transportation } =
        itemvalue;
      // basicActivities, complexActivities,
      let {
        primaryLanguage,
        otherPrimaryLanguage,
        visualHearingImpairment,
        basicActivities,
        complexActivities,
        primaryCaregiver,
        healthcaredecisions,
      } = serviceSupport;
      if (basicActivities?.length <= 0) {
        basicActivities = [{ valueString: "" }];
      }
      if (complexActivities?.length <= 0) {
        complexActivities = [{ valueString: "" }];
      }
      let itemAcessSafety = [
        {
          answer: [
            {
              valueString: Caregive,
            },
          ],
          linkId: "1.1",
          text: EnrollmentAcessSafetyQuestion['1.1'].text,
        },
        {
          answer: [
            {
              valueString: HouseApartment,
            },
          ],
          linkId: "1.2",
          text: EnrollmentAcessSafetyQuestion['1.2'].text,
        },
        {
          answer: [
            {
              valueString: Structuralfunctional,
            },
          ],
          linkId: "1.3",
          text: EnrollmentAcessSafetyQuestion['1.3'].text,
        },
        {
          answer: [
            {
              valueString: transportation,
            },
          ],
          linkId: "1.4",
          text: EnrollmentAcessSafetyQuestion['1.4'].text,
        },
      ];
      let itemServiceSupport = [
        {
          linkId: "2.1",
          text: EnrollmentServiceSupportQuestion['2.1'].text,
          answer: [
            {
              valueString: primaryLanguage === "Other" ? otherPrimaryLanguage : primaryLanguage,
            },
          ],
        },
        {
          linkId: "2.2",
          text: EnrollmentServiceSupportQuestion['2.2'].text,
          answer: [
            {
              valueString: visualHearingImpairment,
            },
          ],
        },
        {
          linkId: "2.3",
          text: EnrollmentServiceSupportQuestion['2.3'].text,
          answer: basicActivities,
        },
        {
          linkId: "2.4",
          text: EnrollmentServiceSupportQuestion['2.4'].text,
          answer: complexActivities,
        },
        {
          linkId: "2.5",
          text: EnrollmentServiceSupportQuestion['2.5'].text,
          answer: [
            {
              valueString: primaryCaregiver,
            },
          ],
        },
        {
          linkId: "2.6",
          text: EnrollmentServiceSupportQuestion['2.6'].text,
          answer: [
            {
              valueString: healthcaredecisions,
            },
          ],
        },
      ];
      const accessSafetyDetails =
        this.getAccessAndServiceDetails(itemAcessSafety);
      const serviceSupportDetails =
        this.getAccessAndServiceDetails(itemServiceSupport);
      return {
        itemAcessSafety: accessSafetyDetails,
        itemServiceSupport: serviceSupportDetails,
      };
    } else return;
  },

  getAccessAndServiceDetails: function (accessAndServiceDetails) {
    return accessAndServiceDetails.map((i) => {
      const { linkId, text, answer } = i;
      if (answer && answer[0].valueString) {
        return i;
      } else {
        return { linkId, text };
      }
    });
  },

  SendObj: async function (
    AcessSafety,
    ServiceSupport,
    searchpageFhirPatientId,
    cpid,
    EnrollmentAcessSafetyQuestion,
    EnrollmentServiceSupportQuestion,
  ) {
    let { itemAcessSafety, itemServiceSupport } = this.item(
      AcessSafety,
      ServiceSupport,
      EnrollmentAcessSafetyQuestion,
      EnrollmentServiceSupportQuestion,
    );
    let searchFhirPatientId = `Patient/${searchpageFhirPatientId}`;
    let AccessSafetyobj = {
      resourceType: "QuestionnaireResponse",
      status: "completed",
      cpIdentifier: cpid,
      questionnaire: "urn:uuid:8384bfba-de39-4f78-9bb7-62d9999eeffa",
      subject: {
        reference: searchFhirPatientId,
        type: "Patient",
      },
      authored: new Date().toISOString(),

      item: [
        {
          linkId: "EnvironmentalAccessAndSafety",
          text: "Environmental Access And Safety",
          item: itemAcessSafety,
        },
        {
          linkId: "ServicesAndSupport",
          text: "Services And Support",
          item: itemServiceSupport,
        },
      ],
    };

    return AccessSafetyobj;
  },
};

export default AccessSafetyobjService;
